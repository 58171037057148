export const recommendationCommonHelpers = {
    computed: {
        // common helpers
        clients() {
            return this.transaction.clients;
        },
        estatePlanningSurveys() {
            return this.transaction.estate_planning_surveys;
        },
        surveys() {
            return this.estatePlanningSurveys;
        }
    },
    methods: {
        separate(conditions, i, andOr = ' and ', separator = ', ') {
            let string = '';
            if (i !== 0 && i !== conditions.length - 1) {
                string += separator;
            }
            if (i !== 0 && i === conditions.length - 1) {
                string += andOr;
            }
            return string;
        },
        existingCustomRecommendationFound(invoiceService) {
            if (!invoiceService) return false
            // existing custom recommendation found
            // used to exclude recommendation creation if custom recommendation for the same product has already been recommended
            return !!this.transaction.estate_planning_custom_recommendation.find(recommendation => recommendation.invoice_service === invoiceService.id && !recommendation.auto_created);
        }
    }
};

<script>
import {clientHelpers} from '@/mixins/clientHelpers';
import {dateFormat} from '@/mixins/dateFormat';
import {recommendationCommonHelpers} from './recommendationCommonHelpers';
import {textHelpers} from '@/mixins/textHelpers';
import {clone} from '@/mixins/clone';
import {personHelpers} from "@/mixins/personHelpers";

import {showClientQuestionHelpers} from "@/views/questionnaires/EstatePlanningSurvey/showClientQuestionHelpers";

export default {
  name: 'DisabledPersonsTrustAndDiscretionaryTrustEngine',
  mixins: [
    clientHelpers,
    recommendationCommonHelpers,
    dateFormat,
    textHelpers,
    clone,
    personHelpers,
    showClientQuestionHelpers
  ],
  render() {
    return null;
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    // main surveys that require trusts
    trustsRequired() {
      // required trusts based upon problems with the survey, filtered to unique surveys
      let surveys = [
        ...this.disabledSurveys,
        ...this.discretionarySurveys
      ].reduce((acc, survey) => {
        if (!acc.map(s => s.id).includes(survey.id)) {
          return [...acc, survey]
        }
        return acc
      }, [])

      // if single mode client is set, then filter surveys required to the client selected
      if (this.singleModeClient)
        surveys = surveys.filter(
            (s) => s.client === this.singleModeClient
        );
      return surveys;
    },

    // people from surveys into trust type
    disabledPeople() {
      // search for people who need a disabled persons trust
      let searchProperties = [
        {
          question: 'will_distribution_beneficiaries_illness_working',
          people: 'will_distribution_beneficiaries_illness_working_people'
        },
        {
          question: 'will_distribution_beneficiaries_managing_finances',
          people: 'will_distribution_beneficiaries_managing_finances_people'
        }
      ]

      let subSearchProperties = [
        {
          question: 'will_distribution_beneficiaries_financial_vulnerable',
          people: 'will_distribution_beneficiaries_financial_vulnerable_people'
        },
        {
          question: 'will_distribution_beneficiaries_state_benefits',
          people: 'will_distribution_beneficiaries_state_benefits_people'
        },
        {
          question: 'will_distribution_beneficiaries_drugs',
          people: 'will_distribution_beneficiaries_drugs_people'
        },
        {
          question: 'will_distribution_beneficiaries_divorced',
          people: 'will_distribution_beneficiaries_divorced_people'
        }
      ]
      return this.searchPeople(searchProperties, 'unnamed disabled person', false, subSearchProperties)
    },
    discretionaryPeople() {
      // search for people who need discretionary trust
      let searchProperties = [
        {
          question: 'will_distribution_beneficiaries_financial_vulnerable',
          people: 'will_distribution_beneficiaries_financial_vulnerable_people'
        },
        {
          question: 'will_distribution_beneficiaries_state_benefits',
          people: 'will_distribution_beneficiaries_state_benefits_people'
        },
        {
          question: 'will_distribution_beneficiaries_drugs',
          people: 'will_distribution_beneficiaries_drugs_people'
        },
        {
          question: 'will_distribution_beneficiaries_divorced',
          people: 'will_distribution_beneficiaries_divorced_people'
        }
      ]
      return this.searchPeople(searchProperties, 'unnamed discretionary person', true)
    },

    // trusts required by survey
    disabledSurveys() {
      // search for clients who need a disabled persons trust
      let allSurveys = this.disabledPeople.reduce((acc, person) => acc.concat(person.surveys), [])
      // filter out duplicate surveys
      return allSurveys.reduce((acc, survey) => {
        if (!acc.map(s => s.id).includes(survey.id)) {
          return [...acc, ...[survey]]
        }
        return acc
      }, [])
    },
    discretionarySurveys() {
      // search for clients who need a discretionary trust
      let allSurveys = this.discretionaryPeople.reduce((acc, person) => acc.concat(person.surveys), [])

      // add in excluded people surveys
      allSurveys = [...allSurveys, ...this.excludedPeopleSurveys]

      // filter out duplicate surveys
      return allSurveys.reduce((acc, survey) => {
        if (!acc.map(s => s.id).includes(survey.id)) {
          return [...acc, ...[survey]]
        }
        return acc
      }, [])
    },
    excludedPeopleSurveys() {
      // excluded partners and children surveys
      let allSurveys = [...this.excludedPartnerSurveys, ...this.excludedChildrenSurveys]

      // filter out duplicate surveys
      return allSurveys.reduce((acc, survey) => {
        if (!acc.map(s => s.id).includes(survey.id)) {
          return [...acc, ...[survey]]
        }
        return acc
      }, [])
    },
    excludedPartnerSurveys() {
      // excluded partner with house / spouse
      // house not going to partner initially or other assets not going to partner initially
      return this.surveys.filter(survey => {
        return (this.willDistributionResidentialPartner(survey) && survey.will_distribution_residential_partner === false) ||
            (this.willDistributionOtherAssetsToPartner(survey) && survey.will_distribution_other_assets_to_partner === false && survey.clientData.people.find(
                (person) => this.isMarried(person)
            ))
      })
    },
    excludedChildrenSurveys() {
      // excluded children
      // all children not included as beneficiaries
      return this.surveys.filter(survey => {
        // excluded children
        return this.willDistributionAllChildrenBeneficiaries(survey) && survey.will_distribution_all_children_beneficiaries === false
      })
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async createRecommendations(singleMode = false) {
      let result = [];
      console.log('create recommendaitons')

      // guards
      if (this.disabled) return result;
      if (!this.trustsRequired.length) return result;

      // start
      // find invoice service
      let singleDptCode = 'DPT-S';
      let mirrorDptCode = 'DPT-M';
      let singleDtCode = 'DT-S';
      let mirrorDtCode = 'DT-M';

      let singleDptProduct = this.products.services.find(
          (option) => option.code === singleDptCode
      );
      let mirrorDptProduct = this.products.services.find(
          (option) => option.code === mirrorDptCode
      );

      let singleDtProduct = this.products.services.find((option) => option.code === singleDtCode);
      let mirrorDtProduct = this.products.services.find((option) => option.code === mirrorDtCode);

      // if (this.trustsRequired.length === 1 || singleMode) {
      //   // take a copy of required storages as it will be modified for single mode
      //   let trustsRequiredCopy = this.clone(this.trustsRequired);
      //
      //   // single product recommendation
      //   for (let i = 0; i < trustsRequiredCopy.length; i++) {
      //     // assign single mode client the current client from copy
      //     if (singleMode)
      //       this.singleModeClient = trustsRequiredCopy[i].client;
      //
      //     // if disabled persons trust
      //     if (this.disabledSurveys.length) {
      //       // add joint DPT product recommendation
      //       result.push({
      //         title: 'Disabled Persons Trust',
      //         description: this.descriptionDisabled(),
      //         benefits: this.benefitsDisabled(),
      //         pricing: this.text.pricing.disabled.single,
      //         clients: [trustsRequiredCopy[i].client],
      //         transaction: this.transaction.id,
      //         auto_introducer_priority: 0,
      //         invoice_service: singleDptProduct?.id,
      //         link_web: this.links.disabled.web,
      //         link_pdf: this.links.disabled.pdf,
      //         link_video: this.links.disabled.video
      //       });
      //     }
      //
      //     // if discretionary trust
      //     if (this.discretionarySurveys.length) {
      //       // add joint DT product recommendation
      //       result.push({
      //         title: 'Discretionary Trust',
      //         description: this.descriptionDiscretionary(),
      //         benefits: this.benefitsDiscretionary(),
      //         pricing: this.text.pricing.discretionary.single,
      //         clients: [trustsRequiredCopy[i].client],
      //         transaction: this.transaction.id,
      //         auto_introducer_priority: 2,
      //         invoice_service: singleDtProduct?.id,
      //         link_web: this.links.discretionary.web,
      //         link_pdf: this.links.discretionary.pdf,
      //         link_video: this.links.discretionary.video
      //       });
      //     }
      //
      //   }
      //   // reset single model client
      //   if (singleMode) this.singleModeClient = null;
      // } else {

      // if disabled persons trust
      if (this.disabledSurveys.length > 1) {
        // add joint DPT product recommendation
        result.push({
          title: 'Disabled Persons Trust (Joint)',
          description: this.descriptionDisabled(),
          benefits: this.benefitsDisabled(),
          pricing: this.text.pricing.disabled.joint,
          clients: this.disabledSurveys.map((survey) => survey.client),
          transaction: this.transaction.id,
          auto_introducer_priority: 0,
          invoice_service: mirrorDptProduct?.id,
          link_web: this.links.disabled.web,
          link_pdf: this.links.disabled.pdf,
          link_video: this.links.disabled.video
        });
      } else if (this.disabledSurveys.length === 1) {
        // add single DPT product recommendation
        result.push({
          title: 'Disabled Persons Trust',
          description: this.descriptionDisabled(),
          benefits: this.benefitsDisabled(),
          pricing: this.text.pricing.disabled.single,
          clients: this.disabledSurveys.map((survey) => survey.client),
          transaction: this.transaction.id,
          auto_introducer_priority: 0,
          invoice_service: singleDptProduct?.id,
          link_web: this.links.disabled.web,
          link_pdf: this.links.disabled.pdf,
          link_video: this.links.disabled.video
        });
      }

      // if discretionary trust
      if (this.discretionarySurveys.length > 1) {
        // add joint DT product recommendation
        result.push({
          title: 'Discretionary Trust (Joint)',
          description: this.descriptionDiscretionary(),
          benefits: this.benefitsDiscretionary(),
          pricing: this.text.pricing.discretionary.joint,
          clients: this.discretionarySurveys.map((survey) => survey.client),
          transaction: this.transaction.id,
          auto_introducer_priority: 2,
          invoice_service: mirrorDtProduct?.id,
          link_web: this.links.discretionary.web,
          link_pdf: this.links.discretionary.pdf,
          link_video: this.links.discretionary.video
        });
      } else if (this.discretionarySurveys.length === 1) {
        // add joint DT product recommendation
        result.push({
          title: 'Discretionary Trust',
          description: this.descriptionDiscretionary(),
          benefits: this.benefitsDiscretionary(),
          pricing: this.text.pricing.discretionary.single,
          clients: this.discretionarySurveys.map((survey) => survey.client),
          transaction: this.transaction.id,
          auto_introducer_priority: 2,
          invoice_service: singleDtProduct?.id,
          link_web: this.links.discretionary.web,
          link_pdf: this.links.discretionary.pdf,
          link_video: this.links.discretionary.video
        });
      }

      return result;
    },
    descriptionDisabled() {
      // description for disabled person trust
      let string = '';
      if (!this.disabledSurveys.length) return string

      // introduction paragraph
      string += this.introductionText(this.disabledPeople)

      // list people with problems
      for (let i = 0; i < this.disabledPeople.length; i++) {

        let person = this.disabledPeople[i]
        let who = ''
        let isAre = 'is '
        let herSheTheyIsAre = ''
        let hasHave = ''
        let youHeSheHaveBeneficiaries = ''
        let hisHerTheir = ''

        // named person or not
        if (person.id) {
          youHeSheHaveBeneficiaries = this.listPeopleAddressDob({
            people: [person],
            noDob: true
          })
        } else {
          if (this.trustsRequired.length === 1 || person.surveys.length > 1) {
            youHeSheHaveBeneficiaries = 'you have unspecified beneficiaries that';
          } else {
            youHeSheHaveBeneficiaries = ` ${this.heShe(person.surveys[0].clientData.profileSex)} would like to include beneficiaries that`
          }
        }

        // who has reported the problem
        if (this.trustsRequired.length === 1) {
          who = 'You have';
        } else if (person.surveys.length > 1) {
          who = 'You both have';
        } else {
          who = `${this.clientFirstName(person.surveys[0].clientData)} has`;
        }

        // he, she, they, is and are
        if (person.id) {
          herSheTheyIsAre = `${this.heShe(person.gender)} is`
          hasHave = 'has'
          hisHerTheir = this.hisHer(person.gender)
        } else {
          herSheTheyIsAre = 'they are'
          hasHave = 'have'
          hisHerTheir = 'their'
        }

        // intro for person
        string += `${who} advised us that ${youHeSheHaveBeneficiaries} `;

        // list problems for this person
        for (let i = 0; i < person.problemQuestions.length; i++) {

          // and separator to each problem based sentence
          string += this.separate(person.problemQuestions, i, ' and ');

          // problem
          let problem = person.problemQuestions[i]

          // problem selector
          if (problem === 'will_distribution_beneficiaries_illness_working') {
            string += ` cannot go to work due to illness or disability`
          } else if (problem === 'will_distribution_beneficiaries_managing_finances') {
            string += ` ${isAre}unable to manage ${hisHerTheir} own finances as ${herSheTheyIsAre} disabled`
          } else if (problem === 'will_distribution_beneficiaries_financial_vulnerable') {
            string += ` could be negatively influenced by others or otherwise financially vulnerable`
          } else if (problem === 'will_distribution_beneficiaries_state_benefits') {
            string += ` ${isAre} on state benefits`
          } else if (problem === 'will_distribution_beneficiaries_drugs') {
            string += ` ${isAre} prone to gambling or ${hasHave} a drug addiction`
          } else if (problem === 'will_distribution_beneficiaries_divorced') {
            string += ` ${isAre} currently separated from ${hisHerTheir} spouse or likely to divorce`
          }
        }
        string += '. '
      }

      string += ' In these cases we would recommend that a disabled persons trust be added. Assets left in a Disabled Persons ' +
          'Trust are protected in the above situations.</p>'

      // end list people with problems

      return `<div>${string}</div>`;
    },
    descriptionDiscretionary() {
      // description for discretionary trust
      let string = ''
      // if (!this.discretionaryPeople.length) return string

      // introduction paragraph
      string += this.introductionText(this.discretionaryPeople, true)

      // excluded partner and children text
      if (this.excludedPartnerSurveys.length) string += this.excludedPartnerText()
      if (this.excludedChildrenSurveys.length) string += this.excludedChildrenText()

      // list people with problems
      for (let i = 0; i < this.discretionaryPeople.length; i++) {

        let person = this.discretionaryPeople[i]
        let who = ''
        let isAre = ''
        let hasHave = ''
        let youHeSheHaveBeneficiaries = ''
        let hisHerTheir = ''

        // named person or not
        if (person.id) {
          youHeSheHaveBeneficiaries = this.listPeopleAddressDob({
            people: [person],
            noDob: true
          })
        } else {
          if (this.trustsRequired.length === 1 || person.surveys.length > 1) {
            youHeSheHaveBeneficiaries = 'you have unspecified beneficiaries that';
          } else {
            youHeSheHaveBeneficiaries = ` ${this.heShe(person.surveys[0].clientData.profileSex)} would like to include beneficiaries that`
          }
        }


        // who has reported the problem
        if (this.trustsRequired.length === 1) {
          who = 'You have';
        } else if (person.surveys.length > 1) {
          who = 'You both have';
        } else {
          who = `${this.clientFirstName(person.surveys[0].clientData)} has`;
        }

        // he, she, they, is and are
        if (person.id) {
          hasHave = 'has'
          hisHerTheir = this.hisHer(person.gender)
          isAre = 'is'
        } else {
          hasHave = 'have'
          hisHerTheir = 'their'
          isAre = 'are'
        }

        // intro for person
        string += `${who} advised us that ${youHeSheHaveBeneficiaries} `;

        // list problems for this person
        for (let i = 0; i < person.problemQuestions.length; i++) {

          // and separator to each problem based sentence
          string += this.separate(person.problemQuestions, i, ' and ');

          // problem
          let problem = person.problemQuestions[i]

          // problem selector
          if (problem === 'will_distribution_beneficiaries_financial_vulnerable') {
            string += ` could be negatively influenced by others or otherwise financially vulnerable`
          } else if (problem === 'will_distribution_beneficiaries_state_benefits') {
            string += ` ${isAre} on state benefits`
          } else if (problem === 'will_distribution_beneficiaries_drugs') {
            string += ` ${isAre} prone to gambling or ${hasHave} a drug addiction`
          } else if (problem === 'will_distribution_beneficiaries_divorced') {
            string += ` ${isAre} currently separated from ${hisHerTheir} spouse or likely to divorce`
          }
        }
        string += '. '
      }

      string += ' In these cases we would recommend that a discretionary trust be added. Assets left in a Discretionary ' +
          'Trust are protected in the above situations.</p>'

      // end list people with problems

      return `<div>${string}</div>`;
    },
    benefitsDisabled() {
      let string = ''
      string += '<p>A disabled persons trust brings the following benefits...</p>';
      return string
    },
    benefitsDiscretionary() {
      let string = ''
      string += '<ul class="mt-16 list-policies">'

      if (this.excludedPartnerSurveys.length) {
        string += '<li class="mb-12"><strong>Excluded Partner -</strong> EXCLUDED PARTNER BENEFIT</li>'
      }
      if (this.excludedChildrenSurveys.length) {
        string += '<li class="mb-12"><strong>Excluded Child -</strong> EXCLUDED CHILDREN BENEFIT</li>'

      }

      string += '<li class="mb-12"><strong>Total Flexibility - </strong> one of the beneficiaries may be in greater need than another, or a child may wish for their own children to benefit instead, with a discretionary trust, the trustees have the flexibility to decide what to do for the best after you have passed away.</li>' +
          '<li class="mb-12"><strong>Divorce Protection -</strong> assets that are held on trust are protected against changes in beneficiaries’ circumstances; ensuring that assets can be kept within the family even if a beneficiary gets divorced for example.</li>' +
          '<li class="mb-12"><strong>Bankruptcy Protection -</strong> trusts can safeguard assets in the event of bankruptcy. In the current economic climate where many people are struggling with debt and the potential for bankruptcy threatens a greater proportion of society, protective trusts are very useful vehicle to protect your home.</li>' +
          `<li class="mb-12"><strong>Prevent Challenges -</strong> if you give beneficiaries a fixed interest in your Will there are situations where your wishes can be challenged, it is not possible to challenge a discretionary trust in the same way.</li>` +
          `<li class="mb-12"><strong>Reduce Tax -</strong> because Discretionary Trusts are very flexible, there are many ways that they can be used in the long-term to reduce Income Tax, Inheritance Tax and Capital Gains Tax.</li>` +
          `<li class="mb-12"><strong>Protect Disabled / Vulnerable Beneficiaries -</strong> if one of your beneficiaries is considered to be vulnerable or disabled when you pass away, a Discretionary Trust can ensure that their inheritance is protected for them.</li>` +
          '</ul>'

      return string
    },
    excludedPartnerText() {
      let string = ''

      for (let i = 0; i < this.excludedPartnerSurveys.length; i++) {
        console.log('excluded partner survey', i)
        // init
        let survey = this.excludedPartnerSurveys[i]
        let person = ''
        let yourHisHer = ''
        let youHeSheDoes = ''
        let isMarriedToPerson = survey.clientData.people.find(
            (person) => this.isMarried(person)
        )
        let isPartneredToPerson = survey.clientData.people.find(
            (person) => this.isPartnered(person)
        )
        let houseNotToPartner = this.willDistributionResidentialPartner(survey) &&
            survey.will_distribution_residential_partner === false

        let assetsNotToPartner = this.willDistributionOtherAssetsToPartner(survey) &&
            survey.will_distribution_other_assets_to_partner === false

        // start preparing text variables
        if (this.trustsRequired.length === 1) {
          person = 'You have';
          yourHisHer = 'your'
          youHeSheDoes = 'you do'
        } else {
          person = `${this.clientFirstName(survey.clientData)} has `;
          yourHisHer = this.hisHer(survey.clientData.profileSex)
          youHeSheDoes = this.heShe(survey.clientData.profileSex) + ' does'
        }
        let relationship = ''
        let name = ''

        if (isMarriedToPerson) {
          relationship = 'spouse'
          name = this.fullName(isMarriedToPerson)
        } else if (isPartneredToPerson) {
          relationship = 'partner'
          name = this.fullName(isPartneredToPerson)
        }

        // start assembling text
        string += '<p>'
        if (houseNotToPartner) {

          if (isPartneredToPerson) {
            let partnerHeShe = this.heShe(isPartneredToPerson.gender)

            string += `${person} told us that ${youHeSheDoes} not initially plan of leaving ${yourHisHer} main residential property to ` +
                `${yourHisHer} ${relationship} ${name}, this will cause problems as ${partnerHeShe} ` +
                `CAN CLAIM ${yourHisHer} PROPERTY AS ${partnerHeShe} HAS BEEN LIVING THERE. `
          } else if (isMarriedToPerson) {
            let partnerHeShe = this.heShe(isMarriedToPerson.gender)

            string += `${person} told us that ${youHeSheDoes} not initially plan of leaving ${yourHisHer} main residential property to ` +
                `${yourHisHer} ${relationship} ${name}, this will cause problems as ${partnerHeShe} ` +
                `CAN CLAIM ${yourHisHer} PROPERTY AS ${partnerHeShe} HAS BEEN LIVING THERE. `
          }


        }

        if (assetsNotToPartner && isMarriedToPerson) {
          let partnerHeShe = this.heShe(isMarriedToPerson.gender)
          let other = houseNotToPartner ? 'other ' : ''
          let intro = houseNotToPartner ? ', additionally' : `${person} told us that`
          let toPersonText = houseNotToPartner ? this.fullName(isMarriedToPerson, true) : `${yourHisHer} ${relationship} ${name}`

          string += `${intro} ${youHeSheDoes} not plan of leaving ${yourHisHer} ${other}assets to ` +
              `${toPersonText}, this will cause problems as ${partnerHeShe} CAN CLAIM ON YOUR ESTATE`
        }


        //   if (houseNotToPartner && isPartneredToPerson) {
        //   console.log('house no to partner & partnered')
        //   let partnerHeShe = this.heShe(isPartneredToPerson.gender)
        //
        //   // paragraph for person who is partnered and not leaving residential property to partner initially
        //   string += `${person} told us that ${youHeSheDoes} not initially plan of leaving ${yourHisHer} main residential property to ` +
        //       `${yourHisHer} ${relationship} ${name}, this will cause problems as ${partnerHeShe} ` +
        //       `CAN CLAIM ${yourHisHer} PROPERTY AS ${partnerHeShe} HAS BEEN LIVING THERE. `
        //
        // }

        // if ((assetsNotToPartner || houseNotToPartner) && isMarriedToPerson) {
        //   console.log('assets or house not to partner & married')
        //   let partnerHeShe = this.heShe(isMarriedToPerson.gender)
        //
        //   // // paragraph for person who is married and not leaving main residential property to spouse initially
        //   // if (houseNotToPartner) {
        //   //   string += `${person} told us that ${youHeSheDoes} not plan of leaving ${yourHisHer} main residential property to ` +
        //   //       `${yourHisHer} ${relationship} ${name}, this will cause problems as ${partnerHeShe} CAN CLAIM YOUR PROPERTY`
        //   // }
        //
        //   // paragraph for person who is married and not leaving all other assets to spouse initially
        //   if (assetsNotToPartner) {
        //     let other = houseNotToPartner ? 'other ' : ''
        //     let intro = houseNotToPartner ? ', additionally' : `${person} told us that`
        //     let toPersonText = houseNotToPartner ? this.fullName(isMarriedToPerson, true) : `${yourHisHer} ${relationship} ${name}`
        //
        //     string += `${intro} ${youHeSheDoes} not plan of leaving ${yourHisHer} ${other}assets to ` +
        //         `${toPersonText}, this will cause problems as ${partnerHeShe} CAN CLAIM ON YOUR ESTATE`
        //   }
        string += '. '

        // }

        console.log(string)

        string += '</p>'

      }
      return string
    },
    excludedChildrenText() {
      let string = ''

      for (let i = 0; i < this.excludedChildrenSurveys.length; i++) {
        // init
        let survey = this.excludedChildrenSurveys[i]
        let person = ''
        let persons = ''
        let yourHisHer = ''
        let under18Children = survey.clientData.people.filter(p => this.isChildOf(p))
        let allChildren = survey.clientData.people.filter(p => this.isSonOrDaughter(p))
        let allListedChildrenUnder18 = under18Children.length === allChildren.length && allChildren.length > 0

        // start preparing text variables
        if (this.trustsRequired.length === 1) {
          person = 'You plan';
          persons = 'your'
          yourHisHer = 'your'
        } else {
          person = `${this.clientFirstName(survey.clientData)} plans`;
          persons = `${this.clientFirstName(survey.clientData)}'s`
          yourHisHer = this.hisHer(survey.clientData.profileSex)
        }

        string += `${person} on excluding one or more of ${yourHisHer} children, EXCLUDING CHILDREN IS A PROBLEM`

        if (allListedChildrenUnder18) {
          `, furthermore because all of ${persons} children are under 18 THERE ARE FURTHER CHILDREN PROBLEMS`
        }
        string += '.</p>'
      }
      return string
    },

    introductionText(people, discretionaryTrust = false) {
      let string = ''
      // introduction paragraph
      // get all unique problems
      let allProblems = people.reduce((acc, person) => [...acc, ...person.problemQuestions], []).reduce((acc, problem) => {
        if (!acc.includes(problem)) {
          return [...acc, problem]
        }
        return acc
      }, [])

      if (discretionaryTrust) {
        // add excluded partner and children to problems if for a discretionary trust
        if (this.excludedChildrenSurveys.length) allProblems.unshift('excluded_children')
        if (this.excludedPartnerSurveys.length) allProblems.unshift('excluded_partner')
      }

      string += '<p>Where those who would benefit under a will '

      for (let i = 0; i < allProblems.length; i++) {
        string += this.separate(allProblems, i, ' or ');
        if (allProblems[i] === 'excluded_partner') {
          string += 'such as a partner but have been excluded as a beneficiary'
        } else if (allProblems[i] === 'excluded_children') {
          string += 'such as your children that have been excluded from your will'
        } else if (allProblems[i] === 'will_distribution_beneficiaries_illness_working') {
          string += `that are suffering from an illnesses that may restrict their ability to work`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_managing_finances') {
          string += `are physically disabled or unable mentally incapable of managing their own finances`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_financial_vulnerable') {
          string += `are likely to be negatively influenced by others or are otherwise financially vulnerable`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_state_benefits') {
          string += `are entitled to means tested state benefits (such as PIP, EDL or ESA)`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_drugs') {
          string += `are prone to gambling or have a drug / alcohol addictions`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_divorced') {
          string += `are currently separated from their spouse or likely to divorce in the future`;
        }
      }
      string += `, the flexibility to control how much they inherit and exactly when they inherit is crucial. `;
      for (let i = 0; i < allProblems.length; i++) {
        string += this.separate(allProblems, i, ' ');
        if (allProblems[i] === 'excluded_partner') {
          string += 'What would happen if your partner made a claim against your estate?'
        } else if (allProblems[i] === 'excluded_children') {
          string += 'What would happen if excluded children made a claim against your estate?'
        } else if (allProblems[i] === 'will_distribution_beneficiaries_illness_working') {
          string += `What would happen to the inheritance or a beneficiary who is unable to go out to work?`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_managing_finances') {
          string += `What would happen to the inheritance or a beneficiary who cannot manage their own finances?`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_financial_vulnerable') {
          string += `What would happen to the inheritance or a beneficiary who is financially vulnerable or heavily influenced by others?`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_state_benefits') {
          string += `How quickly would an inheritance be spent where state support / benefits are stopped?`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_drugs') {
          string += `What would happen to the beneficiary with addiction issues having inherited a large sum of money?`;
        } else if (allProblems[i] === 'will_distribution_beneficiaries_divorced') {
          string += `How much of the inheritance of the person going through a divorce would pass to their ex-partner?`;
        }
      }
      string += '</p><p>'
      // end introduction paragraph
      return string
    },

    // helpers
    searchPeople(searchProperties, blankPersonName, excludeDisabled = false, subSearchProperties = null) {
      // search for named people in survey questions (searchProperties)
      let people = []
      // search surveys
      for (let i = 0; i < this.surveys.length; i++) {
        let survey = this.surveys[i]
        // search for disabled people and discretionary trust problem people

        for (let sp = 0; sp < searchProperties.length; sp++) {

          // question is true
          if (survey[searchProperties[sp].question]) {

            for (let p = 0; p < survey[searchProperties[sp].people].length; p++) {
              let person = Object.assign(survey[searchProperties[sp].people][p], {})

              // exit if person is disabled
              if (excludeDisabled && this.disabledPeople.map(p => p.id).includes(person.id)) continue

              if (!people.map(pn => pn.id).includes(person.id)) {
                // if person not already in results/people
                person.surveys = [survey]
                person.problemQuestions = [searchProperties[sp].question]
                people.push(person)
              } else {
                // add another survey to person if survey not already in person
                let index = people.findIndex(x => x.id === person.id)
                if (index > -1 && !people[index].surveys.map(s => s.id).includes(survey.id)) {
                  people[index].surveys.push(survey)
                }
                if (index > -1 && !people[index].problemQuestions.includes(searchProperties[sp].question)) {
                  people[index].problemQuestions.push(searchProperties[sp].question)
                }
              }

              // if sub search properties
              // search through all the surveys again to find if person in main problem is in one of the sub problems
              if (subSearchProperties) {
                for (let i = 0; i < this.surveys.length; i++) {
                  let subSurvey = this.surveys[i]
                  for (let si = 0; si < subSearchProperties.length; si++) {
                    if (subSurvey[subSearchProperties[si].question] && subSurvey[subSearchProperties[si].people].map(p => p.id).includes(person.id)) {
                      // add person sub problem
                      let index = people.findIndex(x => x.id === person.id)
                      if (index > -1 && !people[index].surveys.map(s => s.id).includes(subSurvey.id)) {
                        people[index].surveys.push(subSurvey)
                      }

                      if (index > -1 && !people[index].problemQuestions.includes(subSearchProperties[si].question)) {
                        people[index].problemQuestions.push(subSearchProperties[si].question)
                      }
                    }
                  }
                }
              }

            }

            // no people named, add blank person
            if (!survey[searchProperties[sp].people].length) {
              if (!people.map(pn => pn.id).includes(null)) {
                // if person not already in results/people
                people.push(
                    {
                      id: null,
                      name: blankPersonName,
                      surveys: [survey],
                      problemQuestions: [searchProperties[sp].question]
                    }
                )
              } else {
                // add another survey to person if survey not already in person
                let index = people.findIndex(x => x.id === null)
                if (index > -1 && !people[index].surveys.map(s => s.id).includes(survey.id)) {
                  people[index].surveys.push(survey)
                }
                if (index > -1 && !people[index].problemQuestions.includes(searchProperties[sp].question)) {
                  people[index].problemQuestions.push(searchProperties[sp].question)
                }
              }
            }
          }
        }

      }
      return people
    },

  },
  data() {
    return {
      singleModeClient: null,
      links: {
        disabled: {
          video: null,
          pdf: null,
          web: 'https://dm-legal.co.uk/services/disabled-trust/'
        },
        discretionary: {
          video: null,
          pdf: null,
          web: 'https://dm-legal.co.uk/services/discretionary-trusts/'
        }
      },
      text: {
        pricing: {
          disabled: {
            single:
                'To add a discretionary trust into your will costs £349. If this is organised at the same time as your ' +
                'Will the fee is discounted by £150, reducing the price to £199 including VAT.',
            joint:
                'To add mirror discretionary trusts into each will our fee is £449. If these are organised at the same ' +
                'time as your Wills the fee is discounted by £150, reducing the price to £299 including VAT.',
          },
          discretionary: {
            single:
                'To add a discretionary trust into your will costs £349. If this is organised at the same time as your ' +
                'Will the fee is discounted by £150, reducing the price to £199 including VAT.',
            joint:
                'To add mirror discretionary trusts into each will our fee is £449. If these are organised at the same ' +
                'time as your Wills the fee is discounted by £150, reducing the price to £299 including VAT.',
          }
        }
      }
    };
  }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{class:_vm.marginBottom ? _vm.marginBottom : 'mb-24'},[(!_vm.topLabel && _vm.label)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-lg-end",attrs:{"lg":"4"}},[_c('label',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.d500",value:({
                  customClass: 'label-detail',
                  title: _vm.popoverTitle || 'Question',
                  content: _vm.popoverContent
              }),expression:"{\n                  customClass: 'label-detail',\n                  title: popoverTitle || 'Question',\n                  content: popoverContent\n              }",modifiers:{"hover":true,"top":true,"d500":true}}]},[_vm._v(_vm._s(_vm.label)+" ")])]):_vm._e(),_c('b-col',{attrs:{"cols":_vm.inputWidth || 8}},[(_vm.topLabel && _vm.label)?_c('label',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.d500",value:({
                  customClass: 'label-detail',
                  title: _vm.popoverTitle || 'Question',
                  content: _vm.popoverContent
              }),expression:"{\n                  customClass: 'label-detail',\n                  title: popoverTitle || 'Question',\n                  content: popoverContent\n              }",modifiers:{"hover":true,"top":true,"d500":true}}],staticClass:"mb-2"},[_vm._v(_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-select',{class:{
                  'invalid-feedback-v-select': _vm.error
              },attrs:{"create-option":(option) => ({ code: option.toLowerCase(), label: option }),"options":_vm.options,"placeholder":_vm.placeholder,"taggable":_vm.taggable,"reduce":(option) => option.code,"size":"lg"},on:{"input":function($event){return _vm.$emit('update')}},model:{value:(_vm.formValue),callback:function ($$v) {_vm.formValue=$$v},expression:"formValue"}}),(_vm.error)?_c('div',{staticClass:"invalid-feedback-label"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="{ 'trans-section-locked': locked }">
    <div class="d-flex align-items-center page-num-container pt-80"
        :class="{'completed': progress.percentage === 100}"
    >
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon" />
      </div>
      <h4>Property Details</h4>
    </div>
    <b-row>
      <b-col lg="7">
        <p class="mb-lg-32">Below is a detailed questionnaire about the property that you are mortgaging.
          Please complete this section and notify your mortgage advisor when you have found a property.
        </p>
        <div v-if="locked">
          <p v-if="hasHandedOver"
             class="mb-lg-32">
            This section is currently locked as the transaction has been 'Handed Over'. </p>
          <template v-else>
            <p>
            <span v-if="!previousSectionComplete">This section is currently locked as the previous section is
              incomplete.
            </span>
              <span v-if="!hasTraining">You do not have the relevant permissions to take this information, please
              email enquiries@dunham-mccarthy.co.uk for more information.</span>
            </p>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col md="4">
        <MortgagePropertySurveyCard
            :progress="progress"
            :transaction="transaction"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MortgagePropertySurveyCard from "./cards/MortgagePropertySurvey";

export default {
  name: 'MortgagePropertySurvey',
  components: {MortgagePropertySurveyCard},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    progress: {
      type: Object,
      required: true
    },
    previousSectionComplete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      trainingSkill: "basic",
      handoverStep: "after_client_profile",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes(this.trainingSkill);
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return (!this.previousSectionComplete || !this.hasTraining || this.hasHandedOver);
    },
  },
}
</script>

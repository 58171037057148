import { clone } from '@/mixins/clone';

export const pdfHelpers = {
    name: 'pdfHelpers',
    mixins: [clone],
    mounted() {
        this.$root.$on('showPdfModal', this.initPdf);
    },
    destroyed() {
        this.$root.$off('showPdfModal');
    },
    data() {
        return {
            show: {
                loading: false,
                clients: false,
                modal: false,
                editor: true,
                templateSelect: false,
                extraForm: false,
                ckeditor: false
            },
            selected: {
                transaction: null,
                func: null,
                template: null
            },
            clientOptions: [],
            form: {
                include_header: null,
                id: null,
                html: null,
                callBackFunction: null,
                url: null,
                template: null,
                clients: [],
                transaction: null,
                reference: null
            }
        };
    },
    methods: {
        initPdf(args) {
            // set form
            if ('html' in args && args.html) this.form.html = args.html;
            else this.show.templateSelect = true;

            if ('id' in args && args.id) this.form.id = args.id;
            else if (this.transaction) this.form.id = this.transaction.id;

            if ('url' in args && args.url) this.form.url = args.url;
            else this.form.url = 'letter';

            if ('ckEditor' in args) this.show.ckEditor = args.ckEditor;

            if ('callBackFunction' in args && args.callBackFunction)
                this.form.callBackFunction = args.callBackFunction;
            if ('title' in args && args.title) this.title = args.title;
            if ('editor' in args) {
                this.show.editor = args.editor;
            }
            if ('clientData' in args && args.clientData)
                this.clientOptions = args.clientData;
            if ('to' in args && args.to) {
                this.form.clients = args.to;
            } else if (this.transaction && this.transaction.clients) {
                this.form.clients = this.transaction.clients.map(
                    (client) => client.id
                );
                this.clientOptions = this.clone(this.transaction.clients);
            }
            if ('transaction' in args && args.transaction) {
                if (typeof args.transaction === 'object') {
                    this.form.transaction = args.transaction.id;
                } else if (typeof args.transaction === 'number') {
                    this.form.transaction = args.transaction;
                }
                this.selected.transaction = args.transaction;
            }
            if ('template' in args && args.template) {
                this.selected.template = args.template;
                this.show.templateSelect = false;
            }

            // show modal
            this.show.modal = true;
        },
        clearForm() {
            this.form.include_header = null;
            this.form.html = null;
            this.form.url = null;
            this.form.id = null;
            this.form.callBackFunction = null;
            this.form.clients = [];
            this.form.transaction = null;
            this.form.reference = null;
            this.form.url = null;
            this.show.templateSelect = false;
            this.show.editor = true;
            this.show.extraForm = false;
            this.selected.func = null;
            this.selected.transaction = null;
            this.selected.client = null;
            this.selected.family = null;
            this.title = null;
            this.show.clients = false;
        }
    }
};
<template>
  <div
      class="hero-sub-bar-two-header border-bottom-gray-200 bg-white w-100 d-flex flex-column justify-content-center">
    <b-container>
      <div class="hero-sub-bar-two-heading d-flex text-16 font-weight-400">
        <div class="d-flex hero-sub-bar-two-heading-stat pr-30"><i
            class="pr-12 text-22 text-mute i-Administrator"></i>
          {{ clientNameList({clients: transaction.clients}) }}
        </div>
        <div class="d-flex hero-sub-bar-two-heading-stat"><i class="pr-16 text-22 text-mute i-Stopwatch"></i>25
          Minutes
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import {clientHelpers} from "../../../../../mixins/clientHelpers";

export default {
  name: 'Header',
  mixins: [clientHelpers],
  props: {
    transaction: {
      type: Object,
      required: true
    }
  }
}
</script>
<script>
import { clientHelpers } from '@/mixins/clientHelpers';
import { dateFormat } from '@/mixins/dateFormat';

export default {
    render() {
        return null;
    },
    name: 'EstatePlanningAcceptedLetter',
    props: ['value', 'showData', 'clientOptions', 'selected', 'transaction'],
    mixins: [clientHelpers, dateFormat],
    mounted() {
        this.initTemplate();
        this.generateHtml();
    },
    watch: {
        options: {
            deep: true,
            handler() {
                this.generateHtml();
            }
        },
        'form.clients': {
            deep: true,
            handler() {
                this.generateHtml();
            }
        }
    },
    data() {
        return {
            options: {}
        };
    },
    computed: {
        form: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value;
            }
        },
        show: {
            set(value) {
                this.$emit('showData', value);
            },
            get() {
                return this.showData;
            }
        },
        owner() {
            return this.$store.getters.user
        }
    },
    methods: {
        initTemplate() {
            this.show.ckEditor = true;
            this.show.extraForm = false;
            this.show.clients = false;
            this.form.url = 'estate_planning_recommended_summary_pdf';
            this.form.id = this.transaction.estate_planning.id;
        },
        generateHtml() {
            this.form.html = `
     <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8">
    <title>Review Summary</title>

   ${this.css()}
</head>

<body>

<p class="w-100 text-right">${this.letterDate(new Date())}</p>

<p>
${this.clientAddress({
    address: this.form.clients.length
        ? this.clientOptions.find(
              (client) => client.id === this.form.clients[0]
          ).current_address
        : null,
    separator: '<br>'
})}
</p>
<h1>Initial Estate Planning Consultation - Summary</h1>

<p>${this.clientNameList({
                clients: this.form.clients,
                clientDetails: this.clientOptions
            })},</p>
<p>
  Following our initial consultation,
  below is the recommendation in relation to your estate planning needs.
  The list is not intended to be exhaustive, instead it should be seen as an
  overview of the core services recommended given your current situation.
</p>

<div class="policy-bullets">

${this.recommendationsGen()}

</div>

<br><br><br>
<p class="ml-50">Kind Regards</p>

<div class="ml-50"><strong>${this.owner.name}</strong></div>
<p class="ml-50">${this.owner.is_introducer.introducer}</p>

</body>

</html>
      `;
        },
        css() {
            return `<style type="text/css">

:root {
  font-size: 16px;
}
.new-page {
    page-break-before: always !important;
}

html {
  white-space: nowrap;
}

body {
  padding: 0 40px;
}

body, p {
  text-align: justify;
  white-space: normal;
  font-family: "Times New Roman", Georgia, Serif;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.1rem;
  display: block;
  margin: 0;
}

*, :after, :before {
    box-sizing: border-box;
}

h1 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 40px;
}

h2 {
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 40px;
  margin-bottom: 20px;
  text-decoration: underline
}

p {
  margin-bottom: 10px;
}

span {
  margin-bottom: 20px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-400 {
  font-weight: 400;
}

.w-100 {
  width: 100% !important
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-12 {
  margin-top: 12px;
}

.list-priorities {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
  margin-block-end: 0;
}

.list-priorities li {
  display: block;
  position: relative;
  margin-left: 53px;
}

.list-priorities li:before {
  position: absolute;
  content: "#" counter(item);
  counter-increment: item;
  font-weight: 600;
  color: #663069;
  left: -28px;
  top: 0px;
  font-size: 15px;
}

.list-square {
  margin-left: 0;
  padding-left: 0;
  margin-block-end: 0;
}

.list-square li {
  display: block;
  position: relative;
  margin-left: 43px;
}

.list-square li:before {
  position: absolute;
  font-weight: 600;
  background-color: #663069;
  left: -19px;
  top: 7px;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 2px;
}

.policy-bullets {
  counter-reset: policy;
  margin-left: 0;
  padding-left: 0;
  margin-block-end: 0;
}

.policy-bullets ul,
.policy-bullets .sec-title-wrapper{
  padding-inline-start: 0 !important;
  margin-block-start: 0 !important;
  margin-inline-start: 0 !important;
  list-style: none;
  padding-left: 0;
}

.policy-bullets li {
  display: block;
  position: relative;
  text-decoration: none;
}

.policy-bullets li {
  display: block;
  position: relative;
  text-decoration: none;
}

.policy-bullets.policy-number li:before {
  content: "Policy " counter(policy);
  counter-increment: policy;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 40px;
  color: #663069;
  margin-bottom: 20px;
  margin-right: 8px;
}

.policy-bullets .h5 {
  margin-bottom: 10px;
  margin-top: 30px;
}

.policy-bullets .h5 li {
  font-weight: 600;
  font-size: 0.9rem;
}

.feature {
  padding-right: 25px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
}

.col-3 {
    flex: 0 0 30%;
    max-width: 30%;
    position: relative;
    min-height: 1px;
}

.col-9 {
    flex: 0 0 70%;
    max-width: 70%;
    position: relative;
    min-height: 1px;
}

h6 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0.8rem;
    font-weight: 600;
}

h6 .text-mute {
    font-weight: 500;
}

   </style>`;
        },
        recommendationsGen() {
            let string = '';
            let recommendations = this.transaction.estate_planning_custom_recommendation;

            if (recommendations && recommendations.length) {
                for (let i in recommendations) {
                    let recommendation = recommendations[i];
                    string += `<h2><u>${recommendation.title}`;
                    if (recommendation.title === 'Life Insurance Review') {
                      if (recommendation.accepted) {
                        string += ' <span class="font-weight-400">(Organised as part of the Will Writing process)</span>';
                      } else {
                        string += ' <span class="font-weight-400">(Recommended)</span>'
                      }
                    } else if (recommendation.accepted) {
                      string += ' <span class="font-weight-400">(Accepted / In progress)</span>';
                    } else {
                      string += ' <span class="font-weight-400">(Recommended)</span>'
                    }


                    string += `</u></h2>
                    <h6>${this.clientNameList({
                        clients: recommendation.clients,
                        clientDetails: this.transaction.clients
                    })}</h6>
                    ${recommendation.description}${recommendation.benefits}
                    ${
                        recommendation.link_web
                            ? `<p>Visit our website at ${recommendation.link_web} for additional information</p>`
                            : ''
                    }
                       ${
                        recommendation.link_pdf
                            ? `<p>A pdf details this recommendation can be found here ${recommendation.link_pdf}</p>`
                            : ''
                    }
                        ${
                        recommendation.link_video
                            ? `<p>A video explaing this recommendation can be found here ${recommendation.link_video}</p>`
                            : ''
                    }
                    <p>${recommendation.pricing}</p>`;
                }
            }
            return string;
        }
    }
};
</script>

<script>
import { clientHelpers } from '@/mixins/clientHelpers';
import { dateFormat } from '@/mixins/dateFormat';
import { recommendationCommonHelpers } from './recommendationCommonHelpers';
import { textHelpers } from '@/mixins/textHelpers';
import { clone } from '@/mixins/clone';

export default {
  name: 'LifeInsuranceReviewEngine',
  mixins: [
    clientHelpers,
    recommendationCommonHelpers,
    dateFormat,
    textHelpers,
    clone
  ],
  render() {
    return null;
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    adviceRequired() {
      let surveys = [
        ...this.hasYoungClients,
        ...this.hasYoungChildren,
        ...this.hasMortgage
      ].reduce((acc, o) => {
        if (!acc.map((ac) => ac.id).includes(o.id))
          return acc.concat(o);
        return acc;
      }, []);
      // if single mode client is set, then filter surveys required to the client selected
      if (this.singleModeClient)
        surveys = surveys.filter(
            (s) => s.client === this.singleModeClient
        );
      return surveys;
    },
    hasYoungClients() {
      // surveys with clients under 50 people and low cash
      let age = 50;
      return this.hasLowCashAssets.filter((s) =>
          this.isUnderAge(s.clientData.profileDOB, age)
      );
    },
    hasYoungChildren() {
      // surveys that have low cash and young children
      return this.hasLowCashAssets.filter(
          (s) =>
              s.clientData.people.filter((p) => this.isChildOf(p))
                  .length > 0
      );
    },
    youngChildrenPeople() {
      // people who are young children
      return this.adviceRequired.reduce(
          (acc, s) =>
              acc.concat(
                  s.clientData.people.filter(
                      (p) =>
                          this.isChildOf(p) &&
                          !acc.map((ac) => ac.id).includes(p.id)
                  )
              ),
          []
      );
    },
    hasMortgage() {
      // surveys that have low cash and a mortgage
      return this.hasLowCashAssets.filter(
          (s) =>
              s.clientData.liabilities.filter(
                  (l) => l.liabilityType === 'Mortgage'
              ).length
      );
    },
    hasLowCashAssets() {
      // clients that have large cash assets (over this.largeCashAmount (£150,000))
      return this.surveys.filter((s) => {
        return (
            s.clientData.assets
            .filter((a) => {
              return ['Bank Accounts', 'Savings'].includes(
                  a.assetType
              );
            })
            .reduce((acc, o) => acc + o.assetValue, 0) <
            this.largeCashAmount
        );
      });
    },
    havePolicies() {
      return this.adviceRequired.filter(
          (s) =>
              s.clientData.policies.filter((p) =>
                  [
                    'Critical Illness',
                    'Life Cover',
                    'Life & Critical Illness'
                  ].includes(p.policyType)
              ).length
      );
    },
    havePartners() {
      // surveys where the clients are in a relationship
      return this.adviceRequired.filter((s) =>
          ['married', 'partnered', 'engaged', 'civil partner'].includes(
              s.clientData.profileRelationshipStatus
          )
      );
    },
    lifeInsuranceReviewedRecently() {
      // have policies and have been reviewed recently
      return this.havePolicies.filter(
          (s) => s.life_insurance_reviewed_recently
      );
    },
    lifeInsuranceNotReviewedRecently() {
      // have policies and not reviews recently
      return this.havePolicies.filter(
          (s) => s.life_insurance_reviewed_recently === false
      );
    },
    lifeInsuranceNotProfessionallyNotInTrust() {
      // have policies not in trust and not professionally done
      return this.lifeInsuranceNotInTrust.filter(
          (s) => s.life_insurance_professionally_arranged === false
      );
    },
    lifeInsuranceNotInTrust() {
      // have policies but not in trust
      return this.havePolicies.filter(
          (s) => s.life_insurance_all_in_trust === false
      );
    },
    hasNoLifeInsurance() {
      // has confirmed that they have no life insurance
      return this.adviceRequired.filter((s) => s.life_insurance_none);
    },
    hasNoLifeInsurancePoliciesListed() {
      // they have life insurance but none is listed in assets more than likely
      return this.adviceRequired.filter(
          (s) => s.life_insurance_none === false
      );
    }
  },
  methods: {
    async createRecommendations(singleMode = false) {
      // singleMode used for splitting joint recommendation into two single recommendations
      let result = [];

      // guards
      if (this.disabled) return result;
      if (!this.adviceRequired.length) return result;

      // no invoice item attached to recommendation

      if (this.adviceRequired.length === 1 || singleMode) {
        // take a copy of required storages as it will be modified for single mode
        let adviceRequiredCopy = this.clone(this.adviceRequired);
        // single product recommendation
        for (let i = 0; i < adviceRequiredCopy.length; i++) {
          // assign single mode client the current client from copy
          if (singleMode)
            this.singleModeClient = adviceRequiredCopy[i].client;
          // create recommendation
          result.push({
            title: 'Life Insurance Review',
            description: this.description(),
            benefits: this.benefits(),
            pricing: this.text.pricing.single,
            clients: [adviceRequiredCopy[i].client],
            transaction: this.transaction.id,
            auto_introducer_priority: 0,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
        // reset single model client
        if (singleMode) this.singleModeClient = null;
      } else {
        // joint product recommendation

        // create recommendation
        result.push({
          title: 'Life Insurance Review',
          description: this.description(),
          benefits: this.benefits(),
          pricing: this.text.pricing.joint,
          clients: this.adviceRequired.map((survey) => survey.client),
          transaction: this.transaction.id,
          auto_introducer_priority: 0,
          link_web: this.links.web,
          link_pdf: this.links.pdf,
          link_video: this.links.video
        });
      }
      return result;
    },
    description() {
      let string = ``;

      string += `Insurance is an essential tool in the estate planning toolkit`;
      if (this.youngChildrenPeople.length) {
        string += `, even more so for those with young children`;
        if (this.hasMortgage.length) {
          string += ` and mortgage commitments`;
        }
      } else if (this.hasMortgage.length) {
        string += `, even more so for those with mortgages and other financial commitments`;
      }
      string += `. As part of the estate planning process we always record details of any existing arrangements. As well as simply recording existing policy details, we also review policies to ensure they are the most suitable and competitive available, this is especially useful because cover options and the wider insurance market is constantly evolving.<br><br>`;

      if (
          this.lifeInsuranceReviewedRecently.length ||
          this.lifeInsuranceNotReviewedRecently.length
      ) {
        string += `We recommend that a detailed examination of your current insurance arrangements be conducted, with a view to ensuring that any provisions fully reflect your current circumstances. `;
      }
      if (this.lifeInsuranceNotReviewedRecently.length) {
        let who = '';
        if (this.lifeInsuranceNotReviewedRecently.length > 1) {
          who = 'neither of you have reviewed';
        } else {
          who = `${this.clientFirstName(
              this.lifeInsuranceNotReviewedRecently[0].clientData
          )} has not reviewed`;
        }
        let yourHisHer = '';
        if (this.adviceRequired.length === 1) {
          yourHisHer = `your`;
        } else if (this.lifeInsuranceNotReviewedRecently.length > 1) {
          yourHisHer = 'your';
        } else {
          yourHisHer = `${this.hisHer(
              this.lifeInsuranceNotReviewedRecently[0].clientData
                  .profileSex
          )}`;
        }
        string += `This is particularly important as ${who} ${yourHisHer} arrangements recently. `;
      }

      if (this.lifeInsuranceNotInTrust.length) {
        let to = '';
        let who = '';
        let partner = '';
        if (this.havePartners.length) {
          partner = 'your partner';
        }
        let and = partner ? 'and' : '';

        if (this.youngChildrenPeople.length) {
          to = `, ${partner}${and}your children for example`;
        }
        if (this.adviceRequired.length === 1) {
          who = `your`;
        } else if (this.lifeInsuranceNotInTrust.length > 1) {
          who = 'your';
        } else {
          who = `${this.clientFirstName(
              this.lifeInsuranceNotInTrust[0].clientData
          )}'s`;
        }
        string += `<br><br>Also, insurance policies should be held in trust so payment can be made without tax (policies held in trust are normally outside of the taxable estate) and without delay, directly to your beneficiaries${to}. Since ${who} policies are not in trust this should be also be reviewed a part of the process.`;
      } else if (this.lifeInsuranceNotProfessionallyNotInTrust.length) {
        let to = '';
        let who = '';
        let partner = '';
        if (this.havePartners.length) {
          partner = 'your partner';
        }
        let and = partner ? 'and' : '';

        if (this.youngChildrenPeople.length) {
          to = `, ${partner}${and}your children for example`;
        }
        if (this.adviceRequired.length === 1) {
          who = `your`;
        } else if (this.lifeInsuranceNotInTrust.length > 1) {
          who = 'your';
        } else {
          who = `${this.clientFirstName(
              this.lifeInsuranceNotInTrust[0].clientData
          )}'s`;
        }
        string += `<br><br>Also, insurance policies should be held in trust so payment can be made without tax (policies held in trust are normally outside of the taxable estate) and without delay, directly to your beneficiaries${to}. As ${who} policies were not organised by a professional, the benefits of insurance related trusts should be explored as part of the review process.`;
      }

      if (this.hasNoLifeInsurancePoliciesListed.length) {
        let who = '';
        let youHeShe = '';
        let yourHisHer = '';
        let review = '';

        if (this.adviceRequired.length === 1) {
          who = `You have`;
        } else if (this.hasNoLifeInsurancePoliciesListed.length > 1) {
          who = 'You have both';
        } else {
          who = `${this.clientFirstName(
              this.hasNoLifeInsurancePoliciesListed[0].clientData
          )} has`;
        }
        if (
            this.adviceRequired.length === 1 ||
            this.hasNoLifeInsurancePoliciesListed.length > 1
        ) {
          youHeShe = `you have`;
        } else {
          youHeShe = `${this.heShe(
              this.hasNoLifeInsurancePoliciesListed[0].clientData
                  .profileSex
          )} has`;
        }
        if (
            this.adviceRequired.length === 1 ||
            this.hasNoLifeInsurancePoliciesListed.length > 1
        ) {
          yourHisHer = `your`;
        } else {
          yourHisHer = `${this.hisHer(
              this.hasNoLifeInsurancePoliciesListed[0].clientData
                  .profileSex
          )}`;
        }
        if (this.adviceRequired.length === 1) {
          review = `you review`;
        } else if (this.hasNoLifeInsurancePoliciesListed.length > 1) {
          review = 'you both review';
        } else {
          review = `${this.clientFirstName(
              this.hasNoLifeInsurancePoliciesListed[0].clientData
          )} review`;
        }
        string += `${who} advised us that ${youHeShe} life insurance in place but we have not yet recorded the full details, once full details have been provided we suggest that ${review} ${yourHisHer} current insurance arrangements to ensure they fully reflect ${yourHisHer} current circumstances and are the most competitive available.  `;
      }

      if (this.hasNoLifeInsurance.length) {
        let who = '';
        let youHeShePuts = '';
        let yourHisHer = '';
        let youHeShe = '';

        if (this.adviceRequired.length === 1) {
          who = `You do not`;
        } else if (this.hasNoLifeInsurance.length > 1) {
          who = 'Neither of you';
        } else {
          who = `${this.clientFirstName(
              this.hasNoLifeInsurance[0].clientData
          )} does not`;
        }
        if (
            this.adviceRequired.length === 1 ||
            this.hasNoLifeInsurance.length > 1
        ) {
          yourHisHer = `your`;
        } else {
          yourHisHer = `${this.hisHer(
              this.hasNoLifeInsurance[0].clientData.profileSex
          )}`;
        }
        if (
            this.adviceRequired.length === 1 ||
            this.hasNoLifeInsurance.length > 1
        ) {
          youHeShePuts = `you put`;
        } else {
          youHeShePuts = `${this.heShe(
              this.hasNoLifeInsurance[0].clientData.profileSex
          )} puts`;
        }
        if (
            this.adviceRequired.length === 1 ||
            this.hasNoLifeInsurance.length > 1
        ) {
          youHeShe = `you`;
        } else {
          youHeShe = `${this.heShe(
              this.hasNoLifeInsurance[0].clientData.profileSex
          )}`;
        }

        string += `${who} currently have any life insurance or critical illness protection in place. We strongly recommend that ${youHeShePuts} cover in place`;

        if (this.youngChildrenPeople.length) {
          string += `to protect ${yourHisHer} family (this is especially important as ${youHeShe} have financially dependant children)`;
        }

        if (this.hasMortgage.length) {
          string += `, as a minimum level of cover we would generally suggest organising policies that would repay ${yourHisHer} mortgage debts`;
        }

        string += '.';
        // TODO add text for no insurance and has a partner
        if (this.havePartners.length) {
          // very complicated to get who's partner/your partner/both of your partners/James' partner etc
          // suggest something simple
          string += ``;
        }
      }
      return `<p>${string}</p>`;
    },
    benefits() {
      return '';
    }
  },
  data() {
    return {
      singleModeClient: null,
      largeCashAmount: 150000,
      links: {
        web: null,
        video: null,
        pdf: null
      },
      text: {
        pricing: {
          single:
              'Where applicable we will review your insurance arrangements free of charge as part of the estate planning process. We do not charge to advise on, or organise new term assurance or family protection policies, this includes organising the relevant trust (a fee of £199 is payable to place an existing policy in trust).',
          joint:
              'Where applicable we will review your insurance arrangements free of charge as part of the estate planning process. We do not charge to advise on, or organise new term assurance or family protection policies, this includes organising the relevant trust (a fee of £199 is payable to place an existing policy in trust).'
        }
      }
    };
  }
};
</script>

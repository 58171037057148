<template>
  <div :class="{ 'trans-section-locked': locked }">
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"
           :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Mortgage Requirements</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">The below questionnaire
          contains various questions about the mortgage requirements.
        </p>
        <div v-if="locked">
          <p v-if="hasHandedOver"
             class="mb-lg-32">
            This section is currently locked as the transaction has been 'Handed Over'. </p>
          <template v-else>
            <p>
            <span v-if="!previousSectionComplete">This section is currently locked as the previous section is
              incomplete.
            </span>
              <span v-if="!hasTraining">You do not have the relevant permissions to take this information, please
              email enquiries@dunham-mccarthy.co.uk for more information.</span>
            </p>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col md="4">
        <MortgageSurveyCard
            :progress="progress"
            :survey="transaction.mortgage_survey"
            :transaction="transaction"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import MortgageSurveyCard from "./cards/MortgageSurveyCard";

export default {
  name: 'MortgageSurvey',
  components: {MortgageSurveyCard},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    progress: {
      type: Object,
      required: true
    },
    previousSectionComplete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      trainingSkill: "basic",
      handoverStep: "after_client_profile",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes(this.trainingSkill);
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return (!this.previousSectionComplete || !this.hasTraining || this.hasHandedOver);
    },
  }
}
</script>

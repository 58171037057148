<template>
  <div :class="{ 'trans-section-locked': locked }">
    <Handover v-if="!hasHandedOver && (!sectionComplete || !nextSectionComplete)"
              :step="handoverStep"
              :transaction="transaction"
              :clientProgress="progress"
              @refreshTransaction="$emit('refreshTransaction', $event)">
      <PreProfilesHandoverInfo />
    </Handover>
    <div :class="{ completed: completed }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon" />
      </div>
      <h4 class="trans-section-title">
        Client Profile{{ progress.length > 1 ? "s" : null }} </h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Click on the profile{{ progress.length === 2 ? "s" : null }} below to add basic information about {{ progress.length > 1 ? "each" : "the" }} clients status, address, dependants, assets and liabilities. </p>
        <div v-if="locked">
          <p v-if="hasHandedOver"
             class="mb-lg-32">
            This section is currently locked as the transaction has been 'Handed Over'. </p>
          <template v-else>
            <p>
              <span v-if="!hasTraining">You do not have the relevant permissions to complete this section, please
              email enquiries@dunham-mccarthy.co.uk for more information.</span>
            </p>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(clientProfile, index) in progress"
             :key="`clientProfile${index}`"
             md="4">
        <ClientProfileCard
            :clientProfile="clientProfile"
            :transaction="transaction"
            :will="wills.find(will => will.client.id === clientProfile.client.id)"
            :estatePlanning="estatePlanning.find(ep => ep.client.id === clientProfile.client.id)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ClientProfileCard from "./cards/ClientProfile";
import Handover from "./models/handover/Handover";
import PreProfilesHandoverInfo from "./models/handover/PreProfilesHandoverInfo";

export default {
  name: "ClientProfiles",
  components: {
    PreProfilesHandoverInfo,
    Handover,
    ClientProfileCard,
  },
  props: {
    progress: {
      type: Array,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    nextSectionComplete: {
      type: Boolean,
      required: true,
    },
    wills: {
      type: Array,
      required: false
    },
    estatePlanning: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      trainingSkill: "basic",
      handoverStep: "before_client_profile",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes(this.trainingSkill);
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return !this.hasTraining || this.hasHandedOver;
    },
    sectionComplete() {
      return (this.progress.filter((p) => p.percentage === 100).length === this.progress.length);
    },
  },
};
</script>

<script>
import { clientHelpers } from '@/mixins/clientHelpers';
import { dateFormat } from '@/mixins/dateFormat';
import { recommendationCommonHelpers } from './recommendationCommonHelpers';
import { textHelpers } from '@/mixins/textHelpers';
import { clone } from '@/mixins/clone';

export default {
  name: 'StorageRecommendationEngine',
  mixins: [
    clientHelpers,
    recommendationCommonHelpers,
    dateFormat,
    textHelpers,
    clone
  ],
  render() {
    return null;
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    storagesRequired() {
      let surveys = this.surveys.filter(
          (s) =>
              s.will_existing === false ||
              (s.will_existing &&
                  (s.changes_relationship ||
                      s.changes_dependants ||
                      s.changes_appointed === false ||
                      s.changes_estate ||
                      s.changes_will_other))
      );
      // if single mode client is set, then filter surveys required to the client selected
      if (this.singleModeClient)
        surveys = surveys.filter(
            (s) => s.client === this.singleModeClient
        );
      return surveys;
    },
    youngClients() {
      // sorted surveys with clients under 50 people by age oldest first: required in order for description text
      let age = 50;
      return this.storagesRequired
      .filter((s) => this.isUnderAge(s.clientData.profileDOB, age))
      .sort(
          (a, b) =>
              new Date(a.clientData.profileDOB) -
              new Date(b.client.profileDOB)
      );
    },
    youngChildren() {
      // surveys that have young children
      return this.storagesRequired.filter(
          (s) =>
              s.clientData.people.filter((p) => this.isChildOf(p))
                  .length > 0
      );
    },
    youngChildrenPeople() {
      // people who are young children
      return this.storagesRequired.reduce(
          (acc, s) =>
              acc.concat(
                  s.clientData.people.filter(
                      (p) =>
                          this.isChildOf(p) &&
                          !acc.map((ac) => ac.id).includes(p.id)
                  )
              ),
          []
      );
    }
  },
  methods: {
    async createRecommendations(singleMode = false) {
      // singleMode used for splitting joint recommendation into two single recommendations
      let result = [];

      // guards
      if (this.disabled) return result;
      if (!this.storagesRequired.length) return result;

      // find invoice service
      let singleStorageCode = 'STORAGE-S';
      let jointStorageCode = 'STORAGE-M';

      let singleStorageProduct = this.products.services.find(
          (option) => option.code === singleStorageCode
      );
      let jointStorageProduct = this.products.services.find(
          (option) => option.code === jointStorageCode
      );

      if (this.storagesRequired.length === 1 || singleMode) {
        // take a copy of required storages as it will be modified for single mode
        let storageRequiredCopy = this.clone(this.storagesRequired);
        // single product recommendation
        for (let i = 0; i < storageRequiredCopy.length; i++) {
          // assign single mode client the current client from copy
          if (singleMode)
            this.singleModeClient = storageRequiredCopy[i].client;
          // create recommendation
          result.push({
            title: 'Document Storage',
            description: this.description(),
            benefits: this.benefits(),
            pricing: this.text.pricing.single,
            clients: [storageRequiredCopy[i].client],
            transaction: this.transaction.id,
            auto_introducer_priority: 0,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video,
            invoice_service: singleStorageProduct?.id,
          });
        }
        // reset single model client
        if (singleMode) this.singleModeClient = null;
      } else {
        // joint product recommendation

        // create recommendation
        result.push({
          title: 'Document Storage',
          description: this.description(),
          benefits: this.benefits(),
          pricing: this.text.pricing.joint,
          clients: this.storagesRequired.map(
              (survey) => survey.client
          ),
          transaction: this.transaction.id,
          auto_introducer_priority: 0,
          link_web: this.links.web,
          link_pdf: this.links.pdf,
          link_video: this.links.video,
          invoice_service: jointStorageProduct?.id,
        });
      }

      return result;
    },
    description() {
      // default text for all
      let string =
              '<p>Documents need to be updated and re-published when your situation or wishes change. ' +
              'This might be if you move home, have a change in status or inherit assets for example. ';
      // clients that are young or have young children text
      if (this.youngClients.length || this.youngChildren.length) {
        if (this.youngClients.length) {
          let lifeExpectancy = 78;
          let lifeLeft =
                  lifeExpectancy -
                  this.ageYears(
                      this.youngClients[0].clientData.profileDOB
                  );
          // round number to decades
          let roundedDecades = Math.round(lifeLeft / 10) * 10;
          // no need to protect against negative decades as all clients are under 50

          string +=
              "Given that on average people live into their late 70's and people tend to update " +
              `their will every 5-10 years you are likely to revise your wishes ${
                  roundedDecades / 10
              } - ` +
              `${
                  roundedDecades / 5
              } times over the next ${roundedDecades}+ years. Additionally, important ` +
              'documents should be stored securely. Your Will in particular cannot be replaced as only an ' +
              'original signed will can be admitted to probate. ';
        }
        if (this.youngChildren.length) {
          let start = '';
          if (
              this.youngChildren.length > 1 ||
              this.storagesRequired.length === 1
          ) {
            start = 'Also, when your';
          } else {
            start = `Also, when ${this.clientFirstName(
                this.youngChildren[0].clientData
            )}'s`;
          }

          let relationship =
                  this.youngChildrenPeople.length > 1
                      ? 'children'
                      : this.youngChildrenPeople[0]
                          .relationship_to_client;
          let themHimHer =
                  this.youngChildrenPeople.length > 1
                      ? 'them'
                      : this.himHer(this.youngChildrenPeople[0].gender);
          let getsGet =
                  this.youngChildren.length > 1 ? 'get' : 'gets';

          let executorsTrustees =
                  this.youngChildrenPeople.length > 1
                      ? 'executors / trustees'
                      : 'executor / trustee';

          string +=
              `${start} ${relationship} ${getsGet} older you may wish to revise your ` +
              `Will to appoint ${themHimHer} as your ${executorsTrustees} etc.`;
        }
      }
      string += '</p>';
      string += this.text.required;
      return string;
    },
    benefits() {
      return (
          `<ul class="mt-16 list-policies"><li class="mb-12">${this.text.reasons.peace}</li>` +
          `<li class="mb-12">${this.text.reasons.updates}</li>` +
          `<li class="mb-12">${this.text.reasons.register}</li>` +
          `<li class="mb-12">${this.text.reasons.certificates}</li>` +
          `<li class="mb-12">${this.text.reasons.copies}</li></ul>`
      );
    }
  },
  data() {
    return {
      singleModeClient: null,
      links: {
        video: null,
        pdf: null,
        web: 'https://dm-legal.co.uk/services/document-storage/'
      },
      text: {
        reasons: {
          peace:
              '<strong>Peace of Mind -</strong> over the years wills can go missing or become damaged, ' +
              'using our storage service, you will have the peace of mind that your Will is stored in a ' +
              'secure, waterproof and fireproof storage facility.',
          updates:
              '<strong>Free Updates -</strong> circumstances change over the years, when you have ' +
              'your Will stored with us we can redraft your Will each time your situation changes.',
          register:
              '<strong>National Will Register -</strong> we register your Will on the National ' +
              'Will Register free of charge (usually £30 per Will), this ensures that the document ' +
              'can always be found.',
          certificates:
              '<strong>Storage Certificates -</strong> both yourself and your executors ' +
              'are provided with certificates confirming that we have your original Will and how ' +
              'to access it.',
          copies:
              '<strong>Copy Documents -</strong> for your records hard copies and electronic ' +
              'copies of all documents held in storage are provided.'
        },
        required:
            '<p>For these reasons we recommend the updates and storage enhancement, this covers the secure ' +
            'storage of your Will(s) and also includes registration on the national will register ' +
            '(usually £30 per person) and free updates in the future.</p>',
        pricing: {
          single:
              'To store and update your estate related documents costs £29 per year.',
          joint:
              'To store and update a single persons estate related documents costs £29 per year, for a ' +
              'couple we apply a £20 discount making the package £39 in total for both of you.'
        }
      }
    };
  }
};
</script>

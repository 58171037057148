<template>
  <div>
    <will-recommendation-engine
        ref="will"
        :disabled="recommendationBlackList.includes('will')"
        :products="products"
        :transaction="transaction"
    />
    <lpa-recommendation-engine
        ref="lpa"
        :disabled="recommendationBlackList.includes('lpa')"
        :products="products"
        :transaction="transaction"
    />
    <ppt-recommendation-engine
        ref="ppt"
        :disabled="recommendationBlackList.includes('ppt')"
        :products="products"
        :transaction="transaction"
    />
    <StorageRecommendationEngine
        ref="storage"
        :disabled="recommendationBlackList.includes('storage')"
        :products="products"
        :transaction="transaction"
    />
    <LifeInsuranceReviewEngine
        ref="lifeInsuranceReview"
        :disabled="
                recommendationBlackList.includes('life_insurance_review')
            "
        :products="products"
        :transaction="transaction"
    />

    <DisabledPersonsTrustAndDiscretionaryTrustEngine
        ref="disabledPersonsAndDiscretionaryTrust"
        :disabled="recommendationBlackList.includes('dpt') || recommendationBlackList.includes('dt')"
        :products="products"
        :transaction="transaction"
    />
  </div>
</template>

<script>
import {clone} from '@/mixins/clone';
import {http} from '@/services';
import {toast} from '@/mixins/toast';

import WillRecommendationEngine from './engine/will';
import LpaRecommendationEngine from './engine/lpa';
import PptRecommendationEngine from './engine/ppt';
import StorageRecommendationEngine from './engine/storage';
import LifeInsuranceReviewEngine from './engine/life_insurance_review';
import DisabledPersonsTrustAndDiscretionaryTrustEngine
  from "@/views/introducer/transaction/detail/estatePlanning/recommendations/engine/dpt_dt";

export default {
  name: 'RecommendationHandler',
  components: {
    DisabledPersonsTrustAndDiscretionaryTrustEngine,
    StorageRecommendationEngine,
    PptRecommendationEngine,
    LpaRecommendationEngine,
    WillRecommendationEngine,
    LifeInsuranceReviewEngine
  },
  mixins: [clone, toast],
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    introducer: {
      type: Object,
      required: false
    }
  },
  computed: {
    recommendationBlackList() {
      if (!this.introducer) return [];
      return this.introducer.recommendation_black_list;
    }
  },
  methods: {
    async createAllRecommendations() {
      this.$emit('loading', true)
      let existingRecommendationsCount = this.transaction
          .estate_planning_custom_recommendation
          ? this.transaction.estate_planning_custom_recommendation.length
          : 0;

      // remove all previous recommendations
      await this.deleteAllRecommendations();

      // add recommended products to recommendations to be saved
      try {
        let recommendations = [
          ...(await this.$refs.will.createRecommendations()),
          ...(await this.$refs.lpa.createRecommendations()),
          ...(await this.$refs.ppt.createRecommendations()),
          ...(await this.$refs.storage.createRecommendations()),
          ...(await this.$refs.lifeInsuranceReview.createRecommendations()),
          ...(await this.$refs.disabledPersonsAndDiscretionaryTrust.createRecommendations())
        ];
        await this.saveRecommendations(recommendations);

        if (existingRecommendationsCount)
          this.toast('Recommendations updated');
        else this.toast('Recommendations created');

      } catch (e) {
        this.toast(
            'Error creating recommendations, see console for details',
            'Error',
            'danger'
        );
        console.log(e);
      }
      this.$emit('loading', false)
    },
    async saveRecommendations(recommendations) {
      // saves a list of recommendations to server
      for (let i in recommendations) {
        await http
            .post(
                'estate_planning_auto_recommendations',
                recommendations[i]
            )
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
      }
      this.$emit('refreshTransaction');
    },
    async deleteAllRecommendations() {
      // skip step if no recommendations
      if (
          this.transaction.estate_planning_custom_recommendation &&
          !this.transaction.estate_planning_custom_recommendation.length
      )
        return true;
      return http
          .delete('estate_planning_auto_recommendations', {
            params: {transaction_id: this.transaction.id}
          })
          .then(() => {
            return true;
          })
          .catch((error) => {
            console.log(error);
            return false;
          });
    },

    async deleteRecommendation(id) {
      await http
          .delete('estate_planning_custom_recommendation_detail', {
            params: {id: id}
          })
          .then(
              () => {
              },
              (error) => {
                console.log(error);
              }
          );
    },
    async createSplitRecommendation(recommendation) {
      // recommendations do not have a type so type is guessed from the recommendation title search
      let title = recommendation.title.toLowerCase();
      let recommendations = [];
      try {
        // create split recommendations
        if (title.includes('storage')) {
          recommendations = await this.$refs.storage.createRecommendations(
              true
          );
        } else if (title.includes('will')) {
          recommendations = await this.$refs.will.createRecommendations(
              true
          );
        } else if (title.includes('property protection trust')) {
          return this.toast('Can not split PPT', 'Error', 'danger');
        } else if (title.includes('lasting power of attorney')) {
          recommendations = await this.$refs.lpa.createRecommendations(
              true
          );
        } else if (title.includes('life insurance review')) {
          recommendations = await this.$refs.lifeInsuranceReview.createRecommendations(
              true
          );
        } else if (title.includes('discretionary') || title.includes('disabled')) {
          recommendations = await this.$refs.disabledPersonsAndDiscretionaryTrust.createRecommendations(
              true
          );
        }
        else {
          return this.toast(
              'Could not determine recommendation type'
          );
        }

        // delete old then save new
        await this.deleteRecommendation(recommendation.id);
        await this.saveRecommendations(recommendations);
        this.toast('Recommendation split');
      } catch (e) {
        this.toast(
            'Error splitting recommendations, see console for details',
            'Error',
            'danger'
        );
        console.log(e);
      }
    }
  }
};
</script>

import { render, staticRenderFns } from "./InputRadioMultipleBasic.vue?vue&type=template&id=7bb8704a&scoped=true"
import script from "./InputRadioMultipleBasic.vue?vue&type=script&lang=js"
export * from "./InputRadioMultipleBasic.vue?vue&type=script&lang=js"
import style0 from "./InputRadioMultipleBasic.vue?vue&type=style&index=0&id=7bb8704a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb8704a",
  null
  
)

export default component.exports
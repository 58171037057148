<template>
  <b-col md="5">
    <div class="section-title">
      <h4 class="pl-0 pr-3 pt-3 pb-3">
        <span class="pl-0">Handover Status</span>
      </h4>
    </div>

    <p style="margin-top: -30px" v-if="transaction.handover_point_by">
      This transaction was 'Handed Over' by {{ transaction.handover_point_by.name }} on
      {{ formatDateAndTimeLong({date: transaction.handover_point_date}) }}.
      It will now be completed by the specialists at Dunham McCarthy, you are unable to edit your clients details at
      this stage.
    </p>
    <p style="margin-top: -30px" v-else>
      This transaction is yet to be handed over for completion by the specialists at
      Dunham McCarthy.
    </p>

    <p>
      <a class="d-flex justify-items-center"
          href="#"
          @click="gotoContact">
        <i class="i-Speach-Bubble-Asking pr-2 text-secondary text-20"/>
        Ask Question / Submit Request
      </a>
    </p>

    <p>
      <a
          href="#"
          @click="show.modal = true">
        <i class="i-Speach-Bubble-Asking pr-2  text-secondary text-20"/>
        Guidance & Instructions
      </a>
    </p>

    <IntroductionMoreInformationModal
        v-model="show.modal">
      <component :is="showComponent" :key="1" :ref="showComponent"/>
    </IntroductionMoreInformationModal>

    <Handover
        v-if="!hasHandedOver"
        :normalButton="true"
        :step="currentHandoverStep"
        :transaction="transaction"
        :client-progress="progress"
        @refreshTransaction="$emit('refreshTransaction', $event)"
    >
      <component :is="showComponent" :key="1" :ref="showComponent"/>
    </Handover>
  </b-col>
</template>

<script>
import {dateFormat} from "@/mixins/dateFormat";
import {scrollTo} from "@/mixins/scrollTo";
import Handover from "@/views/introducer/transaction/detail/estatePlanning/models/handover/Handover";

import PostEPHandoverInfo
  from "@/views/introducer/transaction/detail/estatePlanning/models/handover/PostEPHandoverInfo";
import PreProfilesHandoverInfo
  from "@/views/introducer/transaction/detail/estatePlanning/models/handover/PreProfilesHandoverInfo";
import PostInstructionsHandoverInfo
  from "@/views/introducer/transaction/detail/estatePlanning/models/handover/PostInstructionsHandoverInfo";
import PostPaymentHandoverInfo
  from "@/views/introducer/transaction/detail/estatePlanning/models/handover/PostPaymentHandoverInfo";

import IntroductionMoreInformationModal from "./models/IntroductionMoreInformationModal.vue";


export default {
  name: "HandedOver",
  mixins: [dateFormat, scrollTo],
  components: {
    Handover,
    PostEPHandoverInfo,
    IntroductionMoreInformationModal,
    PostPaymentHandoverInfo,
    PostInstructionsHandoverInfo,
    PreProfilesHandoverInfo
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    currentHandoverStep: {
      type: String,
      required: false,
    },
    progress: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      show: {
        modal: false,
      },
    };
  },
  computed: {
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    showComponent() {
      if (this.currentHandoverStep === 'before_client_profile') return 'PreProfilesHandoverInfo'
      if (this.currentHandoverStep === 'after_estate_planning_surveys') return 'PostProfilesHandoverInfo'
      if (this.currentHandoverStep === 'after_deposit') return 'PostPaymentHandoverInfo'
      if (this.currentHandoverStep === 'after_product_instructions') return 'PostInstructionsHandoverInfo'
      return 'PreProfilesHandoverInfo'
    }
  },
  methods: {
    gotoContact() {
      this.scrollTo("#contact", 500);
    },
  },
};
</script>

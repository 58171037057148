<template>
  <b-card class="card-document-upload text-center mb-30 pt-20">
    <h3>{{ currency(invoice.total) }}</h3>
    <div class="text-15 mb-2">
      <div :class="{'badge-success' : invoice.status === 'end_paid', 'badge-warning' : invoice.status !== 'end_paid' }"
           class="badge p-1">Remainder to pay: {{ currency(remainderToPay) }}
      </div>
    </div>
    <template #footer>
      <small v-if="invoice.number"
             class="m-0">
        <span class="doc-data">Reference</span>
        <span class="doc-attribute">{{ invoice.number }}</span>
      </small> <small v-for="(item, index) in invoice.invoice_items"
                      :key="`invoice${invoice.id}Payment${index}`">
      <span class="doc-data">{{ item.service }}</span>
      <span class="doc-attribute">{{ currency(item.gross_price) }}</span>
    </small>
    </template>
  </b-card>
  <!--     Payments received-->
  <!--        <li v-for="(payment, index) in invoice.invoice_payments" :key="`invoice${invoice.id}Payment${index}`">-->
  <!--          {{ currency(payment.amount) }} paid on {{ formatDateDescription(payment.date) }}-->
  <!--        </li>-->
</template>

<script>
import {dateFormat} from "../../../../../../mixins/dateFormat";
import {currencyFormat} from "../../../../../../mixins/currencyFormat";

export default {
  name: 'InvoiceCard',
  mixins: [
    currencyFormat,
    dateFormat
  ],
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    remainderToPay() {
      let payments = this.invoice.invoice_payments.reduce((acc,
                                                           ob) => acc + ob.amount,
          0)
      return this.invoice.total - payments
    }
  }
}
</script>
<template>
  <div>
    <component
      v-if="transaction"
      ref="component"
      :is="transactionType"
      :transaction="transaction"
      @refreshTransaction="fetchTransaction"
    />
  </div>
</template>

<script>
import { http } from "@/services";

import TransactionEstatePlanningDetail from "./estatePlanning/index";
import TransactionMortgageDetail from "./mortgage/index";
import TransactionInsuranceDetail from "./insurance/index";

export default {
  name: "IntroducerTransactionDetail",
  created() {
    this.fetchTransaction();
  },
  components: {
    TransactionEstatePlanningDetail,
    TransactionMortgageDetail,
    TransactionInsuranceDetail,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    transactionType() {
      if (this.transaction) {
        if (this.transaction.type === "estate_planning")
          return "TransactionEstatePlanningDetail";
        else if (this.transaction.type === "insurance")
          return "TransactionInsuranceDetail";
        else if (this.transaction.type === "mortgage")
          return "TransactionMortgageDetail";
      }
      return null;
    },
  },
  data() {
    return {
      transaction: null,
    };
  },
  methods: {
    fetchTransaction(args) {
      http
        .get("transaction_detail", { params: { id: this.$route.params.id } })
        .then((response) => {
          this.transaction = response.data;
          if (args && args.showBooking) {
            this.$refs.component.showBookingModal();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

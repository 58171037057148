import {http} from "@/services";

export const productEstatePlanningServices = {
    mounted () {
        this.getEstatePlanningProductServices();
    },
    data() {
        return {
            products: {
                services: null,
                packages: null,
            }
        }
    },
    methods: {
        getEstatePlanningProductServices() {
            http
                .get("estate_planning_product_prices")
                .then((response) => {
                    this.products.services = response.data.services;
                    this.products.packages = response.data.packages;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        estatePlanningPrices () {
            return {
                singleWill: this.products.services?.find(service => service.code === 'WILL-S')?.gross_price,
                mirrorWill: this.products.services?.find(service => service.code === 'WILL-M')?.gross_price,
                singleLpa:  this.products.services?.find(service => service.code === 'LPAFH-S')?.gross_price,
                mirrorLpa: this.products.services?.find(service => service.code === 'LPAFH-M')?.gross_price,
                ppt: this.products.packages?.find(service => service.code === 'PPT')?.gross_price
            }
        }
    }
}

<template>
    <div>
        <label v-if="!hiddenLabel" :class="{ 'mr-24': horizontal }">{{
            label
        }}</label>

        <div :class="{ 'd-flex flex-row': horizontal }">
            <div
                v-for="(client, index) in options"
                :id="'client' + client.id"
                :key="index"
                :class="{ 'pr-30': horizontal, 'link cursor-pointer': link }"
                class="fancy-checkbox-container v2 pr-30"
                style="height: 40px"
            >
                <div @click="redirect(client)">
                    {{ clientNameLocal(client) }}
                </div>
                <div
                    v-if="showEmail"
                    style="margin-top: -3px"
                    class="text-very-small text-secondary"
                >
                    {{ client.profileEmail }}
                </div>
                <div
                    v-if="showMobile"
                    style="margin-top: -3px"
                    class="text-very-small text-secondary"
                >
                    {{ client.profileMobileNumber }}
                </div>
                <div
                    v-if="showAddress"
                    style="margin-top: -3px"
                    class="text-very-small text-secondary"
                >
                    {{
                        clientAddress({
                            address: client.current_address,
                            addressShort: true
                        })
                    }}
                </div>
                <label>
                    <input
                        v-model="clients"
                        :value="client.id"
                        type="checkbox"
                    />
                    <!--Removed from the input above-->
                    <!--                  :disabled="-->
                    <!--                  options.length === 1 && clients.includes(client.id)-->
                    <!--                  "-->
                    <span class="checkmark cursor-pointer"></span>
                </label>
            </div>
        </div>
        <div v-if="description" class="text-small text-primary mt-8 mb-8">
            {{ description }}
        </div>
        <div v-if="error" class="invalid-feedback-label">
            {{ error }}
        </div>
    </div>
</template>

<script>
import { clientHelpers } from '@/mixins/clientHelpers';

export default {
    name: 'ClientsCheck',
    mixins: [clientHelpers],
    props: [
        'value',
        'label',
        'error',
        'link',
        'options',
        'horizontal',
        'hiddenLabel',
        'showEmail',
        'showMobile',
        'showAddress',
        'description'
    ],
    computed: {
        clients: {
            set(value) {
                this.$emit('input', value);
            },
            get() {
                return this.value;
            }
        }
    },
    methods: {
        clientNameLocal(client) {
            if ('name' in client) return client.name;
            if ('profileNameFirst' in client) return this.clientName(client);
            return 'NO NAME';
        },
        redirect(client) {
            // OPEN IN NEW TAB
            if (this.link) {
                let routeData = this.$router.resolve({
                    name: 'clientDetail',
                    params: { id: client.id }
                });
                window.open(routeData.href, '_blank');
            }
        }
    }
};
</script>

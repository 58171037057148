<script>
import deepEqual from 'lodash/isEqual';

import {clientHelpers} from '@/mixins/clientHelpers';
import {dateFormat} from '@/mixins/dateFormat';
import {recommendationCommonHelpers} from './recommendationCommonHelpers';
import {textHelpers} from '@/mixins/textHelpers';
import {currencyFormat} from '@/mixins/currencyFormat';
import {personHelpers} from '@/mixins/personHelpers';
import {clone} from '@/mixins/clone';

export default {
  name: 'WillRecommendationEngine',
  mixins: [
    clientHelpers,
    recommendationCommonHelpers,
    dateFormat,
    textHelpers,
    currencyFormat,
    personHelpers,
    clone
  ],
  render() {
    return null;
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    willsRequired() {
      let surveys = [
        ...this.willsY,
        ...this.willsN
      ];

      // if single mode client is set, then filter surveys required to the client selected
      if (this.singleModeClient) surveys = surveys.filter((s) => s.client === this.singleModeClient);
      return surveys;
    },
    willsY() {
      return this.surveys.filter((s) => s.will_existing && (s.changes_relationship || s.changes_dependants || s.changes_appointed || s.changes_estate || s.changes_will_other));
    },
    willsN() {
      return this.surveys.filter((s) => s.will_existing === false);
    },
    youngChildren() {
      return this.willsRequired.filter((s) => s.clientData.people.filter((p) => this.isChildOf(p)).length);
    },
    olderChildren() {
      // surveys with older children
      // have children over 18
      let age = 18;
      return this.willsRequired.filter((s) => s.clientData.people.filter((p) => this.isSonOrDaughter(p) && this.isOverAge(p.dob,
          age)).length > 0);
    },
    youngChildrenPeople() {
      // people who are young children
      return this.willsRequired.reduce((acc,
                                        s) => acc.concat(s.clientData.people.filter((p) => this.isChildOf(p) && !acc.map((ac) => ac.id).
              includes(p.id))),
          []);
    },
    olderChildrenPeople() {
      // people who are old children
      let age = 18;
      return this.willsRequired.reduce((acc,
                                        s) => acc.concat(s.clientData.people.filter((p) => this.isSonOrDaughter(p) && this.isOverAge(p.dob,
          age) && !acc.map((ac) => ac.id).
              includes(p.id))),
          []);
    },
    property() {
      return this.willsRequired.filter((s) => s.clientData.assets.filter((a) => a.assetType === 'Residential Home').length);
    },
    businessContinue() {
      return this.willsRequired.filter((s) => ['Company Director'].includes(s.clientData.profilePrimaryEmployment) && s.business_continue);
    },
    businessOtherShareHolders() {
      return this.businessContinue.filter((s) => s.business_other_shareholders);
    },
    businessShareHoldersAgreement() {
      return this.businessOtherShareHolders.filter((s) => s.business_shareholders_agreement);
    },
    cashAssets() {
      // clients that have large cash assets (over this.largeCashAmount)
      return this.willsRequired.filter((s) => {
        return (s.clientData.assets.filter((a) => {
          return [
            'Bank Accounts',
            'Savings'
          ].includes(a.assetType);
        }).
            reduce((acc,
                    o) => acc + o.assetValue,
                0) > this.largeCashAmount);
      });
    },
    taxableEstates() {
      return this.willsRequired.filter((s) => s.will_distribution_estate_taxable);
    },
    areCouple() {
      if (this.transaction.clients.length !== 2) return false;
      // if people in the transaction are a couple
      let clientIds = this.transaction.clients.map((client) => client.id).
          sort((a,
                b) => a - b);
      let partnerIds = this.transaction.clients.map((client) => {
        let partner = client.people.find((person) => person.partner);
        if (partner) return partner.partner;
        return null;
      }).
          sort((a,
                b) => a - b);
      return deepEqual(clientIds,
          partnerIds);
    },
    areMarriedAndResidentialHomeNotToPartner() {
      // TODO Add civil partner?
      return this.willsRequired.filter((s) => {
        return (['married'].includes(s.clientData.profileRelationshipStatus) && s.will_distribution_residential_partner === false);
      });
    },
    areMarriedAndOtherAssetsNotToPartner() {
      // TODO Add civil partner?
      return this.willsRequired.filter((s) => ['married'].includes(s.clientData.profileRelationshipStatus) && s.will_distribution_other_assets_to_partner === false);
    },
    notMarriedAndResidentialHomeToPartner() {
      // TODO Add civil partner?
      return this.willsRequired.filter((s) => !['married'].includes(s.clientData.profileRelationshipStatus) && s.will_distribution_residential_partner);
    },
    notMarriedAndOtherAssetsToPartner() {
      // TODO Add civil partner?
      return this.willsRequired.filter((s) => !['married'].includes(s.clientData.profileRelationshipStatus) && s.will_distribution_other_assets_to_partner);
    },
    partnerNotToInheritAndJointAssets() {
      let jointAssets = this.willsRequired.reduce((acc,
                                                   ob) => {
            return acc.concat(ob.clientData.assets.filter((a) => a.clients.length > 1));
          },
          []);

      let surveys = this.surveys.filter((s) => ![
        'single',
        'widowed'
      ].includes(s.clientData.profileRelationshipStatus) && (s.will_distribution_other_assets_to_partner === false || s.will_distribution_other_assets_to_partner === false));
      if (surveys && jointAssets) return surveys;
      return [];
    }
  },
  methods: {
    async createRecommendations(singleMode = false) {
      // singleMode used for splitting joint recommendation into two single recommendations
      let result = [];

      // guards
      if (this.disabled) return result;
      // if will is not needed then return
      if (!this.willsRequired.length) return result;

      // find invoice service
      let singleWillCode = 'WILL-S';
      let mirrorWillCode = 'WILL-M';
      let singleWillProduct = this.products.services.find((option) => option.code === singleWillCode);
      let mirrorWillProduct = this.products.services.find((option) => option.code === mirrorWillCode);

      // if the clients family has a source then it is a free will?
      let freeWill = !!this.willsRequired[0].clientData.family.source_new;

      if ((this.willsRequired.length === 1 || singleMode)) {
        if (this.existingCustomRecommendationFound(singleWillProduct)) return result

        // take a copy of required storages as it will be modified for single mode
        let willsRequiredCopy = this.clone(this.willsRequired);
        // calculate pricing for display purposes
        // TODO insert prices from invoice services
        let pricing = '';
        if (freeWill) {
          pricing = this.text.pricing.single.discounted;
        } else {
          pricing = this.text.pricing.single.standard;
        }
        // create single product recommendation
        for (let i = 0; i < willsRequiredCopy.length; i++) {
          // assign single mode client the current client from copy
          if (singleMode) this.singleModeClient = willsRequiredCopy[i].client;

          result.push({
            title: 'Last Will & Testament',
            description: this.description(),
            benefits: this.benefits(),
            pricing: pricing,
            clients: [willsRequiredCopy[i].client],
            transaction: this.transaction.id,
            auto_introducer_priority: 0,
            invoice_service: singleWillProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
        // reset single model client
        if (singleMode) this.singleModeClient = null;
      } else {
        if (this.existingCustomRecommendationFound(mirrorWillProduct)) return result

        // calculate pricing for display purposes
        // TODO insert prices from invoice services
        let pricing = '';
        if (freeWill) pricing = this.text.pricing.joint.discounted;
        else pricing = this.text.pricing.joint.standard;


        // create recommendation
        result.push({
          title: 'Last Will & Testament (Mirror)',
          description: this.description(),
          benefits: this.benefits(),
          pricing: pricing,
          clients: this.willsRequired.map((survey) => survey.client),
          transaction: this.transaction.id,
          auto_introducer_priority: 0,
          invoice_service: mirrorWillProduct.id,
          link_web: this.links.web,
          link_pdf: this.links.pdf,
          link_video: this.links.video
        });
      }

      return result;
    },
    description() {
      // assemble conditions to loop through in text generation
      let bacConditions = [];

      if (this.willsN.length) {
        let x = {
          t: 'wills_no',
          s: this.willsN
        };
        bacConditions.push(x);
      }
      if (this.willsY.length) {
        let x = {
          t: 'wills_yes',
          s: this.willsY
        };
        bacConditions.push(x);
      }
      if (this.youngChildren.length) {
        let x = {
          t: 'children_young',
          s: this.youngChildren
        };
        bacConditions.push(x);
      }
      if (this.property.length) {
        let x = {
          t: 'property',
          s: this.property
        };
        bacConditions.push(x);
      }
      if (this.businessContinue.length) {
        let x = {
          t: 'business',
          s: this.businessContinue
        };
        bacConditions.push(x);
      }

      return this.backgroundText(bacConditions);
    },
    backgroundText(conditions) {
      let string = '';
      for (let i = 0; i < conditions.length; i++) {
        let c = conditions[i];
        string += this.separate(conditions,
            i);

        if (c.t === 'children_young') {
          let person = '';
          if (this.willsRequired.length === 1) {
            person = 'you have';
          } else if (c.s.length > 1) {
            person = 'you both have';
          } else {
            person = `${this.clientFirstName(c.s[0].clientData)} has`;
          }

          string += `${person} children`;
        }
        if (c.t === 'property') {
          let person = '';
          if (this.willsRequired.length === 1) {
            person = 'you have';
          } else if (c.s.length > 1) {
            person = 'you both have';
          } else {
            person = `${this.clientFirstName(c.s[0].clientData)} has`;
          }

          string += `${person} a residential property`;
        }
        if (c.t === 'business') {
          let person = '';
          if (this.willsRequired.length === 1) {
            person = 'you have';
          } else if (c.s.length > 1) {
            person = 'you both have';
          } else {
            person = `${this.clientFirstName(c.s[0].clientData)} has`;
          }

          string += `${person} a business that would continue following your passing`;
        }
        if (c.t === 'wills_no') {
          let person = '';
          if (this.willsRequired.length === 1) {
            person = 'you do not';
          } else if (c.s.length > 1) {
            person = 'neither of you';
          } else {
            person = `${this.clientFirstName(c.s[0].clientData)} does not`;
          }

          string += `dying without a will in place can make what is already a difficult time a lot more difficult for all involved. As ${person} currently have a valid will in place`;
        }
        if (c.t === 'wills_yes') {
          let start = '';
          if (this.willsRequired.length === 1) {
            start = 'you have';
          } else if (c.s.length > 1) {
            start = 'you both have';
          } else {
            start = `${this.clientFirstName(c.s[0].clientData)} has`;
          }

          let singleDraftYear = c.s[0].will_existing_year ? ` (drafted in ${c.s[0].will_existing_year})` : '';
          let drafted = c.s.length === 1 && singleDraftYear ? singleDraftYear : '';

          string += `${start} a will in place${drafted} where you are considering making changes`;
        }
      }

      // result
      let product = this.willsRequired.length > 1 ? 'a pair of Mirror Wills' : ' a new Last Will and Testament';
      return `<p>${this.capitalFirst(string) + `, we strongly recommend that you put in place ${product}. <br><br>If you instruct us to draft your Wills we will provide advise on the various options and choices involved, this includes how best to distribute your estate, how to plan for every eventuality and therefore ensure your Will is future proof and how to avoid potential challenges in the future. In addition, we will create a detailed record of your assets and liabilities for your executors including policy and provider information for any insurance policies.`}</p>`;
    },
    benefits() {
      let string = `<ul class="mt-16 list-policies"><li class="mb-12">${this.text.reasons.distribution}</li><li class="mb-12">${this.text.reasons.executors}</li><li class="mb-12">${this.text.reasons.funeral}</li><li class="mb-12">${this.text.reasons.pets}</li>`;
      string += this.guardianshipText();
      string += this.trusteeText();
      string += this.taxText();
      string += this.businessText();
      // string += this.marriedProblemsText();
      // string += this.unMarriedProblemsText();
      // Expecting a child??
      string += '</ul>';
      // result
      return string;
    },
    guardianshipText() {
      let string = '';
      if (this.youngChildren.length) {
        // have young children
        let childChildren = this.youngChildren.length > 1 ? 'children' : `a child (${this.fullName(this.youngChildrenPeople[0],
            true)})`;
        let childChildrenTwo = this.youngChildren.length > 1 ? 'children' : `child`;
        let children = '';
        if (this.willsRequired.length === 1) {
          children = `you have ${childChildren}`;
        } else if (this.youngChildren.length > 1) {
          children = 'you both have';
        } else {
          children = `${this.clientFirstName(this.youngChildren[0].clientData)} has ${childChildren}`;
        }

        let yourHisHer = this.youngChildren.length > 1 ? 'your' : this.hisHer(this.youngChildren[0].gender);
        string += `<li class="mb-12"><strong>Appoint Guardians -</strong> because ${children} under the age of 18 guardianship is crucial. Without a Will if the worst happens ${yourHisHer} ${childChildrenTwo} will not only have the difficulty of dealing with the loss of a parent or parents, but the ${childChildrenTwo} may face other problems - they may even be taken into care whilst social services appoint a suitable guardian.</li>`;
      }
      return string;
    },
    trusteeText() {
      let string = '';
      if (this.youngChildren.length) {
        // have young children
        let childChildren = this.youngChildren.length > 1 ? 'children' : `a child (${this.fullName(this.youngChildrenPeople[0],
            true)})`;
        let children = '';
        if (this.willsRequired.length === 1) {
          children = `you have ${childChildren}`;
        } else if (this.youngChildren.length > 1) {
          children = `you have ${childChildren}`;
        } else {
          children = `${this.clientFirstName(this.youngChildren[0].clientData)} has ${childChildren}`;
        }

        let youHeShe = this.youngChildren.length > 1 ? 'you' : this.heShe(this.youngChildren[0].gender);
        string += `<li class="mb-12"><strong>Appoint Trustees -</strong> because ${children} under the age of 18 ${youHeShe} should appoint Trustees to look after assets until children are old enough to inherit (when they reach the age of 25 for example).</li>`;
      }
      return string;
    },
    olderChildrenText() {
      let string = '';
      if (this.olderChildren.length) {
        // have older children
        let childChildren = this.olderChildrenPeople.length > 1 ? 'children' : `a child (${this.fullName(this.olderChildrenPeople[0],
            true)})`;

        let children = '';
        if (this.willsRequired.length === 1) {
          children = `you have ${childChildren}`;
        } else if (this.olderChildren.length > 1) {
          children = `you both have ${childChildren}`;
        } else {
          children = `${this.clientFirstName(this.olderChildren[0].clientData)} has ${childChildren}`;
        }

        let theyHeShe = this.olderChildrenPeople.length > 1 ? 'they' : this.heShe(this.olderChildrenPeople[0].gender);
        let theirHisShe = this.olderChildrenPeople.length > 1 ? 'their' : this.hisHer(this.olderChildrenPeople[0].gender);

        string += `<li class="mb-12">Since ${children} over 18 ${theyHeShe} could potentially have children of ${theirHisShe} own, you could leave your grandchild something.</li>`;
      }
      return string;
    },
    taxText() {
      let string = '';
      if (this.taxableEstates.length) {
        let person = '';
        if (this.willsRequired.length === 1 || this.taxableEstates.length > 1) {
          person = `your `;
        } else {
          person = `${this.clientFirstName(this.taxableEstates[0].clientData)}'s`;
        }

        let estateS = this.taxableEstates.length > 1 ? 'estates' : 'estate';
        let areIs = this.taxableEstates.length > 1 ? 'are' : 'is';
        string += `<li class="mb-12"><strong>Minimise IHT - </strong> having assessed your assets and liabilities it has become apparent that ${person} ${estateS} ${areIs} likely to be subject to Inheritance Tax (IHT). A carefully drafted will can often help to minimise or even eliminate any potential IHT liability.</li>`;
      }
      return string;
    },
    businessText() {
      let string = '';
      if (this.businessContinue.length && !this.businessShareHoldersAgreement.length) {
        let start = '';
        if (this.willsRequired.length === 1) {
          start = 'because you run a business';
        } else if (this.businessContinue.length > 1) {
          start = 'because you are both in business';
        } else {
          start = `because ${this.clientFirstName(this.businessContinue[0].clientData)} runs a business`;
        }

        let youHeShe = this.businessContinue.length > 1 || this.willsRequired.length === 1 ? 'you' : this.heShe(this.businessContinue[0].clientData.profileSex);
        string += `<li class="mb-12"><strong>Business Succession Planning - </strong> ${start} ${youHeShe} should consider what would happen to the business should ${youHeShe} pass away. By making a will it is possible to decide both who should manage the business and who should benefit from the business. `;
        if (this.businessOtherShareHolders.length) {
          // Other shareholders and no shareholder agreement
          string += `As there are other shareholders involved and no specific shareholder agreement this is especially important.`;
          string += '</li>';
        }
      }
      return string;
    },
    marriedProblemsText() {
      let string = '';
      if (this.areMarriedAndResidentialHomeNotToPartner.length) {
        let person = '';
        if (this.willsRequired.length === 1) {
          person = 'You have';
        } else if (this.property.length > 1) {
          person = 'You have both';
        } else {
          person = `${this.clientFirstName(this.property[0].clientData)} has`;
        }

        let youHeShe = this.areMarriedAndResidentialHomeNotToPartner.length > 1 || this.willsRequired.length === 1 ? 'you' : this.heShe(this.areMarriedAndResidentialHomeNotToPartner[0].clientData.profileSex);

        let yourHisHer = this.areMarriedAndResidentialHomeNotToPartner.length > 1 || this.willsRequired.length === 1 ? 'your' : this.hisHer(this.areMarriedAndResidentialHomeNotToPartner[0].clientData.profileSex);
        let to = '';
        if (this.willsRequired.length === 1) {
          to = 'your spouse';
        } else if (this.areMarriedAndResidentialHomeNotToPartner.length > 1) {
          to = 'each other';
        } else {
          to = this.hisHer(this.areMarriedAndResidentialHomeNotToPartner[0].clientData.profileSex) + ' spouse';
        }

        string += `${person} indicated that ${youHeShe} do not want to leave ${yourHisHer} share of the residential property to ${to}, this is a problem...  `;
      }
      if (this.areMarriedAndOtherAssetsNotToPartner.length) {
        let start = this.areMarriedAndResidentialHomeNotToPartner.length ? 'Further more, ' : '';
        let person = '';
        if (this.willsRequired.length === 1) {
          person = 'you do';
        } else if (this.property.length > 1) {
          person = 'you both do';
        } else {
          person = `${this.clientFirstName(this.property[0].clientData)} does`;
        }

        let yourHisHer = this.areMarriedAndOtherAssetsNotToPartner.length > 1 || this.willsRequired.length === 1 ? 'your' : this.hisHer(this.areMarriedAndOtherAssetsNotToPartner[0].clientData.profileSex);

        let to = '';
        if (this.willsRequired.length === 1) {
          to = 'your spouse';
        } else if (this.areMarriedAndOtherAssetsNotToPartner.length > 1) {
          to = 'each other';
        } else {
          to = this.hisHer(this.areMarriedAndOtherAssetsNotToPartner[0].clientData.profileSex) + ' spouse';
        }

        string += `${start}${person} not want to leave ${yourHisHer} other assets to ${to} initially, this will become a problem...  `;
      }
      return string;
    },
    unMarriedProblemsText() {
      let string = '';

      if (this.notMarriedAndResidentialHomeToPartner.length) {
        let person = '';
        if (this.willsRequired.length === 1) {
          person = 'You are';
        } else if (this.property.length > 1) {
          person = 'You are both';
        } else {
          person = `${this.clientFirstName(this.property[0].clientData)} is`;
        }

        let youHeShe = this.notMarriedAndResidentialHomeToPartner.length > 1 || this.willsRequired.length === 1 ? 'you' : this.heShe(this.notMarriedAndResidentialHomeToPartner[0].clientData.profileSex);

        let yourHisHer = this.notMarriedAndResidentialHomeToPartner.length > 1 || this.willsRequired.length === 1 ? 'your' : this.hisHer(this.notMarriedAndResidentialHomeToPartner[0].clientData.profileSex);

        let wantS = this.notMarriedAndResidentialHomeToPartner.length > 1 || this.willsRequired.length === 1 ? 'want' : this.heShe(this.notMarriedAndResidentialHomeToPartner[0].clientData.profileSex) + ' wants';

        let to = '';
        if (this.willsRequired.length === 1) {
          to = 'your partner';
        } else if (this.notMarriedAndResidentialHomeToPartner.length > 1) {
          to = 'each other';
        } else {
          to = this.hisHer(this.notMarriedAndResidentialHomeToPartner[0].clientData.profileSex) + ' partner';
        }

        string += `${person} not married and ${youHeShe} ${wantS} to leave ${yourHisHer} share of the residential property to ${to}, this is a problem...  `;
      }
      if (this.notMarriedAndOtherAssetsToPartner.length) {
        let start = this.notMarriedAndResidentialHomeToPartner.length ? 'Further more,' : '';
        let person = '';
        if (this.willsRequired.length === 1) {
          person = 'you';
        } else if (this.property.length > 1) {
          person = 'you both';
        } else {
          person = `${this.clientFirstName(this.property[0].clientData)}`;
        }

        let yourHisHer = this.notMarriedAndOtherAssetsToPartner.length > 1 || this.willsRequired.length === 1 ? 'your' : this.hisHer(this.notMarriedAndOtherAssetsToPartner[0].clientData.profileSex);

        let to = '';
        if (this.willsRequired.length === 1) {
          to = 'your partner';
        } else if (this.notMarriedAndOtherAssetsToPartner.length > 1) {
          to = 'each other';
        } else {
          to = this.hisHer(this.notMarriedAndOtherAssetsToPartner[0].clientData.profileSex) + ' partner';
        }

        string += `${start} ${person} want to leave ${yourHisHer} other assets to ${to} initially, being unmarried this will also become a problem.`;
      }

      if (this.partnerNotToInheritAndJointAssets.length) {
        let person = '';
        if (this.willsRequired.length === 1) {
          person = 'You';
        } else if (this.partnerNotToInheritAndJointAssets.length > 1) {
          person = 'You both';
        } else {
          person = `${this.clientFirstName(this.partnerNotToInheritAndJointAssets[0].clientData)}`;
        }

        let to = '';
        if (this.willsRequired.length === 1) {
          to = 'your partner';
        } else if (this.partnerNotToInheritAndJointAssets.length > 1) {
          to = 'each other';
        } else {
          to = this.hisHer(this.partnerNotToInheritAndJointAssets[0].clientData.profileSex) + ' partner';
        }

        let yourHisHer = this.partnerNotToInheritAndJointAssets.length > 1 || this.willsRequired.length === 1 ? 'your' : this.hisHer(this.partnerNotToInheritAndJointAssets[0].clientData.profileSex);
        string += `<p>${person} does not want to leave ${yourHisHer} assets (home or other assets) to ${to} initially, however you have joint assets and this will become a problem... </p> `;
      }
      return string;
    }
  },
  data() {
    return {
      singleModeClient: null,
      links: {
        video: null,
        pdf: null,
        web: 'https://dm-legal.co.uk/services/will-writing/'
      },
      text: {
        reasons: {
          executors: `<strong>Appoint Executors -</strong> an Executor is someone who deals with the administration of your estate, often they will be close relatives or friends. Without a will your next of kin have to apply for Grant of Letters of administration, this is a time consuming process.`,
          pets: `<strong>Provide for Pets -</strong> it is common to make provision in your Will for the family pets. You can even gift the pet together with a sum of money to help with their care.`,
          funeral: `<strong>Funeral Preferences -</strong> this could be directions around Burial or Cremation, information about whether the service should be religions or non-religions, or if you would even want a formal funeral service.`,
          distribution: `<strong>Name Beneficiaries -</strong> arguably the most important reason to make your Will is to decide who will ultimately benefit from your assets. Because nobody knows what the future holds, it may be important to stipulate different 'levels' of distribution. You can effectively have a first choice, second choice and even third choice of beneficiaries. Without a Will you have no say as to who will benefit, your assets will simply be distributed according to the law of intestacy.`
        },
        pricing: {
          joint: {
            standard: 'We charge £179 to draft a single will however where a couple instruct us jointly, a ' + "discount of £109 is applied bringing the total cost for two 'Mirror' wills down to £249 " + 'including VAT (note that wills containing discretionary trusts and protective property ' + 'trusts start from £549).',
            discounted: "We usually charge £249 for two 'Mirror' wills, however we will waive this fee " + 'leaving nothing to pay for your Wills (note that wills containing discretionary trusts and ' + 'protective property trusts start from £549).'
          },
          single: {
            standard: 'A single Last Will & Testament document costs £179 including VAT (additional fees ' + 'apply for discretionary will trusts and property trusts).',
            discounted: 'A single Last Will & Testament document usually costs £179 including VAT, however we ' + 'will waive this fee leaving nothing to pay for your Will (additional fees apply for ' + 'discretionary will trusts and property trusts).'
          }
        }
      }
    };
  }
};
</script>

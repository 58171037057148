<template>
  <div>
    <div class="text-12 position-absolute text-secondary cursor-pointer pt-12" style="left: 52px" @click="show.modal.custom = true">{{ title }}</div>

    <b-modal
      v-model="show.modal.custom"
      centered
      size="lg"
      :title="title"
      modal-class="modal-style-one modal-background-white questionnaire"
      @cancel="clear"
      @ok="savePackage"
    >
      <QuestionBase
        :errors="errors"
        :question="question"
        :valid="!!form.length"
      >
        <input-radio-multiple-basic
          v-model="form.custom_recommendation_package"
          :options="options"
          class="radio-options-wide"
        />
      </QuestionBase>

      <template #modal-footer="{ ok, cancel }">
        <div class="w-100 m-0 d-flex">
          <button class="btn btn-backwards white-border" @click="cancel">
            Clear
          </button>
          <button class="btn btn-forwards" @click="ok">Save</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { http } from "@/services";
import { clone } from "@/mixins/clone";
import { toast } from "@/mixins/toast";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputRadioMultipleBasic from "@/components/common/other/inputs/InputRadioMultipleBasic";
export default {
  name: "CustomPackage",
  mixins: [clone, toast],
  components: {
    QuestionBase,
    InputRadioMultipleBasic,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    recommendations: {
      type: Array,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  watch: {
    transaction: {
      deep: true,
      immediate: true,
      handler() {
        this.setForm();
      },
    },
  },
  mounted() {
    this.setForm();
  },
  data() {
    return {
      show: {
        modal: {
          custom: false,
        },
      },
      form: {
        custom_recommendation_package: [],
        id: null
      },
      question: {
        title: "Select products to custom package",
        subTitle: "These are the products available",
        tip: null,
      },
      errors: [],
    };
  },
  computed: {
    package() {
      return this.transaction.estate_planning.custom_recommendation_package;
    },
    options() {
      return this.recommendations.map((r) => {
        return {
          value: r.id,
          label: `${r.title} ${this.price(r)}`,
        };
      });
    },
    title() {
      if (this.package.length) return "Edit Custom Package";
      return "Create Custom Package";
    },
  },
  methods: {
    setForm() {
      this.form.custom_recommendation_package = this.clone(
        this.transaction.estate_planning.custom_recommendation_package
      );
      this.form.id = this.clone(this.transaction.estate_planning.id);
    },
    savePackage(bvEvent) {
      bvEvent.preventDefault();
      http
        .patch("estate_planning_custom_recommendation_package", this.form)
        .then(() => {
          this.$emit("refreshTransaction");
          this.show.modal.custom = false;
        })
        .catch((error) => {
          console.log(error);
          this.toast("Error saving custom package, see console for details");
        });
    },
    clear(bvEvent) {
      bvEvent.preventDefault();
      this.form.custom_recommendation_package = [];
      this.savePackage(bvEvent);
    },
    price(recommendation) {
      let price = 0;
      let service;
      if (recommendation.invoice_service) {
        service = this.products.services.find(
          (s) => s.id === recommendation.invoice_service
        );
      } else if (recommendation.invoice_package) {
        service = this.products.packages.find(
          (p) => p.id === recommendation.invoice_package
        );
      }
      if (service) price = service.gross_price;
      return `£${price}`;
    },
  },
};
</script>

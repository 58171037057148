<template>
  <b-modal
      v-model="showModal"
      aria-label=""
      body-class="modal-form"
      modal-class="modal-style-one modal-background-white questionnaire"
      no-close-on-backdrop
      scrollable
      size="xl"
      :disabled="show.loading"
      title="Deposit Payment"
      @hidden="clear"
      @ok="ok"
      @shown="shown"
  >
    <b-overlay
        :show="show.loading"
        rounded="sm"
        spinner-type="border"
        spinner-variant="primary"
    >
      <QuestionBase
          v-if="!show.success"
          :errors="errors.amount"
          :question="question.amount"
          :valid="!!form.amount">
        <InputRadioBasic
            v-if="form"
            v-model="form.amount"
            :options="amountOptions"
            class="radio-options-wide"
        />
      </QuestionBase>

      <template v-if="form.amount && form.amount !== 'later'">

        <PaymentError
            v-if="errors.non_field_errors && errors.non_field_errors.length"
            :errors="errors.non_field_errors"/>

        <CreditCardForm
            v-if="!show.success"
            v-model="form"
            :errors="errors"/>

        <PaymentSuccess
            v-if="show.success && show.orderId"
            :order-id="show.orderId"
        />
      </template>
    </b-overlay>

    <div v-show="show.authenticate" id="promptModalContent" :class="{'authenticatePopUp': show.authenticate}">
HERE FORM HERE
    </div>

    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button class="btn btn-backwards white-border" @click="cancel">
          Cancel
        </button>
        <button :disabled="show.loading" class="btn btn-forwards" @click="ok">
          {{ confirmButtonText }}
        </button>
      </div>
    </template>
  </b-modal>


</template>

<script>
import {http} from "@/services";
import {toast} from '@/mixins/toast'
import CreditCardForm from "./CreditCardForm";
import PaymentSuccess from "./PaymentSuccess";
import PaymentError from './PaymentError'
import InputRadioBasic from "@/components/common/other/inputs/InputRadioBasic";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: "DepositModal",
  components: {
    QuestionBase,
    InputRadioBasic,
    PaymentSuccess,
    CreditCardForm,
    PaymentError
  },
  mixins: [toast],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    selectedPackage: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    // required for iframe access to this files methods
    window.c_1 = this
    window.addEventListener('message', this.handleMessage)

  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleMessage)
  },

  computed: {
    showModal: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    question() {
      return {
        amount: {
          title: 'How much will you be charging?',
          subTitle: `A full payment can now be taken or a payment of £${this.depositAmount} can be taken to ` +
              `secure the discounted pricing and an appointment with a specialist. Alternatively we can handle ` +
              `invoice collection at a later date`,
          tip: null,
        }
      }
    },
    amountOptions() {
      return [
        {
          label: `Full Amount of ${this.selectedPackage ? this.selectedPackage.amount : ''}`,
          value: 'full'
        },
        {
          label: `£${this.depositAmount} deposit`,
          value: 'deposit'
        },
        {
          label: `Invoice later`,
          value: 'later'
        }
      ]
    },
    confirmButtonText () {
      if (this.show.success) return 'Finish'
      else if (['full', 'deposit'].includes(this.form.amount)) return 'Pay'
      return 'Confirm'
    }
  },
  data() {
    return {
      form: {
        transaction: null,
        accepted_recommendations: null,
        amount: null,
        // card details
        number: null,
        expiry_month: null,
        expiry_year: null,
        security_code: null,
        // address
        address_line_one: null,
        address_line_two: null,
        city: null,
        post_code: null,
        date_of_birth: null,
        // holder
        full_name: null,
        // browser details
        screen_height: screen.height,
        screen_width: screen.width,
        language: navigator.language,
        java_enabled: true,
        colour_depth: screen.colorDepth,
        time_zone: new Date().getTimezoneOffset(),
        // net pay secure id
        payment: null
      },
      errors: {
        number: [],
        expiry_month: [],
        expiry_year: [],
        security_code: [],
        full_name: [],
        accepted_recommendations: [],
        amount: [],
        address_line_one: [],
        address_line_two: [],
        city: [],
        post_code: [],
        date_of_birth: [],
      },
      netPayError: null,
      show: {
        loading: false,
        success: false,
        orderId: null,
        authenticate: false
      },
      depositAmount: 40
    };
  },
  methods: {
    shown() {
      this.form.transaction = this.transaction.id;
      this.form.accepted_recommendations = this.selectedPackage.recommendations;
      this.form.amount = null;
    },
    async ok(bvEvent = null) {
      // initiates payment
      if (bvEvent) bvEvent.preventDefault();

      // if (this.form.amount === 'later') return this.skipPayment()
      // exit payment modal id
      if (this.show.success) return (this.showModal = false);

      // start
      this.show.loading = true;
      this.clearErrors();

      // initiates payment
      return http
          .post("transaction_payment_deposit", this.form)
          .then(async (response) => {

            let data = response.data

            console.log(data)

            // skipped payment
            if (data.success && data.action === 'PAYMENT_SKIPPED') {
              this.showModal = false
              this.show.loading = false;
              this.$emit("refreshTransaction");
            }
            // if payment has succeeded
            else if (data.success && data.action === 'PAYMENT_PROCESSED') {
              this.$emit("refreshTransaction");
              this.show.loading = false;
              this.show.success = true;
              this.show.orderId = response.data.order_id;
            }

            // known success code
            else if (!data.success && data.action === 'UNKNOWN') {
              this.$emit("refreshTransaction");
              this.show.loading = false;
              this.show.success = false;
              this.handleErrors(response.data.errors)
            }
            else if (!data.success && data.action === 'AUTHENTICATE_BROWSER') {
              await this.addAuthenticateElement(data.element)

            } else {
              this.show.loading = false;
            }

          })
          .catch((error) => {
            this.show.loading = false;
            this.handleErrors(error.response.data);
          });
    },
    clear() {
      this.clearErrors()
      this.clearForm()
    },
    handleErrors(data) {
      if ('errors' in data) {
        // first data response errors
        let keys = Object.keys(data.errors);
        for (let i in keys) {
          if (keys[i] === 'non_field_errors') {
            for (let y = 0; y < data.errors[keys[i]].length; y++) {
              this.toastError(data.errors[keys[i]][y])
            }
          }
          this.errors[keys[i]] = data.errors[keys[i]];
        }
      } else {
        // server form serializer errors
        let keys = Object.keys(data);
        for (let i in keys) {
          if (keys[i] === 'non_field_errors') {
            for (let y = 0; y < data[keys[i]].length; y++) {
              this.toastError(data[keys[i]][y])
            }
          }
          this.errors[keys[i]] = data[keys[i]];
        }
      }
    },
    clearErrors() {
      // clear net pay error
      this.netPayError = null
      // clear server for serializer errors
      let keys = Object.keys(this.errors);
      for (let i in keys) {
        this.errors[keys[i]] = [];
      }
    },
    clearForm() {
      this.form.transaction = null
      this.form.accepted_recommendations = null
      this.form.amount = null;
      this.form.number = null
      this.form.expiry_month = null
      this.form.expiry_year = null
      this.form.first_name = null
      this.form.last_name = null
      this.form.title = null
      this.form.full_name = null
      this.form.security_code = null
      this.form.payment = null
    },

    async addAuthenticateElement(newElement) {
      // adds and run javascript for authenticate 3ds
      var elem = document.getElementById('promptModalContent')

      elem.innerHTML = newElement

      elem.querySelectorAll("script")
          .forEach(x => {
                var script = document.createElement("script");
                script.appendChild(document.createTextNode(x.innerText));
                elem.appendChild(script)
              }
          )
      return true
    },

    completePayment() {
      this.show.authenticatePayment = false
      this.ok()
    },
    handleMessage (message) {
      // message from iframe from server
      if (message.data.function === 'paymentSuccess') {
        this.$emit("refreshTransaction");
        this.show.loading = false
        this.show.success = true
        this.show.authenticate = false
        this.show.orderId = message.data.message.order_id
      } else if (message.data.function === 'paymentFailed') {
        this.show.loading = false
        this.show.authenticate = false
        this.handleErrors(message.data.message)
      } else if (message.data.function === 'showForm') {
        this.showForm()
      }
    },
    showForm() {
      this.show.authenticate = true
      let iFrame = document.getElementById("tdsMmethodTgtFrame")
      iFrame.style.height = "100%"
      iFrame.style.width = "100%"
      iFrame.style.visibility = "visible"
    }
  },
};
</script>

<style scoped lang="scss">

.hidden {
  visibility: hidden;
}
.shown {
  visibility: visible;
}
.authenticatePopUp {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 500px;
  height: 600px;
  background-color: white;
  overflow: hidden;
  z-index: 100;

  iframe {
    border: 0;
    height: 100%;
    visibility: visible !important;
  }

}
</style>

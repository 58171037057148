<template>
  <div :class="{ 'trans-section-locked': locked }">
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img
          :src="require('@/assets/images/padlock.svg')"
          alt="padlock"
          class="trans-section-locked-icon"
        />
      </div>
      <h4 class="trans-section-title">Recommendations</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Click the button below to generate the client recommendation. The
          client recommendation is based on the information entered and may be
          adjusted should additional information be provided at a later stage.
        </p>
        <div class="mb-30">
          <div v-if="locked">
            <p v-if="hasHandedOver" class="mb-lg-32">
              This section is currently locked as the transaction has been
              'Handed Over'.
            </p>
            <template v-else>
              <p>
                <span v-if="!previousSectionComplete"
                  >This section is currently locked as the previous section is
                  incomplete.
                </span>
                <span v-if="!hasTraining"
                  >You do not have the relevant permissions to complete this
                  section, please email enquiries@dunham-mccarthy.co.uk for more
                  information.</span
                >
              </p>
            </template>
          </div>

          <template v-if="previousSectionComplete">
            <b-button
                v-if="!transaction.invoice_transaction.length"
                :disabled="loading"
                @click="createRecommendations"
            >Generate Recommendation
            </b-button>

            <div
                v-if="transaction.estate_planning_custom_recommendation.length"
                class="position-relative d-inline"
            >
              <i
                  v-if="!transaction.invoice_transaction.length"
                  class="text-30 text-gray-400 i-Arrow-Right position-absolute"
                  style="top: -5px"
              ></i>
              <b-button
                  :class="{ 'ml-30': !transaction.invoice_transaction.length }"
                  @click="createLetter"
              >Download
              </b-button>
            </div>
          </template>

        </div>
      </b-col>
    </b-row>
    <template v-if="products.services">
      <div class="trans-section-body">
        <RecommendationList :transaction="transaction" />

        <RecommendationHandler
          ref="recommendationHandler"
          :introducer="introducer"
          :products="products"
          :transaction="transaction"
          @loading="loading=$event"
          @refreshTransaction="$emit('refreshTransaction', $event)"
        />
        <AcceptedRecommendationSummary
          v-if="paymentMade"
          :transaction="transaction"
        />
        <RecommendationPackage
          v-else
          :products="products"
          :transaction="transaction"
          @refreshTransaction="$emit('refreshTransaction', $event)"
        />
      </div>
    </template>
    <EstatePlanningPdfs
      :transaction="transaction"
      @refreshDocuments="refreshDocuments"
    />
  </div>
</template>

<script>
import { productEstatePlanningServices } from "@/mixins/productEstatePlanningServices";

import RecommendationPackage from "./RecommendationPackage";
import RecommendationHandler from "./RecommendationHandler";
import RecommendationList from "./RecommendationList";
import AcceptedRecommendationSummary from "./AcceptedRecommendationSummary";
import EstatePlanningPdfs from "../../../../../../components/common/pdfLetters/collections/EstatePlanningPdfs";

export default {
  name: "Recommendations",
  mixins: [productEstatePlanningServices],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EstatePlanningPdfs,
    AcceptedRecommendationSummary,
    RecommendationList,
    RecommendationHandler,
    RecommendationPackage,
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    createRecommendations() {
      this.$refs.recommendationHandler.createAllRecommendations();
    },
    refreshDocuments() {
      this.$emit("refreshDocuments");
    },
    createLetter() {
      this.$root.$emit("showPdfModal", {
        template: "estatePlanningRecommendedLetter",
        html: null,
        transaction: this.transaction,
        clientData: this.transaction.clients,
        to: this.transaction.clients.map((client) => client.id),
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    introducer() {
      return this.user?.is_introducer
    },
    hasTraining() {
      if (this.user)
        return this.user.is_introducer_training.includes("recommendations");
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return (
      (!this.previousSectionComplete || !this.hasTraining || this.hasHandedOver) && !(this.hasUserCreatedRecommendations)
      );
    },
    hasUserCreatedRecommendations () {
      return !!this.transaction.estate_planning_custom_recommendation.filter(recommendation => recommendation.auto_created === false).length
    },
    paymentMade() {
      // has invoices received payment
      let invoices = this.transaction.invoice_transaction;
      return (
        !!invoices.length &&
        !!invoices.reduce((acc, invoice) => [...acc, ...invoice.invoice_items], []).length
      );
    },
  },
};
</script>

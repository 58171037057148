<template>
  <b-modal
    v-model="show"
    modal-class="modal-style-one"
    title="Handover & Next Steps"
    scrollable
    size="lg"
  >
    <slot>NO FORM</slot>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 m-0 d-flex">
        <button class="btn btn-backwards white-border" @click="cancel">
          Cancel
        </button>
        <button class="btn btn-forwards" @click="ok">Confirm</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "IntroductionMoreInformationModal",
  props: {
    value: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    show: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<template>
  <div
       class="radio-options w-100">
    <label v-if="args.label" class="sub-label">{{ args.label }}</label>

    <template>
      <label v-for="(option, index) in options"
          :key="index"
          :class="{ 'active' : data.includes(option.value), 'error-border': errors.length}"
          @click="add(options[index].value)"
          class="radio"
      >
        <span>{{ option.label }}</span>
      </label>

    </template>


    <slot>

    </slot>

  </div>
</template>

<script>
export default {
  name: 'InputRadioMultipleBasic',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: false
    },
    args: {
      type: Object,
      default: () => {
        return {}
      }
    },
    errors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    add(value) {
      if (!this.data.includes(value)) {
        // add to array
        this.data.push(value)
        this.$emit('input', this.data)
        this.$emit('added', value)

      } else {
        // remove from array
        let index = this.data.findIndex(option => option === value)
        this.data.splice(index, 1)
        this.$emit('input', this.data)
        this.$emit('removed', value)
      }
    }
  }
}
</script>


<style scoped lang="scss">
.error-border {
  border: 2px solid red
}
</style>
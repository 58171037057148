<template>
  <b-row :class="marginBottom ? marginBottom : 'mb-24'">
    <b-col v-if="!topLabel && label"
           class="d-flex align-items-center justify-content-lg-end"
           lg="4">
      <label v-b-popover.hover.top.d500="{
                    customClass: 'label-detail',
                    title: popoverTitle || 'Question',
                    content: popoverContent
                }">{{ label }}
      </label>
    </b-col>
    <b-col :cols="inputWidth || 8">
      <label v-if="topLabel && label"
             v-b-popover.hover.top.d500="{
                    customClass: 'label-detail',
                    title: popoverTitle || 'Question',
                    content: popoverContent
                }"
             class="mb-2">{{ label }}
      </label>
      <v-select v-model="formValue"
                :class="{
                    'invalid-feedback-v-select': error
                }"
                :create-option="(option) => ({ code: option.toLowerCase(), label: option })"
                :options="options"
                :placeholder="placeholder"
                :taggable="taggable"
                :reduce="(option) => option.code"
                size="lg"
                @input="$emit('update')" />
      <div v-if="error"
           class="invalid-feedback-label">
        {{ error }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: 'InputVSelect',
  components: {vSelect},
  props: [
    'value',
    'error',
    'state',
    'topLabel',
    'marginBottom',
    'label',
    'taggable',
    'placeholder',
    'popoverTitle',
    'popoverContent',
    'options',
    'inputWidth'
  ],
  computed: {
    formValue: {
      set(value) {
        this.$emit('input',
            value);
      },
      get() {
        return this.value;
      }
    }
  }
};
</script>

<script>
let deepEqual = require('lodash/isEqual');
import { clientHelpers } from '@/mixins/clientHelpers';
import { dateFormat } from '@/mixins/dateFormat';
import { recommendationCommonHelpers } from './recommendationCommonHelpers';
import { textHelpers } from '@/mixins/textHelpers';

export default {
  name: 'PptRecommendationEngine',
  mixins: [
    clientHelpers,
    recommendationCommonHelpers,
    dateFormat,
    textHelpers
  ],
  render() {
    return null;
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    areCouple() {
      if (this.transaction.clients.length !== 2) return false;
      // if people in the transaction are a couple
      let clientIds = this.transaction.clients
      .map((client) => client.id)
      .sort((a, b) => a - b);
      let partnerIds = this.transaction.clients
      .map((client) => {
        let partner = client.people.find(
            (person) => person.partner
        );
        if (partner) return partner.partner;
        return null;
      })
      .sort((a, b) => a - b);
      return deepEqual(clientIds, partnerIds);
    },
    haveChildren() {
      if (this.transaction.clients.length !== 2) return false;

      // if the people in the transaction have children
      let childrenClientOne = this.transaction.clients[0].people.filter(
          (person) => this.isSonOrDaughter(person)
      );
      let childrenClientTwo = this.transaction.clients[1].people.filter(
          (person) => this.isSonOrDaughter(person)
      );
      return !!(childrenClientOne || childrenClientTwo);
    },
    childrenSurveys() {
      return this.surveys.filter((s) => {
        return s.clientData.people.filter((person) =>
            this.isSonOrDaughter(person)
        ).length;
      });
    },
    childrenPeople() {
      // list all children from clients: unique by id
      return this.surveys.reduce(
          (acc, s) =>
              acc.concat(
                  s.clientData.people.filter(
                      (p) =>
                          this.isSonOrDaughter(p) &&
                          !acc.map((ac) => ac.id).includes(p.id)
                  )
              ),
          []
      );
    },
    haveDifferentChildren() {
      if (this.transaction.clients.length !== 2) return false;

      // if the clients do not have exactly the same children
      let childrenClientOne = this.transaction.clients[0].people.filter(
          (person) => this.isSonOrDaughter(person)
      );
      let childrenClientTwo = this.transaction.clients[1].people.filter(
          (person) => this.isSonOrDaughter(person)
      );
      return !deepEqual(
          childrenClientOne
          .map((child) => child.id)
          .sort((a, b) => a - b),
          childrenClientTwo.map((child) => child.id).sort((a, b) => a - b)
      );
    },
    haveJointProperty() {
      if (this.transaction.clients.length !== 2) return false;

      // has a joint main residential property
      let clientOneProperty = this.transaction.clients[0].assets.filter(
          (asset) => asset.assetType === 'Residential Home'
      );
      let clientTwoProperty = this.transaction.clients[1].assets.filter(
          (asset) => asset.assetType === 'Residential Home'
      );
      return !!(
          clientOneProperty.length &&
          clientTwoProperty.length &&
          clientOneProperty[0].id === clientTwoProperty[0].id
      );
    },
    toPartnerInitially() {
      if (this.transaction.clients.length !== 2) return false;
      // both clients have answered to partner initially
      return (
          this.surveys.filter(
              (s) => s.will_distribution_residential_partner
          ).length === 2
      );
    },
    toChildrenAfterPartner() {
      if (this.transaction.clients.length !== 2) return false;
      // both clients have answered to partner initially
      return (
          this.surveys.filter(
              (s) => s.will_distribution_residential_children_after
          ).length === 2
      );
    },
    pptRequired() {
      return (
          this.areCouple &&
          this.haveChildren &&
          this.haveJointProperty &&
          this.toPartnerInitially &&
          this.toChildrenAfterPartner
      );
    }
  },
  methods: {
    async createRecommendations() {
      let result = [];

      // guards
      if (this.disabled) return result;
      // if lpa is not needed then return
      if (!this.pptRequired) return result;

      // find invoice service
      let pptCode = 'PPT';
      let pptProduct = this.products.packages.find(
          (option) => option.code === pptCode
      );

      let priority = 1;
      // change package priority
      if (this.haveDifferentChildren) priority = 0;

      // create recommendation
      result.push({
        title: 'Property Protection Trust (Joint)',
        description: this.description(),
        benefits: this.benefits(),
        pricing: this.text.pricing,
        clients: this.transaction.clients.map((client) => client.id),
        transaction: this.transaction.id,
        auto_introducer_priority: priority,
        invoice_package: pptProduct.id,
        link_web: this.links.web,
        link_pdf: this.links.pdf,
        link_video: this.links.video
      });

      return result;
    },
    description() {
      let string = '';

      let childChildren =
              this.childrenPeople.length > 1
                  ? 'children'
                  : this.childrenPeople[0].relationship_to_client;

      let children =
              this.childrenSurveys.length > 1
                  ? `your ${childChildren}`
                  : `${this.clientFirstName(
                  this.childrenSurveys[0].clientData
                  )}'s ${childChildren}`;

      // if single child name them
      let childrenAlternative =
              this.childrenPeople.length === 1
                  ? this.childrenPeople[0].name_first
                  : children;

      string +=
          `<p>You have said that you want the family home to pass to ${children}. You are planning on ` +
          `leaving the property to each other initially, in the hope that it will then pass to ${children} ` +
          'in due course. Unfortunately, things are not always this straightforward. For example, ' +
          'if one of you pass away and the other later begins a new relationship or re-marries, the property ' +
          'could pass “sideways” to the new partner / spouse. Also, if one of you pass away and later the ' +
          'survivor needs residential or nursing care in their later years, the property could be sold cover ' +
          'care fees.<br><br> To avoid these problems, we recommend creating a Property Protection Trust. ' +
          'This is a simple solution that means when the first partner passes away, their share passes ' +
          `into a protective trust (with the survivor and ${childrenAlternative} named as beneficiaries). When the ` +
          'survivor later passes away, the share that has been protected in the trust will pass to ' +
          `the ${children}; even if the survivor has re-married, gone into care, gone bankrupt or changed ` +
          'their Will.</p>';

      // Extra paragraph where clients have children different relationships
      if (this.haveDifferentChildren) {
        string += '<br><br>Because you have children from different relationships the trust is especially important. This is because without the trust, when one of you passes the house will pass to the surviving partner and the children of the deceased are significantly more likely to be left out when the survivor passes away.';
      }

      return string;
    },
    benefits() {
      return (
          '<ul class="mt-16 list-policies">' +
          '<li class="mb-12"><strong>Avoid Care Fees - </strong> in 2019, the average cost of a residential care home was £33,852 a year in the UK. Whilst there are limits on the cost of care, the family home can still be taken to provide funding. This type of trust will help protect your property.</li>' +
          '<li class="mb-12"><strong>Keep Control -</strong> this type of trust structure is commonly used to control assets after death. The trust can provide flexibility, and certainty as to who the property will ultimately pass to in the future.</li>' +
          '<li class="mb-12"><strong>Bankruptcy Protection -</strong> trusts can safeguard assets in the event of bankruptcy. In the current economic climate where many people are struggling with debt and the potential for bankruptcy threatens a greater proportion of society, protective trusts are very useful vehicle to protect your home.</li>' +
          `<li class="mb-12"><strong>Prevent Sideways Disinheritance -</strong> assets could pass to your partner in the hope that they are later handed down to children, however this arrangement can often be problematic, particularly where the surviving partner meets a new partner or even re-marries. </li>` +
          `<li class="mb-12"><strong>Guarantee Inheritance -</strong> so many factors affect whether or not the family home is passed down to children.  For couples who want to guarantee who ultimately benefits from their home, this trust arrangement is ideal.</li>` +
          '</ul>'
      );
    }
  },
  data() {
    return {
      links: {
        video: 'https://dunham-mccarthy.wistia.com/medias/ywdgq3zdk2',
        pdf: null,
        web:
            'https://dm-legal.co.uk/services/property-protection-trust/'
      },
      text: {
        pricing:
            'For £549 we can organise your Property Protection Trust (a discount of £150 is applied when this is organised together with your Wills reducing this cost to £399).'
      }
    };
  }
};
</script>

<template>
  <div class="pl-40 position-relative" v-if="recommendations.length">
    <div
        class="d-flex align-items-center page-num-container position-absolute"
        style="margin-left: -18px; top: 40px; z-index: 2"
    >
      <div class="page-num"></div>
      <h4>Select Recommendation</h4>
      <div>
        <CustomPackage
            :transaction="transaction"
            :recommendations="recommendations"
            :products="products"
            @refreshTransaction="$emit('refreshTransaction', $event)"
        />
      </div>
    </div>
    <div class="ep-rec-table">
      <!-- Products column-->
      <div class="rec">
        <h4 class="rec-title blank"></h4>

        <ul class="rec-features text-right">
          <li></li>
          <li
              v-for="(recommendation, index) in recommendations"
              :key="'recommendationGeneratedTitle' + index"
          >
            {{
              recommendation.title
            }}{{
              recommendation.clients.length === 1
                  ? " (" +
                  clientNameList({
                    firstName: true,
                    clients: [recommendation.clients[0]],
                    clientDetails: transaction.clients,
                  }) +
                  ")"
                  : ""
            }}
          </li>
        </ul>
      </div>

      <!-- essentials products column -->
      <div
          v-if="
          essentialRecommendations.length &&
          !customRecommendationsPackage.length
        "
          :class="{
          featured:
            essentialRecommendations.length &&
            !protectiveRecommendations.length,
        }"
          class="rec"
      >
        <h4 class="rec-title">Basics</h4>
        <ul class="rec-features">
          <li
              v-for="(recommendation, index) in recommendations"
              :key="`essentialRecommendation${index}`"
          >
            <span
                v-if="[null, 0].includes(recommendation.auto_introducer_priority)"
                class="tick-before position-relative pl-30 success"
            >Included</span
            >
          </li>
        </ul>

        <div class="rec-cost">
          <span class="rec-price">{{ essentialRecommendationsPrice }}</span>
          <span class="rec-type"></span>
        </div>
        <div class="rec-select">
          <b-button
              class="btn rec-btn"
              size="sm"
              variant="secondary"
              @click="selectPackage('essential')"
          >Select
          </b-button>
        </div>
      </div>

      <!-- protective products column -->
      <div
          v-if="
          protectiveRecommendations.length &&
          !customRecommendationsPackage.length
        "
          class="rec featured"
      >
        <h4 class="rec-title">Protective</h4>
        <ul class="rec-features">
          <li
              v-for="(recommendation, index) in recommendations"
              :key="`essentialRecommendation${index}`"
          >
            <span
                v-if="
                [null, 0, 1].includes(recommendation.auto_introducer_priority)
              "
                class="tick-before position-relative pl-30 success"
            >Included</span
            >
          </li>
        </ul>
        <div class="rec-cost">
          <span class="rec-price">{{ protectiveRecommendationsPrice }}</span>
          <span class="rec-type"></span>
        </div>
        <div class="rec-select">
          <b-button
              class="btn rec-btn"
              size="sm"
              variant="secondary"
              @click="selectPackage('protective')"
          >Select
          </b-button>
        </div>
      </div>

      <!-- comprehensive products column -->
      <div
          v-if="
          comprehensiveRecommendations.length &&
          !customRecommendationsPackage.length
        "
          class="rec"
      >
        <h4 class="rec-title">Comprehensive</h4>
        <ul class="rec-features">
          <li
              v-for="(recommendation, index) in recommendations"
              :key="`essentialRecommendation${index}`"
          >
            <span
                v-if="
                [null, 0, 1, 2].includes(
                  recommendation.auto_introducer_priority
                )
              "
                class="tick-before position-relative pl-30 success"
            >Included</span
            >
          </li>
        </ul>
        <div class="rec-cost">
          <span class="rec-price">{{ comprehensiveRecommendationsPrice }}</span>
          <span class="rec-type"></span>
        </div>
        <div class="rec-select">
          <b-button
              class="btn rec-btn"
              size="sm"
              variant="secondary"
              @click="selectPackage('comprehensive')"
          >Select
          </b-button>
        </div>
      </div>

      <!-- custom products column -->
      <div v-if="customRecommendationsPackage.length" class="rec featured">
        <h4 class="rec-title">Custom</h4>
        <ul class="rec-features">
          <li
              v-for="(recommendation, index) in recommendations"
              :key="`essentialRecommendation${index}`"
          >
            <span
                v-if="
                transaction.estate_planning.custom_recommendation_package.includes(
                  recommendation.id
                )
              "
                class="tick-before position-relative pl-30 success"
            >Included</span
            >
          </li>
        </ul>
        <div class="rec-cost">
          <span class="rec-price">{{ customPackagePrice }}</span>
          <span class="rec-type"></span>
        </div>
        <div class="rec-select">
          <b-button
              class="btn rec-btn"
              size="sm"
              variant="secondary"
              @click="selectPackage('custom')"
          >Select
          </b-button>
        </div>
      </div>
    </div>

    <DepositModal
        v-model="show.paymentModal"
        :selected-package="selectedPackage"
        :transaction="transaction"
        @refreshTransaction="$emit('refreshTransaction', $event)"
    />

  </div>
</template>

<script>
let deepEqual = require("lodash/isEqual");
import {currencyFormat} from "@/mixins/currencyFormat";
import {clientHelpers} from "@/mixins/clientHelpers";

import CustomPackage from "./CustomPackage";
import DepositModal from "../../payments/DepositModal";

export default {
  name: "RecommendationPackage",
  components: {DepositModal, CustomPackage},
  mixins: [currencyFormat, clientHelpers],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
  },
  watch: {
    recommendations: {
      immediate: true,
      deep: true,
      handler() {
        this.sortPackages();
      },
    },
  },
  data() {
    return {
      show: {
        paymentModal: false,
      },
      selectedPackage: null,
      essentialRecommendations: [],
      protectiveRecommendations: [],
      comprehensiveRecommendations: [],
    };
  },
  computed: {
    recommendations() {
      // all recommendations sorted by id then priority
      let recommendations =
          this.transaction.estate_planning_custom_recommendation;
      return recommendations
          .sort((a, b) => a.id - b.id)
          .sort(
              (a, b) => a.auto_introducer_priority - b.auto_introducer_priority
          );
    },
    customRecommendationsPackage() {
      return this.recommendations.filter((r) =>
          this.transaction.estate_planning.custom_recommendation_package.includes(
              r.id
          )
      );
    },
    essentialRecommendationsPrice() {
      // get invoice service ids from recommendation
      let servicesIds = this.essentialRecommendations.map(
          (option) => option.invoice_service
      );
      let packagesIds = this.essentialRecommendations.map(
          (option) => option.invoice_package
      );

      return this.calculatePrice(servicesIds, packagesIds)
    },
    protectiveRecommendationsPrice() {
      // get invoice service ids from recommendation
      let servicesIds = this.protectiveRecommendations.map(
          (option) => option.invoice_service
      );
      let packagesIds = this.protectiveRecommendations.map(
          (option) => option.invoice_package
      );
      return this.calculatePrice(servicesIds, packagesIds)
    },
    comprehensiveRecommendationsPrice() {
      // get invoice service ids from recommendation
      let servicesIds = this.comprehensiveRecommendations.map(
          (option) => option.invoice_service
      );
      let packagesIds = this.comprehensiveRecommendations.map(
          (option) => option.invoice_package
      );
      return this.calculatePrice(servicesIds, packagesIds)
    },
    customPackagePrice() {
      // get invoice service ids from recommendation
      let servicesIds = this.customRecommendationsPackage.map(
          (option) => option.invoice_service
      );
      let packagesIds = this.customRecommendationsPackage.map(
          (option) => option.invoice_package
      );

      // find invoice service objects
      let services = this.products.services.filter((option) =>
          servicesIds.includes(option.id)
      );
      let packages = this.products.packages.filter((option) =>
          packagesIds.includes(option.id)
      );

      // add up total price
      let servicesPrice = services.reduce((acc, ob) => acc + ob.gross_price, 0);
      let packagesPrice = packages.reduce((acc, ob) => acc + ob.gross_price, 0);
      let totalPrice = servicesPrice + packagesPrice;
      return this.currency(totalPrice);
    },
  },
  methods: {
    selectPackage(type) {
      // select package for payment model
      if (type === "essential") {
        this.selectedPackage = {
          recommendations: this.essentialRecommendations.map((rec) => rec.id),
          amount: this.essentialRecommendationsPrice
        };
      } else if (type === "protective") {
        this.selectedPackage = {
          recommendations: (this.selectedPackage = this.protectiveRecommendations.map((rec) => rec.id)),
          amount: this.protectiveRecommendationsPrice
        };
      } else if (type === "comprehensive") {
        this.selectedPackage = {
          recommendations: this.comprehensiveRecommendations.map((rec) => rec.id),
          amount: this.comprehensiveRecommendationsPrice
        };
      } else if (type === "custom") {
        this.selectedPackage = {
          recommendations: this.customRecommendationsPackage.map((rec) => rec.id),
          amount: this.customPackagePrice
        };
      }
      this.show.paymentModal = true;
    },
    async sortPackages() {
      await this.sortEssential();
      await this.sortComprehensive();
      await this.sortProtective();
      // sort again KEEP this for now
      await this.sortComprehensive();
      await this.sortProtective();
    },
    async sortEssential() {
      this.essentialRecommendations = this.recommendations
          .filter((recommendation) =>
              [null, 0].includes(recommendation.auto_introducer_priority)
          )
          .sort((a, b) => a.id - b.id);
      return true;
    },
    async sortProtective() {
      let recommendations = this.recommendations
          .filter((recommendation) =>
              [null, 0, 1].includes(recommendation.auto_introducer_priority)
          )
          .sort((a, b) => a.id - b.id);
      if (
          deepEqual(recommendations, this.essentialRecommendations) ||
          deepEqual(recommendations, this.comprehensiveRecommendations)
      ) {
        this.protectiveRecommendations = [];
      } else {
        this.protectiveRecommendations = recommendations;
      }
      return true;
    },
    async sortComprehensive() {
      let recommendations = this.recommendations
          .filter((recommendation) =>
              [null, 0, 1, 2].includes(recommendation.auto_introducer_priority)
          )
          .sort((a, b) => a.id - b.id);
      if (
          deepEqual(recommendations, this.essentialRecommendations) ||
          deepEqual(recommendations, this.protectiveRecommendations)
      ) {
        this.comprehensiveRecommendations = [];
      } else {
        this.comprehensiveRecommendations = recommendations;
      }
      return true;
    },
    calculatePrice(servicesIds, packagesIds) {
      // find invoice service objects
      let services = servicesIds.map(id => {
        return this.products.services.find(product => product.id === id)
      })

      let packages = packagesIds.map(id => {
        return this.products.packages.find(product => product.id === id)
      })

      // add up total price
      let servicesPrice = services.reduce((acc, ob) => {
            if (ob?.gross_price) return acc + ob.gross_price
            return acc
          }
          , 0);

      let packagesPrice = packages.reduce((acc, ob) => {
            if (ob?.gross_price) return acc + ob.gross_price
            return acc
          }
          , 0);

      let totalPrice = servicesPrice + packagesPrice;

      return this.currency(totalPrice);
    }
  },
};
</script>

<template>
  <div :class="{ 'trans-section-locked': locked }">
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon" />
      </div>
      <h4 class="trans-section-title">Products</h4>
    </div>

    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
         These are the products your {{ transaction.clients.length > 1 ? 'clients have' : 'client has'}} proceeded with:</p>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(policy, index) in transaction.policies"
             :key="`policy${index}`"
             md="4">
        <PolicyCard :policy="policy" />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import PolicyCard from "./cards/Policy";

export default {
  name: "Products",
  components: {
    PolicyCard,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      trainingSkill: "basic",
      handoverStep: "after_client_profile",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes(this.trainingSkill);
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return !this.hasTraining || this.hasHandedOver;
    }
  },
};
</script>

<template>
    <CreatePdfModal
        v-model="show.modal"
        :templateOptions="templateOptions"
        :formData="form"
        :showData="show"
        :selectedData="selected"
        :client-options="clientOptions"
        @refreshDocuments="$emit('refreshDocuments')"
        @hidden="clearForm"
        @showData="show = $event"
        @formData="form = $event"
        @selectedData="selected = $event"
    >
        <template
            v-slot:form
            v-if="selected.template === 'estatePlanningRecommendedLetter'"
        >
            <EstatePlanningRecommendedLetter
                v-model="form"
                :showData="show"
                :clientOptions="clientOptions"
                :selected="selected"
                :transaction="transaction"
                @showData="show = $event"
            />
        </template>

    </CreatePdfModal>
</template>

<script>
import { pdfHelpers } from '@/components/common/pdfLetters/collections/pdfHelpers';

import CreatePdfModal from '@/components/common/pdfLetters/CreatePdfModal';
import EstatePlanningRecommendedLetter from '@/components/common/pdfLetters/templates/estatePlanning/EstatePlanningRecommendedLetter';

export default {
    name: 'EstatePlanningPdfs',
    components: {
        EstatePlanningRecommendedLetter,
        CreatePdfModal
    },
    props: ['transaction'],
    mixins: [pdfHelpers],
    data() {
        return {
            templateOptions: [
                {
                    label: 'Recommended Services',
                    code: 'estatePlanningRecommendedLetter'
                }
            ]
        };
    }
};
</script>

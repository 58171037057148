<template>
  <div>
    <b-modal
      v-model="showModal"
      :footer-class="{
        'bg-gray-100 p-2 rounded-bottom': true,
        'rounded-top': !show.editor,
      }"
      :hide-header="!title"
      :size="'xl'"
      :title="title"
      body-class="p-0 bg-gray-100"
      centered
      no-close-on-backdrop
      @hidden="closeModal"
      @ok="createPdf"
    >
      <b-overlay
        v-if="show.editor"
        :show="show.loading"
        rounded="sm"
        spinner-type="border"
        spinner-variant="primary"
      >
        <b-row>
          <b-col v-if="show.templateSelect || show.clients" cols="12">
            <div class="ml-20 mt-1 mb-1 mr-20">
              <b-row>
                <b-col md="5" class="justify-content-center flex-column d-flex">
                  <input-v-select
                    v-if="show.templateSelect"
                    v-model="selected.template"
                    :input-width="12"
                    :margin-bottom="'mb-0'"
                    :options="templateOptions"
                    :top-label="true"
                    :error="errors.reference"
                    placeholder="Select Template"
                    @input="changeTemplate"
                  />
                </b-col>
                <b-col md="7">
                  <ClientsCheck
                    v-if="show.clients"
                    v-model="form.clients"
                    :horizontal="true"
                    :options="clientOptions"
                    :show-address="true"
                    class="pb-12"
                    @input="$emit('createHtml', selected.func)"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex">
              <slot name="form"></slot>
              <div
                v-show="show.ckEditor"
                :style="show.extraForm ? 'flex-basis: 70%' : 'flex-basis: 100%'"
              >
                <ckeditor v-model="form.html" :config="config"></ckeditor>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100 d-flex pl-12 pr-12 pt-12">
          <div class="flex-grow-1">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="radio-options-buttons mb-0 rounded-lg three-row"
            >
              <b-form-radio
                v-for="(option, index) in headerOptions"
                :key="index"
                v-model="form.include_header"
                :aria-describedby="ariaDescribedby"
                :state="errors.include_header ? false : null"
                :value="option.value"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img :src="src(option.value)" />
                  <span class="text-13">{{ option.text }}</span>
                </div>
              </b-form-radio>

              <div v-if="errors.include_header" class="invalid-feedback-label">
                {{ errors.include_header }}
              </div>
            </b-form-group>
            <b-form-invalid-feedback
              id="type-feedback"
              :state="errors.include_header ? false : null"
            >
              {{ errors.type }}
            </b-form-invalid-feedback>
          </div>
          <div class="d-flex align-items-center pl-48 pr-30 pb-2">
            <b-button
              size="sm"
              variant="outline-secondary ripple"
              @click="cancel"
            >
              Cancel
            </b-button>

            <b-button
              :disabled="show.loading"
              class="ml-2"
              size="sm"
              variant="secondary ripple"
              @click="ok"
            >
              Create PDF
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue";
import { toast } from "@/mixins/toast";
import { http } from "@/services";
import { clone } from "@/mixins/clone";

import InputVSelect from "@/components/common/other/form/InputVSelect";
import ClientsCheck from "@/components/common/other/form/ClientsCheck";

export default {
  name: "CreatePdfModal",
  mixins: [toast, clone],
  components: {
    ClientsCheck,
    InputVSelect,
    ckeditor: CKEditor.component,
  },
  props: [
    "value",
    "templateOptions",
    "client",
    "formData",
    "showData",
    "clientOptions",
    "selectedData",
  ],
  data() {
    return {
      errors: {
        reference: null,
        include_header: null,
      },
      title: null,
      config: {
        toolbar: [
          ["Format"],
          ["NumberedList", "BulletedList", "Outdent", "Indent", "-"],
          ["Bold", "Italic", "Underline", "Strike"],
        ],
        allowedContent: true,
        fillEmptyBlocks: false,
        removeFormatAttributes: "",
        fullPage: true,
        autoParagraph: false,
        height: "500px",
        indent: false,
        breakBeforeOpen: false,
        breakAfterOpen: false,
        removePlugins: "elementspath",
        resize_enabled: false,
        breakBeforeClose: false,
        breakAfterClose: false,
        scayt_autoStartup: false,
        disableNativeSpellChecker: false,
      },
      headerOptions: [
        // {
        //     value: 'header',
        //     text: `Header & Footer`
        // },
        {
          value: "blank_header",
          text: `Printing Layout`,
        },
        {
          value: "blank",
          text: "Text Only",
        },
      ],
    };
  },
  methods: {
    createPdf(bvEvent) {
      bvEvent.preventDefault();
      this.show.loading = true;
      this.clearErrors();

      let data = {
        id: this.form.id,
        include_header: this.form.include_header,
        html: this.form.html,
        transaction: this.form.transaction,
        clients: this.form.clients,
        reference: this.form.reference,
      };

      http
        .post(this.form.url ? this.form.url : "letter", data)
        .then(() => {
          if (this.form.callBackFunction) {
            this.form.callBackFunction();
          }
          this.toast("PDF created - see documents section");
          this.$emit("refreshDocuments");
          this.closeModal();
          this.show.loading = false;
        })
        .catch((error) => {
          this.show.loading = false;
          this.handleErrors(error.response.data);
        });
    },
    handleErrors(errors) {
      let keys = Object.keys(errors);
      for (let i in keys) {
        if (keys[i] === "include_header") {
          this.errors.include_header = errors[keys[i]][0];
        } else if (keys[i] === "reference") {
          this.errors.reference = errors[keys[i]][0];
        } else {
          this.toast(errors[keys[i]][0], keys[i], "danger");
        }
      }
    },
    clearErrors() {
      this.errors.include_header = null;
      this.errors.reference = null;
    },
    closeModal() {
      this.clearErrors();
      this.show.modal = false;
      this.$emit("hidden");
    },
    src(value) {
      if (value === "header") return require("@/assets/images/header.svg");
      if (value === "blank_header")
        return require("@/assets/images/no-header.svg");
      if (value === "blank") return require("@/assets/images/text-only.svg");
    },
    changeTemplate() {
      this.show.ckEditor = false;
      this.show.extraForm = false;
    },
  },
  computed: {
    showModal: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    show: {
      set(value) {
        this.$emit("showData", value);
      },
      get() {
        return this.showData;
      },
    },
    form: {
      set(value) {
        this.$emit("formData", value);
      },
      get() {
        return this.formData;
      },
    },
    selected: {
      set(value) {
        this.$emit("selectedData", value);
      },
      get() {
        return this.selectedData;
      },
    },
  },
};
</script>
<template>
  <div v-if="transaction">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img alt="shield"
             class="trans-background"
             src="@/assets/images/trans-banner.png">
        <div class="container">
          <h1 class="text-white">Mortgage</h1>
        </div>
      </div>
    </div>

    <Header :transaction="transaction"/>

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction/>

          <HandedOver
              :transaction="transaction"
              :currentHandoverStep="currentHandoverStep"
              :progress="clientProfileProgress"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

        </b-row>
      </b-container>
    </div>

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">
          <MortgageSurvey
              :progress="mortgageSurveyProgress"
              :transaction="transaction"
              :previousSectionComplete="true"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <ClientProfiles
              :progress="clientProfileProgress"
              :transaction="transaction"
              :completed="clientProfilesSectionComplete"
              :nextSectionComplete="false"
              :currentHandoverStep="currentHandoverStep"
              :previousSectionComplete="clientProfilesSectionComplete"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <MortgagePropertySurvey
              :progress="mortgagePropertyProgress"
              :transaction="transaction"
              :previousSectionComplete="clientProfilesSectionComplete"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <Products
              :mortgages="transaction.mortgages"
              :transaction="transaction"
              :previousSectionComplete="mortgagePropertyComplete"
          />

          <Appointments
              ref="appointments"
              :transaction="transaction"
              :showBookingButton="noAppointments"
              help-text="These are your scheduled appointments. From here you can reschedule appointment if necessary."
              appointment-type="Mortgage Consultation"
              host-role="Mortgage Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <DocumentRequestSection
              v-model="transaction"
              title="Below are details of documents requested by your mortgage advisor."
          />
          <Documents :transaction="transaction"/>

          <Contact
              class="mt-80 mb-80"
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

        </div>
      </b-container>
    </div>

    <div class="container">
      <Footer/>
    </div>


  </div>
</template>

<script>
import {progressMortgageHelpers} from "@/views/introducer/transaction/detail/mortgage/progressMortgageHelpers";

import Introduction from "./Introduction";
import Header from "./Header";
import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import ClientProfiles from "./ClientProfiles";
import MortgageSurvey from "./MortgageSurvey";
import Contact from "../../../../../components/common/contact/Contact";
import MortgagePropertySurvey from "./MortgagePropertySurvey";
import Appointments from "@/components/common/appointment";
import DocumentRequestSection from "@/components/common/documents/requests/DocumentRequestSection";
import Documents from "@/views/client/transaction/detail/mortgage/Documents";
import HandedOver from "@/views/introducer/transaction/detail/mortgage/HandedOver";
import Products from "@/views/introducer/transaction/detail/mortgage/Products";


export default {
  name: 'TransactionMortgageDetail',
  mixins: [progressMortgageHelpers],
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  components: {
    Products,
    HandedOver,
    Documents,
    DocumentRequestSection,
    Appointments,
    MortgagePropertySurvey,
    Contact,
    MortgageSurvey,
    ClientProfiles,
    Header,
    Introduction,
    Footer
  },
  methods: {
    showBookingModal() {
      this.$nextTick(() => {
        this.$refs.appointments.showBookingModal();
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    noAppointments() {
      return !this.transaction.events.length && this.hasHandedOver
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
  }
}
</script>

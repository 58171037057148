<template>
  <div :class="{ 'trans-section-locked': locked }">
    <div :class="{ completed: completed }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Invoice</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Payment needs to be taken before the clients documents can be completed.</p>
        <div v-if="locked">
          <p v-if="hasHandedOver"
             class="mb-lg-32">
            This section is currently locked as the transaction has been 'Handed Over'. </p>
          <template v-else>
            <p>
            <span v-if="!previousSectionComplete">This section is currently locked as the previous section is
              incomplete.
            </span>
              <span v-if="!hasTraining">You do not have the relevant permissions to take payments, please
              email enquiries@dunham-mccarthy.co.uk for more information.</span>
            </p>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(invoice, index) in transaction.invoice_transaction"
             :key="index"
             md="3">
        <InvoiceCard :invoice="invoice"/>
      </b-col>
    </b-row>
    <Handover
        v-if="!hasHandedOver && completed && !nextSectionComplete && previousSectionComplete"
        :step="handoverStep"
        :transaction="transaction"
        :client-progress="clientProgress"
        @refreshTransaction="$emit('refreshTransaction', $event)">
      <PostPaymentHandoverInfo/>
    </Handover>
  </div>
</template>

<script>
import InvoiceCard from "./cards/Invoice";
import Handover from "@/views/introducer/transaction/detail/estatePlanning/models/handover/Handover";
import PostPaymentHandoverInfo
  from "@/views/introducer/transaction/detail/estatePlanning/models/handover/PostPaymentHandoverInfo";

export default {
  name: "Invoices",
  components: {
    PostPaymentHandoverInfo,
    Handover,
    InvoiceCard,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    nextSectionComplete: {
      type: Boolean,
      required: true,
    },
    clientProgress: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      handoverStep: "after_deposit",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes("payment");
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return !this.previousSectionComplete || !this.hasTraining;
    },
  },
  methods: {},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.recommendations.length)?_c('div',{staticClass:"pl-40 position-relative"},[_c('div',{staticClass:"d-flex align-items-center page-num-container position-absolute",staticStyle:{"margin-left":"-18px","top":"40px","z-index":"2"}},[_c('div',{staticClass:"page-num"}),_c('h4',[_vm._v("Select Recommendation")]),_c('div',[_c('CustomPackage',{attrs:{"transaction":_vm.transaction,"recommendations":_vm.recommendations,"products":_vm.products},on:{"refreshTransaction":function($event){return _vm.$emit('refreshTransaction', $event)}}})],1)]),_c('div',{staticClass:"ep-rec-table"},[_c('div',{staticClass:"rec"},[_c('h4',{staticClass:"rec-title blank"}),_c('ul',{staticClass:"rec-features text-right"},[_c('li'),_vm._l((_vm.recommendations),function(recommendation,index){return _c('li',{key:'recommendationGeneratedTitle' + index},[_vm._v(" "+_vm._s(recommendation.title)+_vm._s(recommendation.clients.length === 1 ? " (" + _vm.clientNameList({ firstName: true, clients: [recommendation.clients[0]], clientDetails: _vm.transaction.clients, }) + ")" : "")+" ")])})],2)]),(
        _vm.essentialRecommendations.length &&
        !_vm.customRecommendationsPackage.length
      )?_c('div',{staticClass:"rec",class:{
        featured:
          _vm.essentialRecommendations.length &&
          !_vm.protectiveRecommendations.length,
      }},[_c('h4',{staticClass:"rec-title"},[_vm._v("Basics")]),_c('ul',{staticClass:"rec-features"},_vm._l((_vm.recommendations),function(recommendation,index){return _c('li',{key:`essentialRecommendation${index}`},[([null, 0].includes(recommendation.auto_introducer_priority))?_c('span',{staticClass:"tick-before position-relative pl-30 success"},[_vm._v("Included")]):_vm._e()])}),0),_c('div',{staticClass:"rec-cost"},[_c('span',{staticClass:"rec-price"},[_vm._v(_vm._s(_vm.essentialRecommendationsPrice))]),_c('span',{staticClass:"rec-type"})]),_c('div',{staticClass:"rec-select"},[_c('b-button',{staticClass:"btn rec-btn",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.selectPackage('essential')}}},[_vm._v("Select ")])],1)]):_vm._e(),(
        _vm.protectiveRecommendations.length &&
        !_vm.customRecommendationsPackage.length
      )?_c('div',{staticClass:"rec featured"},[_c('h4',{staticClass:"rec-title"},[_vm._v("Protective")]),_c('ul',{staticClass:"rec-features"},_vm._l((_vm.recommendations),function(recommendation,index){return _c('li',{key:`essentialRecommendation${index}`},[(
              [null, 0, 1].includes(recommendation.auto_introducer_priority)
            )?_c('span',{staticClass:"tick-before position-relative pl-30 success"},[_vm._v("Included")]):_vm._e()])}),0),_c('div',{staticClass:"rec-cost"},[_c('span',{staticClass:"rec-price"},[_vm._v(_vm._s(_vm.protectiveRecommendationsPrice))]),_c('span',{staticClass:"rec-type"})]),_c('div',{staticClass:"rec-select"},[_c('b-button',{staticClass:"btn rec-btn",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.selectPackage('protective')}}},[_vm._v("Select ")])],1)]):_vm._e(),(
        _vm.comprehensiveRecommendations.length &&
        !_vm.customRecommendationsPackage.length
      )?_c('div',{staticClass:"rec"},[_c('h4',{staticClass:"rec-title"},[_vm._v("Comprehensive")]),_c('ul',{staticClass:"rec-features"},_vm._l((_vm.recommendations),function(recommendation,index){return _c('li',{key:`essentialRecommendation${index}`},[(
              [null, 0, 1, 2].includes(
                recommendation.auto_introducer_priority
              )
            )?_c('span',{staticClass:"tick-before position-relative pl-30 success"},[_vm._v("Included")]):_vm._e()])}),0),_c('div',{staticClass:"rec-cost"},[_c('span',{staticClass:"rec-price"},[_vm._v(_vm._s(_vm.comprehensiveRecommendationsPrice))]),_c('span',{staticClass:"rec-type"})]),_c('div',{staticClass:"rec-select"},[_c('b-button',{staticClass:"btn rec-btn",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.selectPackage('comprehensive')}}},[_vm._v("Select ")])],1)]):_vm._e(),(_vm.customRecommendationsPackage.length)?_c('div',{staticClass:"rec featured"},[_c('h4',{staticClass:"rec-title"},[_vm._v("Custom")]),_c('ul',{staticClass:"rec-features"},_vm._l((_vm.recommendations),function(recommendation,index){return _c('li',{key:`essentialRecommendation${index}`},[(
              _vm.transaction.estate_planning.custom_recommendation_package.includes(
                recommendation.id
              )
            )?_c('span',{staticClass:"tick-before position-relative pl-30 success"},[_vm._v("Included")]):_vm._e()])}),0),_c('div',{staticClass:"rec-cost"},[_c('span',{staticClass:"rec-price"},[_vm._v(_vm._s(_vm.customPackagePrice))]),_c('span',{staticClass:"rec-type"})]),_c('div',{staticClass:"rec-select"},[_c('b-button',{staticClass:"btn rec-btn",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.selectPackage('custom')}}},[_vm._v("Select ")])],1)]):_vm._e()]),_c('DepositModal',{attrs:{"selected-package":_vm.selectedPackage,"transaction":_vm.transaction},on:{"refreshTransaction":function($event){return _vm.$emit('refreshTransaction', $event)}},model:{value:(_vm.show.paymentModal),callback:function ($$v) {_vm.$set(_vm.show, "paymentModal", $$v)},expression:"show.paymentModal"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card
      body-class="p-0"
      class="hover-down trans-card"
      @click="progress.percentage === 100 ? showLockedMessage() : goto()">
    <div class="d-flex justify-content-between align-items-center p-3">
      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img :src="require('@/assets/images/house2.svg')"
               alt="cardImage">
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5 class="">{{ clientNameList({clients: transaction.clients, nameShort: true}) }}</h5>
          <h6 class="mb-0 text-mute">Property Details</h6>
        </div>
      </div>
      <div
          class="cursor-pointer pr-2"
          @click.stop="progress.percentage === 100 ? showLockedMessage() : goto()"><i
          class="text-25 text-secondary i-Next1"></i></div>
    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar :value="progress.percentage" variant="primary"></b-progress-bar>
    </div>

    <div class="d-flex align-items-center justify-content-start pl-3 pr-1 pt-12 pb-12">
      <div :class="status[1]" class="badge text-10 p-1">{{ status[0] }} {{ progress.percentage }}%</div>
    </div>

    <div
        v-if="progress.percentage > 70 && progress.errors.length"
        class="pl-3 pr-3">
      <b-alert
          show
          variant="alert alert-card alert-danger pl-0">
        <ul class="list-square-small">
          <li v-for="(error, index) in progress.errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </b-alert>
    </div>
  </b-card>

</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'MortgagePropertySurveyCard',
  mixins: [clientHelpers],
  data() {
    return {
      lockedMessage: ''
    }
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    progress: {
      type: Object,
      required: true
    }
  },
  methods: {
    goto() {
      this.$router.push({
        name: 'ClientMortgagePropertyInstructions',
        params: {
          transactionId: this.transaction.id,
          clientId: this.transaction.clients[0].id,
          mortgagePropertyId: this.transaction.mortgage_survey.property.id
        }
      }).catch(
          error => {
            console.log(error)
          }
      )
    },
    showLockedMessage() {
      this.lockedMessage = ''
      this.$bvModal.msgBoxOk('Once completed this information can only be edited by your advisor. Use the \'Ask Question\' form below to request amendments or notify us of changes.', {
        title: 'Locked',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'secondary',
        footerClass: 'p-2',
        modalClass: 'modal-style-one',
        centered: true
      })
          .then(value => {
            this.lockedMessage = value
          })
      // .catch(err => {
      //   // An error occurred
      // })
    }
  },
  computed: {
    status() {
      // return status text and badge colour
      let string = 'Begin'
      let variant = 'badge-primary'
      if (this.progress.percentage === 100) {
        string = 'Complete'
        variant = 'badge-success'
      } else if (this.progress.percentage > 0) {
        string = 'In Progress'
        variant = 'badge-warning'
      }
      return [string, variant]
    }
  }
}


</script>

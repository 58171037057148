<template>
  <div>
    <div class="handover-point-wrapper" :class="{'handover-btn' : normalButton}" @click="show.modal.info = true">
      <div class="handover-point btn">
        <img :src="require('@/assets/images/deal.svg')" alt="handshake"/>
      </div>
    </div>
    <b-modal
        v-model="show.modal.info"
        modal-class="modal-style-one"
        scrollable
        size="lg"
        title="Handover & Next Steps"
        @hidden="hidden"
        @ok="ok"
        @shown="shown"
    >
      <slot>NO FORM</slot>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100 m-0 d-flex">
          <button class="btn btn-backwards white-border" @click="cancel">
            Cancel
          </button>
          <button class="btn btn-forwards" @click="ok">Confirm</button>
        </div>
      </template>
    </b-modal>

    <b-modal
        v-model="show.modal.confirm"
        modal-class="modal-style-one"
        scrollable
        body-class="pb-48"
        size="xl"
        title="Confirm Handover"
        @shown="setForm"
        @cancel="cancelHandover"
        @ok="handover"
    >
      <div class="questionnaire">


        <div class="mb-30 mt-8">
          Please confirm the Handover, after this point you will be unable to edit
          client data.
        </div>

        <div v-if="form.clients.length">
          <div v-for="(client, index) in form.clients" :key="index">
            <QuestionBase
                :errors="[...clientErrors[index].errors.profileMobileNumber, ...clientErrors[index].errors.profileEmail]"
                :question="clientQuestions[index]"
                :valid="true"
            >
              <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">

                <div class="question question-multipart field-50">
                  <InputStandardBasic v-model="form.clients[index].profileMobileNumber" :args="argsPhone"
                                      :errors="clientErrors[index].errors.profileMobileNumber"/>
                </div>
                <div class="question question-multipart field-50">
                  <InputStandardBasic v-model="form.clients[index].profileEmail" :args="argsEmail"
                                      :errors="clientErrors[index].errors.profileEmail"/>
                </div>
              </div>
            </QuestionBase>
          </div>
        </div>

        <QuestionBase
            :errors="[]"
            :question="{title: 'If you would like to provide any supporting information please add it below.'}"
            :valid="true"
        >

          <b-form-textarea
              rows="10"
              placeholder="e.g. The client is also interested in discussing Inheritance Tax."
              v-model="form.note"
          ></b-form-textarea>
        </QuestionBase>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="w-100 m-0 d-flex">
          <button class="btn btn-backwards white-border" @click="cancel">
            Cancel
          </button>
          <button class="btn btn-forwards" @click="ok">Confirm</button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {http} from "@/services";
import {toast} from "@/mixins/toast";
import {clientHelpers} from "@/mixins/clientHelpers";

import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: "Handover",
  components: {QuestionBase, InputStandardBasic},
  mixins: [toast, clientHelpers],
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
    normalButton: {
      type: Boolean,
      default: false,
    },
    clientProgress: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      show: {
        modal: {
          info: false,
          confirm: false,
        },
        calender: false,
      },
      form: {
        note: null,
        clients: []
      },
      argsPhone: {
        placeholder: "e.g. 01782 2343334",
        inputClass: "field-100",
        label: "Contact phone number",
      },
      argsEmail: {
        placeholder: "e.g. jill@me.com",
        inputClass: "field-100",
        label: "Contact email address",
      },
      clientQuestions: [],
      clientErrors: []
    };
  },
  methods: {
    hidden() {
      console.log("hidden");
    },
    shown() {
      console.log("shown");
    },
    ok() {
      this.show.modal.confirm = true;
    },
    cancelHandover() {
      this.form.note = null;
    },
    setForm() {
      // updates client contact details
      if (!this.clientProgress.length) return
      for (let i = 0; i < this.clientProgress.length; i++) {
        this.clientQuestions.push({
          title: `Contact Details for ${this.clientName(this.clientProgress[i].client)}`,
          subTitle: null,
          tip: null
        })
        this.form.clients.push(
            {
              id: this.clientProgress[i].client.id,
              profileMobileNumber: this.clientProgress[i].client.profileMobileNumber,
              profileEmail: this.clientProgress[i].client.profileEmail,
            }
        )
        this.clientErrors.push({
          id: this.clientProgress[i].client.id,
          errors: {profileEmail: [], profileMobileNumber: []}
        })
      }
    },
    async handover(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!(await this.saveClientContactDetails())) return

      let data = {
        id: this.transaction.id,
        handover_point: this.step,
        handover_note: this.form.note,
        handover_point_by: this.$store.getters.user.id,
        handover_point_date: new Date(),
      };
      console.log('handover this', data)

      http
          .patch("transaction_handover", data)
          .then(() => {
            this.$emit("refreshTransaction", {showBooking: true});
            this.toast("Estate planning transaction successfully handed over");
            this.show.modal.confirm = false;
            this.form.note = null;
          })
          .catch((error) => {
            let keys = Object.keys(error.response.data);
            for (let i in keys) {
              this.toast(error.response.data[keys[i]], "Error", "danger");
            }
          });
    },
    async saveClientContactDetails() {
      this.clearClientErrors()
      let errorAdded = false
      for (let i = 0; i < this.form.clients.length; i++) {
        let data = this.form.clients[i]
        await http.patch('introducer_client_save', data).then(
            response => {
              console.log(response.data)
            }
        ).catch(
            error => {
              let keys = Object.keys(error.response.data)
              for (let index in keys) {
                this.clientErrors[i].errors[keys[index]] = error.response.data[keys[index]]
                if (keys[index] === 'non_field_errors') {
                  this.toastError(error.response.data[keys[index]])
                }
              }
              errorAdded = true
            }
        )
      }
      if (errorAdded) return false
      return true
    },
    clearClientErrors() {
      for (let i = 0; i < this.clientErrors.length; i++) {
        this.clientErrors[i].errors = {
          profileEmail: [],
          profileMobileNumber: []
        }
      }
    }

  },
};
</script>

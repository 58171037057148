<template>
  <div :class="{ 'trans-section-locked': locked }">

    <Handover
        v-if="!hasHandedOver && currentHandoverStep === 'before_client_profile'"
        :step="currentHandoverStep"
        :transaction="transaction"
        :clientProgress="progress"
        @refreshTransaction="$emit('refreshTransaction', $event)">
      <PreProfilesHandoverInfo/>
    </Handover>

    <div
        :class="{ completed: completed }"
        class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"
        :style="'background: conic-gradient(#ffffff 0deg ' + (360/100 * progress.percentage) + 'deg,#f6f7fa 80deg 190deg)'">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">
        Client Profile{{ progress.length > 1 ? "s" : null }}
      </h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          Click on the profile{{
            progress.length === 2 ? "s" : null
          }}
          below to add basic information about
          {{ progress.length > 1 ? "each" : "the" }} clients
          status, address, dependants, assets and liabilities.
        </p>
      </b-col>
    </b-row>

    <b-row class="pt-20 trans-section-body">
      <b-col
          v-for="(clientProfile, index) in progress"
          :key="`clientProfile${index}`"
          md="4"
      >
        <ClientProfileCard
            :clientProfile="clientProfile"
            :transaction="transaction"
        />
      </b-col>
    </b-row>

    <Handover
        v-if="!hasHandedOver && currentHandoverStep === 'after_client_profile'"
        :step="currentHandoverStep"
        :transaction="transaction"
        :clientProgress="progress"
        @refreshTransaction="$emit('refreshTransaction', $event)">
      <PostProfilesHandoverInfo />
    </Handover>

  </div>
</template>

<script>
import ClientProfileCard from "./cards/ClientProfile";
import Handover from "@/views/introducer/transaction/detail/estatePlanning/models/handover/Handover";
import PreProfilesHandoverInfo
  from "@/views/introducer/transaction/detail/mortgage/models/handover/PreProfilesHandoverInfo";
import PostProfilesHandoverInfo
  from "@/views/introducer/transaction/detail/mortgage/models/handover/PostProfilesHandoverInfo";

export default {
  name: "ClientProfiles",
  components: {
    PreProfilesHandoverInfo,
    PostProfilesHandoverInfo,
    Handover,
    ClientProfileCard
  },
  props: {
    progress: {
      type: Array,
      required: true,
    },
    transaction: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    nextSectionComplete: {
      type: Boolean,
      required: true,
    },
    currentHandoverStep: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      trainingSkill: "basic",
      handoverStep: "before_client_profile",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes(this.trainingSkill);
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return !this.hasTraining || this.hasHandedOver;
    },
    sectionComplete() {
      return (this.progress.filter((p) => p.percentage === 100).length === this.progress.length);
    }
  },
};
</script>

<template>
  <div class="recommendation-details">
    <div role="tablist">
      <b-card v-for="(recommendation, index) in recommendations"
              :key="index"
              no-body>
        <b-card-header class="p-1 d-flex align-items-center"
                       header-tag="header"
                       role="tab">
          <b-button v-b-toggle="`accordion${index}`"
                    block
                    class="card-title mb-0 "
                    href="#"
                    variant="transparent">
            <b-badge v-if="recommendation.accepted"
                     style="min-width: 90px" class="badge-success mr-8">Accepted</b-badge>
            <b-badge v-else-if="recommendation.accepted === false"
                     style="min-width: 90px" class="badge-danger mr-8">Declined</b-badge>
            {{ recommendation.title }}
            <span v-if="recommendation.clients.length === 1"
                  class="ml-1"> ({{
                clientNameList({
                  clients: [recommendation.clients[0]],
                  clientDetails: transaction.clients
                })
              }})
            </span>
          </b-button>
          <i v-b-toggle="`accordion${index}`"
             class="i-Arrow-Down-2 pr-20 text-20 text-secondary cursor-pointer"></i>
        </b-card-header>

        <b-collapse :id="`accordion${index}`"
                    accordion="recommendation-accordion"
                    class="text-left"
                    role="tabpanel">
          <b-card-body>
            <div class="p-20">
              <b-row>
                <b-col md="12">
                  <div class="pr-20 pb-20">
                    <p v-dompurify-html="recommendation.description"></p>
                  </div>
                </b-col>

                <b-col v-if="recommendation.benefits"
                       md="6">
                  <div class="bg-gray-100 pr-40 pt-20 pb-20 pl-12 rounded-lg  position-relative">
                    <h5 class="pl-12">Benefits</h5>
                    <p v-dompurify-html="recommendation.benefits"></p>
                  </div>
                </b-col>

                <b-col class="pl-30"
                       cols="6">
                  <div v-if="recommendation.link_web"
                       class="p-24 rounded-lg bg-gray-100 mb-30 position-relative d-flex">
                    <div class="ribbon success ribbon-top-right">
                      <span>Download</span>
                    </div>
                    <div>
                      <img style="height: 45px" class="pr-20" :src="require('@/assets/images/pdf.svg')"
                           alt="padlock">
                    </div>
                    <div>
                    <h5 class="">
                      PDF Guide </h5>
                    <a class="" :href="recommendation.link_web"
                       target="_blank">{{
                        recommendation.link_web
                      }}</a>
                    </div>
                  </div>
                  <div v-if="recommendation.link_web"
                       class="p-24 rounded-lg bg-gray-100 mb-30 d-flex">
                    <div>
                      <img style="height: 45px" class="pr-20" :src="require('@/assets/images/web.svg')"
                           alt="padlock">
                    </div>
                    <div>
                    <h5 class="">
                      Website & FAQ </h5>
                    <a class="" :href="recommendation.link_web"
                       target="_blank">{{
                        recommendation.link_web
                      }}</a>
                    </div>
                  </div>
                  <div v-if="recommendation.link_web"
                       class="p-24 rounded-lg bg-gray-100 mb-30 d-flex">
                    <div>
                      <img style="height: 45px" class="pr-20" :src="require('@/assets/images/video.svg')"
                           alt="padlock">
                    </div>
                    <div>
                    <h5 class="">
                      Youtube Video </h5>
                    <a class="" :href="recommendation.link_web"
                       target="_blank">{{
                        recommendation.link_web
                      }}</a>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'RecommendationList',
  mixins: [clientHelpers],
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  computed: {
    recommendations() {
      return this.transaction.estate_planning_custom_recommendation
    }
  }
}
</script>
<template>
  <div v-if="transaction">
    <Header
        title="Estate Planning"
        sub-title="Wills, Trusts & Lasting Powers of Attorney"
        :banner-image-source="require('@/assets/images/trans-banner.png')"
        bar-info1-icon="i-Administrator"
        bar-info2-icon="i-Stopwatch"
        :barInfo1Text="clientNameList({clients: transaction.clients})"
        bar-info2-text="25 Minutes"
    />

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction />
          <HandedOver
              :transaction="transaction"
              :currentHandoverStep="currentHandoverStep"
              :progress="clientProfileProgress"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />
        </b-row>
      </b-container>
    </div>

    <StepsProgress
        :transaction="transaction"
        :client-profile-progress="clientProfileProgress"
        :estate-planning-progress="estatePlanningProgress"
        :recommendation-progress="recommendationSectionComplete"
        :client-profile-complete="clientProfilesSectionComplete"
        :estate-planning-complete="estatePlanningSectionComplete"
        :recommendation-complete="recommendationSectionComplete"
        :products-complete="productInstructionsSectionComplete"
        :invoiceSectionComplete="invoiceSectionComplete"
        :fullPaymentReceived="fullPaymentReceived"
        :depositTaken="invoiceComplete"
    />

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">
          <Appointments
              v-if="
              transaction.handover_point ===
              'before_client_profile'
            "
              ref="appointments"
              :transaction="transaction"
              :show-booking-button="noAppointments"
              help-text="Transaction handed over, here are the upcoming appointments"
              appointment-type="Estate Planning Consultation"
              host-role="Estate Planning Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <ClientProfiles
              :completed="clientProfilesSectionComplete"
              :nextSectionComplete="estatePlanningSurveysComplete"
              :progress="clientProfileProgress"
              :wills="wills"
              :estatePlanning="estatePlanningProgress"
              :transaction="transaction"
              @refreshTransaction="refreshTransaction($event)"
              @showBookingModal="showBookingModal"
          />

          <EstatePlanning
              :completed="estatePlanningSurveysComplete"
              :nextSectionComplete="invoiceSectionComplete"
              :previousSectionComplete="clientProfilesSectionComplete"
              :progress="estatePlanningProgress"
              :transaction="transaction"
              @refreshTransaction="refreshTransaction($event)"
              @showBookingModal="showBookingModal"
          />

          <Appointments
              v-if="
              transaction.handover_point ===
              'after_estate_planning_surveys'
            "
              ref="appointments"
              :transaction="transaction"
              :show-booking-button="noAppointments"
              help-text="Transaction handed over, here are the upcoming appointments"
              appointment-type="Estate Planning Consultation"
              host-role="Estate Planning Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <Recommendations
              :previousSectionComplete="estatePlanningSectionComplete"
              :transaction="transaction"
              @refreshDocuments="refreshDocuments"
              @refreshTransaction="refreshTransaction($event)"
              @showBookingModal="showBookingModal"
          />

          <Invoices
              v-if="invoiceSetupSectionComplete"
              :completed="invoiceSetupSectionComplete"
              :nextSectionComplete="productInstructionsSectionComplete"
              :previousSectionComplete="recommendationSectionComplete"
              :transaction="transaction"
              :clientProgress="clientProfileProgress"
              @refreshTransaction="refreshTransaction($event)"
              @showBookingModal="showBookingModal"
          />

          <Appointments
              v-if="transaction.handover_point === 'after_deposit'"
              ref="appointments"
              :transaction="transaction"
              :show-booking-button="noAppointments"
              help-text="Transaction handed over, here are the upcoming appointments"
              appointment-type="Estate Planning Consultation"
              host-role="Estate Planning Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <Products
              :completed="productInstructionsSectionComplete"
              :previousSectionComplete="invoiceSectionComplete"
              :transaction="transaction"
              :wills="wills"
              :lpas="lpas"
              @refreshTransaction="refreshTransaction($event)"
              @showBookingModal="showBookingModal"
          />

          <Appointments
              v-if="
              transaction.handover_point ===
              'after_product_instructions'
            "
              ref="appointments"
              :transaction="transaction"
              :show-booking-button="noAppointments"
              help-text="An appointment needs to be scheduled to take the client instructions, a further appointment
            will then be scheduled to check the documentation produced."
              appointment-type="Estate Planning Consultation"
              host-role="Estate Planning Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />


          <DocumentEstatePlanning
              :previousSectionComplete="fullPaymentReceived"
              :transaction="transaction"
              @refreshTransaction="refreshTransaction($event)"
          />

          <document-request-section
              v-model="transaction"
              title="Below are details of documents requested for this estate planning transaction."
          />

          <Contact
              id="contact"
              :transaction="transaction"
              class="mt-80"
              @refreshTransaction="refreshTransaction($event)"
          />

        </div>
      </b-container>
    </div>

    <div class="container">
      <Footer/>
    </div>
  </div>
</template>

<script>
import {progressEstatePlanningHelpers} from "./progressEstatePlanningHelpers";

import Footer from "@/components/common/questionnaires/questionnaire/Footer";
import Recommendations from "./recommendations/index";
import Products from "./Products";
import Introduction from "./Introduction";
import Header from "@/views/pages/layout/Header";
import ClientProfiles from "./ClientProfiles";
import EstatePlanning from "./EstatePlanning";
import Invoices from "./Invoices";
import Appointments from "../../../../../components/common/appointment";
import Contact from "../../../../../components/common/contact/Contact";
import DocumentEstatePlanning from "./DocumentEstatePlanning";
import HandedOver from "./HandedOver";
import StepsProgress from "./StepsProgress";
import DocumentRequestSection from "../../../../../components/common/documents/requests/DocumentRequestSection.vue";

export default {
  name: "TransactionEstatePlanningDetail",
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  mixins: [progressEstatePlanningHelpers],
  components: {
    StepsProgress,
    HandedOver,
    DocumentEstatePlanning,
    Contact,
    Appointments,
    Invoices,
    EstatePlanning,
    ClientProfiles,
    Header,
    Introduction,
    Products,
    Recommendations,
    Footer,
    DocumentRequestSection,
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    noAppointments () {
      return !this.transaction.events.length && this.hasHandedOver
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
  },
  methods: {
    showBookingModal() {
      this.$nextTick(() => {
        this.$refs.appointments.showBookingModal();
      });
    },
    refreshTransaction(event) {
      this.$emit('refreshTransaction', event)
      if ('appointments' in this.$refs) this.$refs.appointments.getEvents()
    }
  },
};
</script>

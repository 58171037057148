<template>
  <div class="border-bottom-gray-200 pt-40 pb-20 bg-white">
    <b-container>
      <b-row>

        <b-col class="mb-lg-0"
               lg="5">
          <div class="border-extra-wide-left border-secondary pl-40"><h4 class="mb-30">Progress Summary</h4>

            <ul class="list-unstyled pb-10">

              <li v-for="(progress, index) in clientProfileProgress"
                  :key="index"
                  :class="progress.percentage === 100 ?  'success tick-before' : 'cross-before'"
                  class="position-relative pl-30">
                Client Profile for {{ clientName(progress.client) }}
              </li>
            </ul>
            <ul class="list-unstyled">

              <li v-for="(progress, index) in estatePlanningProgress"
                  :key="index"
                  :class="progress.percentage === 100 ?  'success tick-before' : 'cross-before'"
                  class="position-relative pl-30">
                Estate Planing Questions for {{ clientNameFirstLast(progress.client) }}
              </li>
            </ul>

            <ul class="list-unstyled">
              <li  :class="recommendationComplete ?  'success tick-before' : 'cross-before'"
                   class="position-relative pl-30">Recommendations</li>
            </ul>

            <ul class="list-unstyled">
              <li  :class="depositTaken ?  'success tick-before' : 'cross-before'"
                   class="position-relative pl-30">Deposit Taken</li>
            </ul>

            <ul class="list-unstyled">
              <li v-for="(will, index) in transaction.wills" :key="index"
                  :class="will.status !== 'awaiting_instructions'  ?  'success tick-before' : 'cross-before'"
                   class="position-relative pl-30">Will Instructions Completed for {{ clientNameFirstLast(will.client) }}</li>
            </ul>

            <ul class="list-unstyled">
              <li v-for="(lpa, index) in transaction.lpas" :key="index"
                  :class="lpa.status !== 'awaiting_instructions'  ?  'success tick-before' : 'cross-before'"
                  class="position-relative pl-30">LPA Instructions Completed for {{ clientNameFirstLast(lpa.client) }}</li>
            </ul>

            <ul class="list-unstyled">
              <li  :class="fullPaymentReceived ?  'success tick-before' : 'cross-before'"
                   class="position-relative pl-30">Full Payment Taken & Documents Available</li>
            </ul>
          </div>
        </b-col>

        <b-col class="d-flex align-items-center justify-content-center"
               lg="7">
          <div class="trans-timeline d-flex flex-column flex-sm-row align-items-center justify-content-center flex">

            <div v-b-popover.hover.top="{title: `Client Profiles`, content: clientProfileProgressPopoverContent, html: true}"
               :class="{'current': !clientProfileComplete}"
               class="trans-timeline-item">
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                  <i v-if="!clientProfileComplete"
                     class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>

            <div v-b-popover.hover.top="{title: `Estate Planning Questions`, content: estatePlanningProgressPopoverContent, html: true}"
               :class="{'current': !estatePlanningComplete && clientProfileComplete}"
               class="trans-timeline-item">
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                    <i v-if="!estatePlanningComplete && clientProfileComplete"
                       class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>

            <div v-b-popover.hover.top="{title: `Recommendations Created`}"
               :class="{'current': !recommendationComplete && estatePlanningComplete}"
               class="trans-timeline-item">
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                    <i v-if="!recommendationComplete && estatePlanningComplete"
                       class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>

            <div v-b-popover.hover.top="{title: `Deposit Taken`}"
               :class="{'current': !depositTaken && recommendationComplete}"
               class="trans-timeline-item">
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                    <i v-if="!depositTaken && recommendationComplete"
                       class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>

            <div v-b-popover.hover.top="{title: `Product Instructions`}"
               :class="{'current': !productsComplete && depositTaken}"
               class="trans-timeline-item"
              >
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                    <i v-if="!productsComplete && depositTaken"
                       class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>


            <div v-b-popover.hover.top="{title: `Full payment and documents released`}"
               :class="{'current': fullPaymentReceived}"
               class="trans-timeline-item"
            >
              <span class="trans-timeline-item-tip"></span>
              <span class="page-num">
                    <i v-if="fullPaymentReceived"
                       class="i-Family-Sign text-white"/>
                  <span v-else ></span>
                </span>
            </div>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { clientHelpers } from "@/mixins/clientHelpers";

export default {
  name: 'StepsProgress',
  mixins: [clientHelpers],
  props: {
    clientProfileProgress: {
      type: [Array],
      required: true
    },
    estatePlanningProgress: {
      type: [Array],
      required: true
    },
    recommendationComplete: {
      type: Boolean,
      required: true
    },
    transaction: {
      type: Object,
      required: true
    },
    clientProfileComplete: {
      type: Boolean,
      required: true
    },
    estatePlanningComplete: {
      type: Boolean,
      required: true
    },
    productsComplete: {
      type: Boolean,
      required: true
    },
    invoiceSectionComplete: {
      type: Boolean,
      required: true
    },
    fullPaymentReceived: {
      type: Boolean,
      required: true
    },
    depositTaken: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    clientProfileProgressPopoverContent () {
      let result = ''
      for (let i in this.clientProfileProgress) {
        if (this.clientProfileProgress[i].errors.length) {
          result += `<h5>${this.clientNameFull(this.clientProfileProgress[i].client)}</h5>`
          result += '<ol>'
          for (let x in this.clientProfileProgress[i].errors) {
            result += `<li>${this.clientProfileProgress[i].errors[x]}</li>`
          }
          result += '</ol>'
        }
      }
      if (!result) {
        result += 'Client information questions'
      }
      return result
    },
    estatePlanningProgressPopoverContent () {
      let result = ''
      for (let i in this.estatePlanningProgress) {
        if (this.estatePlanningProgress[i].errors.length) {
          result += `<h5>${this.clientNameFull(this.estatePlanningProgress[i].client)}</h5>`
          result += '<ol>'
          for (let x in this.estatePlanningProgress[i].errors) {
            result += `<li>${this.estatePlanningProgress[i].errors[x]}</li>`
          }
          result += '</ol>'
        }
      }
      if (!result) {
        result += 'Some estate planning questions'
      }
      return result
    },
  }
}
</script>
<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'AcceptedRecommendationSummary',
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {

  }
}
</script>
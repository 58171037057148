<template>
  <div v-if="transaction">
    <div class="hero-wrapper">
      <div class="hero trans-hero">
        <img alt="shield"
             class="trans-background"
             src="@/assets/images/trans-banner.png">
        <div class="container">
          <h1 class="text-white">Insurance</h1>
          <h3 class="arrow-before text-white pl-30">
            Insurance transaction<br>
          </h3>

        </div>
      </div>
    </div>

    <Header :transaction="transaction" />

    <div class="border-bottom-gray-200 pb-40 mt-12">
      <b-container>
        <b-row>
          <Introduction />

          <HandedOver
              :transaction="transaction"
              :currentHandoverStep="currentHandoverStep"
              :progress="clientProfileProgress"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

        </b-row>
      </b-container>
    </div>

    <div class="border-gray-200 border-wide-bottom">
      <b-container>
        <div class="border-wide-left border-gray-200 pl-32 pb-8">

          <ClientProfiles
              :progress="clientProfileProgress"
              :transaction="transaction"
              :completed="clientProfilesSectionComplete"
              :nextSectionComplete="false"
              :currentHandoverStep="currentHandoverStep"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <Products
              :transaction="transaction"
          />

          <Appointments
              ref="appointments"
              :transaction="transaction"
              :show-booking-button="noAppointments"
              help-text="Here are upcoming appointments."
              appointment-type="Insurance Consultation"
              host-role="Insurance Introducer"
              @refresh="$emit('refreshTransaction', $event)"
          />

          <DocumentInsurance
              :transaction="transaction"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

          <document-request-section
              v-model="transaction"
              title="Below are details of documents requested for this insurance transaction."
          />

          <Contact
              id="contact"
              :transaction="transaction"
              class="mt-80"
              @refreshTransaction="$emit('refreshTransaction', $event)"
          />

        </div>
      </b-container>
    </div>


    <div class="container">
      <Footer />
    </div>

  </div>
</template>

<script>
import { progressInsuranceHelpers } from "@/views/introducer/transaction/detail/insurance/progressInsuranceHelpers";

import ClientProfiles from "@/views/introducer/transaction/detail/insurance/ClientProfiles";
import DocumentInsurance from "@/views/introducer/transaction/detail/insurance/DocumentInsurance";
import DocumentRequestSection from "@/components/common/documents/requests/DocumentRequestSection";
import Appointments from "@/components/common/appointment";
import Introduction from "./Introduction";
import Header from "./Header";
import Products from "./Products";
import HandedOver from "@/views/introducer/transaction/detail/insurance/HandedOver";
import Contact from "@/components/common/contact/Contact";
import Footer from "@/components/common/questionnaires/questionnaire/Footer";

export default {
  name: 'TransactionInsuranceDetail',
  props: {
    transaction: {
      type: Object,
      required: true
    }
  },
  mixins: [progressInsuranceHelpers],
  components: {
    Footer,
    Contact,
    HandedOver,
    Products,
    Header,
    Introduction,
    Appointments,
    DocumentRequestSection,
    DocumentInsurance,
    ClientProfiles

  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    noAppointments () {
      return !this.transaction.events.length && this.hasHandedOver
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
  },
  data() {
    return {
    }
  },
  methods: {
    showBookingModal() {
      this.$nextTick(() => {
        this.$refs.appointments.showBookingModal();
      });
    },
  }
}
</script>

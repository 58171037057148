<script>
import deepEqual from 'lodash/isEqual';

import {clientHelpers} from '@/mixins/clientHelpers';
import {personHelpers} from '@/mixins/personHelpers';
import {dateFormat} from '@/mixins/dateFormat';
import {recommendationCommonHelpers} from './recommendationCommonHelpers';
import {textHelpers} from '@/mixins/textHelpers';
import {currencyFormat} from '@/mixins/currencyFormat';
import {clone} from '@/mixins/clone';

export default {
  name: 'LpaRecommendationEngine',
  mixins: [
    clientHelpers,
    recommendationCommonHelpers,
    dateFormat,
    textHelpers,
    personHelpers,
    currencyFormat,
    clone
  ],
  render() {
    return null;
  },
  props: {
    transaction: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    lpasRequired() {
      let surveys = [...this.lpasHealth, ...this.lpasFinancial, ...this.lpasBoth, ...this.lpasNone];

      // if single mode client is set, then filter surveys required to the client selected
      if (this.singleModeClient)
        surveys = surveys.filter(
            (s) => s.client === this.singleModeClient
        );
      return surveys;
    },

    lpasNone() {
      return this.surveys.filter((s) => s.lpa_existing === false);
    },
    lpasHealth() {
      // have a financial lpa with no circumstance changes
      return this.surveys.filter((s) => {
            return (s.lpa_existing && s.lpa_existing_type === 'financial' && s.lpa_changes === false)
          }
      )
    },
    lpasFinancial() {
      // have a health lpa with no circumstance changes
      return this.surveys.filter((s) => {
            return (s.lpa_existing && s.lpa_existing_type === 'health' && s.lpa_changes === false)
          }
      )
    },
    lpasBoth() {
      // have a health and/or a financial lpa and circumstances have changed
      return this.surveys.filter((s) => {
            return ((s.lpa_existing && s.lpa_existing_type === 'health_financial' && s.lpa_changes) ||
                (s.lpa_existing && s.lpa_existing_type === 'health' && s.lpa_changes) ||
                (s.lpa_existing && s.lpa_existing_type === 'financial' && s.lpa_changes)
            )
          }
      )
    },
    cashAssets() {
      // clients that have large cash assets (over 5000)
      return this.lpasRequired.filter((s) => {
        return (
            s.clientData.assets
                .filter((a) => {
                  return ['Bank Accounts', 'Savings'].includes(
                      a.assetType
                  );
                })
                .reduce((acc, o) => acc + o.assetValue, 0) >
            this.largeCashAmount
        );
      });
    },
    investmentProperties() {
      // clients that have investment properties
      return this.lpasRequired.filter((s) => {
        return (
            s.clientData.assets.filter((asset) => {
              return ['Investment Property'].includes(
                  asset.assetType
              );
            }).length > 0
        );
      });
    },
    business() {
      // clients that have business's
      return this.lpasRequired.filter((s) =>
          ['Company Director'].includes(
              s.clientData.profilePrimaryEmployment
          )
      );
    },
    areCouple() {
      if (this.transaction.clients.length !== 2) return false;
      // if people in the transaction are a couple
      let clientIds = this.transaction.clients
          .map((client) => client.id)
          .sort((a, b) => a - b);
      let partnerIds = this.transaction.clients
          .map((client) => {
            let partner = client.people.find(
                (person) => person.partner
            );
            if (partner) return partner.partner;
            return null;
          })
          .sort((a, b) => a - b);
      return deepEqual(clientIds, partnerIds);
    },
    olderChildren() {
      // surveys with older children
      // have children over 18
      let age = 18;
      return this.lpasRequired.filter(
          (s) =>
              s.clientData.people.filter(
                  (p) =>
                      this.isSonOrDaughter(p) &&
                      this.isOverAge(p.dob, age)
              ).length > 0
      );
    },
    olderChildrenPeople() {
      // people who are old children unique by id
      let age = 18;
      return this.lpasRequired.reduce(
          (acc, s) =>
              acc.concat(
                  s.clientData.people.filter(
                      (p) =>
                          this.isSonOrDaughter(p) &&
                          this.isOverAge(p.dob, age) &&
                          !acc.map((ac) => ac.id).includes(p.id)
                  )
              ),
          []
      );
    },
    youngChildren() {
      // surveys with young children
      // have children under 18
      return this.lpasRequired.filter(
          (s) =>
              s.clientData.people.filter((p) => this.isChildOf(p))
                  .length > 0
      );
    },
    youngChildrenPeople() {
      // people who are young children unique by id
      return this.lpasRequired.reduce(
          (acc, s) =>
              acc.concat(
                  s.clientData.people.filter(
                      (p) =>
                          this.isChildOf(p) &&
                          !acc.map((ac) => ac.id).includes(p.id)
                  )
              ),
          []
      );
    },
    olderClients() {
      // surveys with clients over this.oldAge limit
      return this.lpasRequired.filter((s) =>
          this.isOverAge(s.clientData.profileDOB, this.oldAge)
      );
    }
  },
  methods: {
    async createRecommendations(singleMode = false) {
      // singleMode used for splitting joint recommendation into two single recommendations

      let result = [];

      // guards
      if (this.disabled) return result;
      // if lpa is not needed then return
      if (!this.lpasRequired.length) return result;

      // find invoice service
      let singleBothCode = 'LPAFH-S';
      let mirrorBothCode = 'LPAFH-M';

      let singleFinancialCode = 'LPAF-S'
      let mirrorFinancialCode = 'LPAF-M'
      let singleHealthCode = 'LPAH-S'
      let mirrorHealthCode = 'LPAH-M'

      let singleBothProduct = this.products.services.find(
          (option) => option.code === singleBothCode
      );
      let mirrorBothProduct = this.products.services.find(
          (option) => option.code === mirrorBothCode
      );
      let singleFinancialProduct = this.products.services.find(
          (option) => option.code === singleFinancialCode
      );
      let mirrorFinancialProduct = this.products.services.find(
          (option) => option.code === mirrorFinancialCode
      );
      let singleHealthProduct = this.products.services.find(
          (option) => option.code === singleHealthCode
      );
      let mirrorHealthProduct = this.products.services.find(
          (option) => option.code === mirrorHealthCode
      );

      if (this.lpasRequired.length === 1 || singleMode) {
        // single recommendations
        // take a copy of required storages as it will be modified for single mode
        let lpasRequiredCopy = this.clone(this.lpasRequired);
        let pricing = this.text.pricing.single;

        for (let i = 0; i < lpasRequiredCopy.length; i++) {
          // assign single mode client the current client from copy
          if (singleMode)
            this.singleModeClient = lpasRequiredCopy[i].client;

          // if a client has capacity issues then change priority to upmost importance
          let priority = 1;
          if (lpasRequiredCopy[i].capacity_status) priority = 0;

          if (this.lpasNone.length === 1 && !this.existingCustomRecommendationFound(singleBothProduct)) {
            // create recommendation
            result.push({
              title: 'Lasting Power of Attorney (Health and Financial)',
              description: this.description('none'),
              benefits: this.benefits(),
              pricing: pricing,
              clients: [lpasRequiredCopy[i].client],
              transaction: this.transaction.id,
              auto_introducer_priority: priority,
              invoice_service: singleBothProduct.id
            });
          }

          if (this.lpasHealth.length === 1 && !this.existingCustomRecommendationFound(singleHealthProduct)) {
            // create recommendation
            result.push({
              title: 'Lasting Power of Attorney (Health)',
              description: this.description('health'),
              benefits: this.benefits(),
              pricing: pricing,
              clients: [lpasRequiredCopy[i].client],
              transaction: this.transaction.id,
              auto_introducer_priority: priority,
              invoice_service: singleHealthProduct.id
            });
          }

          if (this.lpasFinancial.length === 1 && !this.existingCustomRecommendationFound(singleFinancialProduct)) {
            // create recommendation
            result.push({
              title: 'Lasting Power of Attorney (Financial)',
              description: this.description('financial'),
              benefits: this.benefits(),
              pricing: pricing,
              clients: [lpasRequiredCopy[i].client],
              transaction: this.transaction.id,
              auto_introducer_priority: priority,
              invoice_service: singleFinancialProduct.id
            });
          }

        }
        // reset single model client
        if (singleMode) this.singleModeClient = null;
      } else {
        // joint recommendation

        let pricing = this.text.pricing.joint;

        // if a client has capacity issues then change priority to upmost importance
        let priority = 1;
        if (this.lpasRequired.filter((s) => s.capacity_status).length)
          priority = 0;

        // both financial and health required
        if (this.lpasNone.length > 1 && !this.existingCustomRecommendationFound(mirrorBothProduct)) {
          // create recommendation
          result.push({
            title: 'Lasting Power of Attorney (Health and Financial Mirror)',
            description: this.description('none'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasNone.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: mirrorBothProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
        if (this.lpasNone.length === 1 && !this.existingCustomRecommendationFound(singleBothProduct)) {
          result.push({
            title: 'Lasting Power of Attorney (Health and Financial)',
            description: this.description('none'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasNone.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: singleBothProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }

        // both financial and health required
        if (this.lpasBoth.length > 1 && !this.existingCustomRecommendationFound(mirrorBothProduct)) {
          // create recommendation
          result.push({
            title: 'Lasting Power of Attorney (Health and Financial Mirror)',
            description: this.description('both'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasBoth.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: mirrorBothProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
        if (this.lpasBoth.length === 1 && !this.existingCustomRecommendationFound(singleBothProduct)) {
          result.push({
            title: 'Lasting Power of Attorney (Health and Financial)',
            description: this.description('both'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasBoth.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: singleBothProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }

        // health only required
        if (this.lpasHealth.length > 1 && !this.existingCustomRecommendationFound(mirrorHealthProduct)) {
          // create recommendation
          result.push({
            title: 'Lasting Power of Attorney (Health Mirror)',
            description: this.description('health'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasHealth.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: mirrorHealthProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
        if (this.lpasHealth.length === 1 && !this.existingCustomRecommendationFound(singleHealthProduct)) {
          result.push({
            title: 'Lasting Power of Attorney (Health)',
            description: this.description('health'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasHealth.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: singleHealthProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }

        // financial only required
        if (this.lpasFinancial.length > 1 && !this.existingCustomRecommendationFound(mirrorFinancialProduct)) {
          // create recommendation
          result.push({
            title: 'Lasting Power of Attorney (Financial Mirror)',
            description: this.description('financial'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasFinancial.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: mirrorFinancialProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
        if (this.lpasFinancial.length === 1 && !this.existingCustomRecommendationFound(singleFinancialProduct)) {
          result.push({
            title: 'Lasting Power of Attorney (Financial)',
            description: this.description('financial'),
            benefits: this.benefits(),
            pricing: pricing,
            clients: this.lpasFinancial.map((survey) => survey.client),
            transaction: this.transaction.id,
            auto_introducer_priority: priority,
            invoice_service: singleFinancialProduct.id,
            link_web: this.links.web,
            link_pdf: this.links.pdf,
            link_video: this.links.video
          });
        }
      }

      return result;
    },
    description(type) {
      // separate sentences for each condition
      let summary = '';
      let string = '';

      if (type === 'both' && this.lpasBoth.length) {
        // clients who need both types of lpa's
        let start = '';
        if (this.lpasRequired.length === 1) {
          start = 'Although you currently have';
        } else if (this.lpasBoth.length > 1) {
          start = 'Although you have both put';
        } else {
          start = `Although ${this.clientFirstName(
              this.lpasBoth[0].clientData
          )} has`;
        }

        let yourHisHer =
            this.lpasBoth.length > 1 || this.lpasRequired.length === 1
                ? 'your'
                : this.hisHer(this.lpasBoth[0].clientData.profileSex);
        let rec =
            this.lpasRequired.length > 1 && this.lpasBoth.length > 1
                ? ' for both of you'
                : '';
        // COULD INSERT THE TYPE OF LPA THE CLIENTS HAVE
        summary +=
            `${start} a Lasting Power of Attorney (LPA) in place ${yourHisHer} circumstances have ` +
            `changed since it was produced and I therefore recommend that new Health and Financial Lasting Powers of ` +
            `Attorney be drafted${rec}.  `;
      }

      else if (type === 'none' && this.lpasNone.length) {
        // client with no existing types of lpa's in place

        let start = '';
        if (this.lpasRequired.length === 1) {
          start = 'Because you do not';
        } else if (this.lpasNone.length > 1) {
          start = 'As neither of you currently have';
        } else {
          start = `Because ${this.clientFirstName(
              this.lpasNone[0].clientData
          )} does not`;
        }

        let youHeShe =
            this.lpasNone.length > 1 || this.lpasRequired.length === 1
                ? 'you'
                : this.heShe(this.lpasNone[0].clientData.profileSex);
        let yourHisHer =
            this.lpasNone.length > 1 || this.lpasRequired.length === 1
                ? 'your'
                : this.hisHer(this.lpasNone[0].clientData.profileSex);
        let haveHas =
            this.lpasNone.length > 1 || this.lpasRequired.length === 1
                ? 'have'
                : 'has';

        // maybe remove this this.lpasNone.length > 1 to the let rec condition below?
        let rec =
            this.lpasRequired.length > 1 && this.lpasNone.length > 1
                ? ' each of you have you Lasting Powers of Attorney produced'
                : `${youHeShe} ${haveHas} ${yourHisHer} Lasting Power of Attorney produced as soon as possible. ` +
                `Lasting Powers of Attorney are essential because if you become incapacitated (as a result of ` +
                `a stroke or an accident for example) it is too late to make a lasting power of attorney ` +
                `and instead your assets will be frozen until your loved ones apply to become your 'deputy' ` +
                `through the Court of Protection. To avoid the complications, delays and costs associated ` +
                `with the courts, it is important to make your LPA’s whilst you have capacity`;
        summary += `${start} currently have a Lasting Power of Attorney (LPA) in place we strongly recommend that ${rec}. `;
      }

      else if (type === 'health' && this.lpasHealth.length) {
        // clients who have an existing financial lpa in place need a health lpa
        let start = '';
        if (this.lpasRequired.length === 1) {
          start = 'Although you currently have';
        } else if (this.lpasHealth.length > 1) {
          start = 'Although you have both put';
        } else {
          start = `Although ${this.clientFirstName(
              this.lpasHealth[0].clientData
          )} has`;
        }

        let rec =
            this.lpasRequired.length > 1 && this.lpasHealth.length > 1
                ? ' for both of you'
                : '';
        summary +=
            `${start} a Financial Lasting Power of Attorney (LPA) in place, it is still recommended that ` +
            `a new Health Lasting Powers of Attorney be drafted${rec} to compliment your existing Financial LPA.  `;
      }

      else if (type === 'financial' && this.lpasFinancial.length) {
        // clients who have an existing health lpa in place need a financial lpa
        let start = '';
        if (this.lpasRequired.length === 1) {
          start = 'Although you currently have';
        } else if (this.lpasFinancial.length > 1) {
          start = 'Although you have both put';
        } else {
          start = `Although ${this.clientFirstName(
              this.lpasFinancial[0].clientData
          )} has`;
        }

        let rec =
            this.lpasRequired.length > 1 && this.lpasFinancial.length > 1
                ? ' for both of you'
                : '';
        summary +=
            `${start} a Health Lasting Power of Attorney (LPA) in place, it is still recommended that ` +
            `a new Financial Lasting Powers of Attorney be drafted${rec} to compliment your existing Health LPA.  `;
      }

      if (
          this.areCouple &&
          this.lpasRequired.length > 1 &&
          this.lpasNone.length > 1
      ) {
        // are any kind of a couple and neither have LPA
        let married =
            this.surveys.filter(
                (s) =>
                    s.clientData.profileRelationshipStatus === 'married'
            ) > 1
                ? ' (this is the case even tho you are married)'
                : '';
        summary +=
            `Lasting Powers of Attorney are essential because if either of you become incapacitated ` +
            `(as a result of a stroke or an accident for example) it is too late to make a lasting power of ` +
            `attorney and instead your assets will be frozen and the other partner will need to apply for ` +
            `"Deputyship" through the Court of Protection${married}. To avoid the complications, delays and ` +
            `costs associated with the courts, it is important to appoint each other in your LPA’s whilst ` +
            `you have capacity. `;
      }
      if (this.olderClients.length) {
        // are older clients
        let start = '';
        if (this.lpasRequired.length === 1) {
          start = `Additionally, given that you are over ${this.oldAge}`;
        } else if (this.olderClients.length > 1) {
          start = `Additionally, given that you are both over ${this.oldAge}`;
        } else {
          start = `Given that ${this.clientFirstName(
              this.olderClients[0].clientData
          )} is over ${this.oldAge}`;
        }

        summary +=
            `${start} the likelihood of suffering from Alzheimer's or Dementia is increased, making ` +
            `the need for Lasting Power of Attorney even more pressing. `;
      }
      if (this.investmentProperties.length || this.business.length) {
        summary += '<br><br>In your particular case ';
      }
      if (this.investmentProperties.length) {
        let start = '';
        if (this.lpasRequired.length === 1) {
          start = 'because you';
        } else if (this.investmentProperties.length > 1) {
          start = 'because you both';
        } else {
          start = `because ${this.clientFirstName(
              this.investmentProperties[0].clientData
          )} has`;
        }

        let yourHeShe =
            this.investmentProperties.length > 1 ||
            this.lpasRequired.length === 1
                ? 'you'
                : this.heShe(
                    this.investmentProperties[0].clientData.profileSex
                );
        let yourHisHer =
            this.investmentProperties.length > 1 ||
            this.lpasRequired.length === 1
                ? 'your'
                : this.hisHer(
                    this.investmentProperties[0].clientData.profileSex
                );
        let properties =
            this.investmentProperties.length > 1
                ? 'investment properties'
                : this.investmentProperties[0].clientData.assets.filter(
                    (a) => a.assetType === 'Investment Property'
                ).length > 1
                    ? 'investment properties'
                    : 'an investment property';
        summary +=
            `${start} have ${properties} it is particularly important that somebody be able to continue ` +
            `to manage ${yourHisHer} affairs (and deal with the tenant for example) should ${yourHeShe} lose capacity. `;
      }
      if (this.business.length) {
        let start = '';
        if (this.lpasRequired.length === 1) {
          start = 'I would add that because you run a business';
        } else if (this.business.length > 1) {
          start = 'I would add that because you are both in business';
        } else {
          start = `I should add that because ${this.clientFirstName(
              this.business[0].clientData
          )} runs a business`;
        }

        let youHeShe =
            this.business.length > 1 || this.lpasRequired.length === 1
                ? 'you'
                : this.heShe(this.business[0].clientData.profileSex);
        let isAre =
            this.business.length > 1 || this.lpasRequired.length === 1
                ? 'are'
                : 'is';
        summary +=
            `${start}, the importance of appointing somebody to take charge in the event that ` +
            `${youHeShe} ${isAre} incapacitated should not be underestimated. It may be even be worth ` +
            `considering having a specific LPA document drafted to deal specifically with business interests. `;
      }
      string += `<p>${summary}</p>`;
      return string;
    },
    benefits() {
      let string = '';
      string += `<ul class="mt-16 list-policies">`;
      if (this.olderChildren.length) {
        // have older children
        let childChildren =
            this.olderChildrenPeople.length > 1
                ? 'children'
                : `a child (${this.fullName(
                    this.olderChildrenPeople[0],
                    true
                )})`;

        let children = '';
        if (this.lpasRequired.length === 1) {
          children = `you have ${childChildren}`;
        } else if (this.olderChildren.length > 1) {
          children = `you have ${childChildren}`;
        } else {
          children = `${this.clientFirstName(
              this.olderChildren[0].clientData
          )} has ${childChildren}`;
        }

        let theyHeShe =
            this.olderChildrenPeople.length > 1
                ? 'they'
                : this.heShe(this.olderChildrenPeople[0].gender);
        let attorneyS =
            this.olderChildrenPeople.length > 1
                ? 'attorneys'
                : 'an attorney';
        let person = 'needed';
        if (this.areCouple) {
          person = 'both of you were unable';
        }
        string +=
            `<li class="mb-12"><strong>Keep Control -</strong>${this.text.reasons.control} With an LPA ` +
            `you can decide who is in charge, the people you name are called your attorneys. Since ${children} ` +
            `over the age of 18 ${theyHeShe} could potentially be appointed as ${attorneyS} if ${person}.</li> `;
      }
      if (this.youngChildren.length) {
        // have young children
        let childChildren =
            this.youngChildren.length > 1
                ? 'children'
                : `a child (${this.fullName(
                    this.youngChildrenPeople[0],
                    true
                )})`;

        let children = '';
        if (this.lpasRequired.length === 1) {
          children = `you have ${childChildren}`;
        } else if (this.youngChildren.length > 1) {
          children = `you have ${childChildren}`;
        } else {
          children = `${this.clientFirstName(
              this.youngChildren[0].clientData
          )} has ${childChildren}`;
        }

        let attorneyS =
            this.youngChildren.length > 1 ? 'attorneys' : 'an attorney';
        let theyHeShe =
            this.youngChildren.length > 1
                ? 'they'
                : this.heShe(this.youngChildren[0].gender);
        let becomeBecomes =
            this.youngChildren.length > 1 ? 'become' : 'becomes';
        let adultAdults =
            this.youngChildren.length > 1 ? 'adults' : 'an adult';
        let person = 'needed';
        if (this.areCouple) {
          person = 'both of you were unable';
        }
        string +=
            `<li class="mb-12"><strong>Keep Control -</strong>${this.text.reasons.control} With an LPA ` +
            `you can decide who is in charge, the people you name are called your attorneys. As ${children} ` +
            `under the age of 18 ${theyHeShe} cannot be appointed as ${attorneyS} in the short term, however ` +
            `${theyHeShe} could be added as ${attorneyS} when ${theyHeShe} ${becomeBecomes} ${adultAdults} ` +
            `if ${person}.</li> `;
      }
      return (
          string +
          `<li class="mb-12">${this.text.reasons.frozen}</li>
                    <li class="mb-12">${this.text.reasons.costs}</li>
                    <li class="mb-12">${this.text.reasons.simplify}</li>
                    </ul>`
      );
    }
  },
  data() {
    return {
      singleModeClient: null,
      links: {
        video: 'https://dunham-mccarthy.wistia.com/medias/qq7b08w30e',
        pdf: null,
        web: 'https://dm-legal.co.uk/services/lasting-powers-of-attorney/'
      },
      oldAge: 60,
      largeCashAmount: 50000,
      healthLpaOptions: [
        'health',
        'health_financial'
      ],
      financialLpaOptions: [
        'financial',
        'health_financial'
      ],
      text: {
        reasons: {
          control:
              ' without a Lasting Power of Attorney, it is the courts that will decide who may or may ' +
              'not deal with your financial affairs. Equally, with medical matters, it will be the state ' +
              'who have the final say regarding your care and treatment.',
          costs:
              '<strong>Reduce Costs & Avoid Court -</strong> without an LPA your loved ones will have to ' +
              'apply for “Deputyship”, this process is very time-consuming and costly. These costs can often ' +
              'run into thousands of pounds. People often put Lasting Powers of Attorney in place to ' +
              'protect their families from the stress and delays involved in dealing with Deputyship ' +
              'through the Court of Protection.',
          frozen:
              "<strong>Avoid Frozen Accounts -</strong> if you haven't made your LPA and you lose your " +
              'mental capacity, your assets including bank and building society accounts will be frozen ' +
              'until a Deputyship Order is granted (this will include any joint accounts).',
          simplify:
              '<strong>Simplify Administration -</strong> attorneys appointed under a Lasting Power ' +
              'of Attorney have fewer administrative responsibilities than a Deputy appointed through the ' +
              'Court of Protection.'
        },
        pricing: {
          joint:
              'For a full set of Lasting Powers of Attorney the cost is £749, this includes a Health ' +
              "Decisions LPA and a Financial Decisions LPA for each of you (if you organise your LPA's " +
              'together with your Wills the fee is reduced by £150 to £599)',
          single:
              'For a full set of Lasting Powers of Attorney the cost is £499, this includes a ' +
              "Health Decisions LPA and a Financial Decisions LPA (if you organise your LPA's together " +
              'with your Wills the fee is reduced by £150 to £349)`'
        }
      }
    };
  }
};
</script>

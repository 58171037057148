<template>
  <b-card body-class="p-0"
          class="hover-down trans-card" @click="goto">
    <div class="d-flex justify-content-between align-items-center p-3">

      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img alt="cardImage"
               :src="image">
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5 class="">{{ clientNameFull(lpa.client) }}</h5>
          <h6 class="mb-0 text-mute">Lasting Power of Attorney</h6>
        </div>
      </div>

      <div
          @click.stop="goto"
          class="cursor-pointer pr-2"><i class="text-25 text-secondary i-Next1"></i>
      </div>
    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar :value="status[2]" variant="primary"></b-progress-bar>
    </div>

    <div class="d-flex align-items-center justify-content-start pl-3 pr-1 pt-12 pb-12">
      <div class="badge text-10 p-1" :class="status[1]">{{ status[0] }}</div>

    </div>
  </b-card>

</template>

<script>
import { clientHelpers } from "@/mixins/clientHelpers";

export default {
  name: 'LpaCard',
  mixins: [clientHelpers],
  props: {
    lpa: {
      type: Object,
      required: true
    }
  },
  methods: {
    goto() {
      this.$router.push({
        name: "EstatePlanningLpaInstructions",
        params: {
          transactionId: this.lpa.transaction,
          clientId: this.lpa.client.id,
          lpaId: this.lpa.id,
        },
      }).catch(
          error => {
            console.log(error)
          }
      )
    }
  },
  computed: {
    status () {
      // return status text and badge colour
      let string = this.lpa.STATUS_CHOICES.find(
          (option) => option[0] === this.lpa.status
      )[1];
      let progress = 20;
      let variant = "badge-primary";

      if (this.lpa.status !== "awaiting_instructions") {
        variant = "badge-success";
        progress = 100;
      }
      return [string, variant, progress];
    },
    image () {
      // image for card
      if (this.lpa.client.profileSex === 'male') {
        return require('@/assets/images/man-profile.svg')
      }
      return require('@/assets/images/woman-profile.svg')
    }
  }
}


</script>

<template>
  <div>
    <div class="mb-30 mt-8">The 'Handover', is the point when an appointment is scheduled with the team at Dunham McCarthy. You can handover now or at a later stage (after you complete additional client details for example).</div>

    <h4 class="mb-30 d-inline-flex position-relative">What's Next
      <div :class="{ 'tip_open': showTip }"
           class="tip-wrapper handover-tip">
        <a class="tip-outer"
           @click="showTip = !showTip">
          <span class="tip-inner">
            <span class="tip-icon"></span>
          </span>
        </a>
        <div class="tip-text">
          <a class="tip-text-close-container">
            <div class="tip-text-close"
                 @click="showTip = !showTip"></div>
          </a>

        </div>
      </div>
    </h4>

    <b-row>
      <b-col lg="10">
        <b-card body-class="p-0"
                class="bg-primary pt-20 pl-20 w-100 mb-40"
                style="min-height: 190px; z-index: 2">
          <div class="ribbon success ribbon-top-right">
            <span>Step 1</span>
          </div>
          <img :src="require('@/assets/images/trans-banner.png')"
               alt="shield"
               class="btn-background-img"
               style="height: 80%">
          <div class="text-left d-inline-block">
            <h4 class="text-white font-weight-600">Fact Find</h4>
            <div class="text-white pr-80">
              The next step is to schedule a meeting for us to ascertain  the clients preferences and priorities.
            </div>
            <ul class="list-unstyled text-white mt-20">
              <li class="tick-before position-relative pl-30">
                Complete Fact find
              </li>
              <li class="tick-before position-relative pl-30">
                Explain Options
              </li>
              <li class="tick-before position-relative pl-30">
                Establish Needs
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
      <b-col class="position-relative"
             lg="2">
        <div class="arrow-diagonal-right"><i class="text-30 text-gray-400 i-Arrow-Right position-relative"></i></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="position-relative"
             lg="2">
        <div class="arrow-diagonal-left"><i class="text-30 text-gray-400 i-Arrow-Right position-relative"></i></div>
      </b-col>
      <b-col lg="10">
        <b-card body-class="p-0"
                class="pt-20 pl-20 w-100 mb-40"
                style="min-height: 190px; background: #533d54; z-index: 2">
          <div class="ribbon success ribbon-top-right">
            <span>Step 2</span>
          </div>
          <img :src="require('@/assets/images/trans-banner.png')"
               alt="shield"
               class="btn-background-img"
               style="height: 80%">
          <div class="text-left d-inline-block">
            <h4 class="text-white font-weight-600">Present Solution</h4>
            <div class="text-white pr-80">
              Having researched the insurance market we can then present the most suitable solution to the client.
            </div>
            <ul class="list-unstyled text-white mt-20">
              <li class="tick-before position-relative pl-30">
                Explain Recommendation
              </li>
              <li class="tick-before position-relative pl-30">
                Complete Application
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <h4 class="pl-48">All Done</h4>
  </div>
</template>

<script>
export default {
  name: 'PreProfilesHandoverInfo',
  computed: {},
  data() {
    return {
      showTip: false
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num"><img :src="require('@/assets/images/padlock.svg')"
                                 alt="padlock"
                                 class="trans-section-locked-icon">
      </div>
      <h4 class="trans-section-title">Documents</h4>
    </div>
    <b-row v-if="locked">
      <b-col lg="6">
        <p v-if="!previousSectionComplete">This section is currently locked as the previous section is incomplete. (Full
          payment not received)</p>
      </b-col>
    </b-row>
    <div>
      <DocumentSection
          ref="documents"
          :locked="locked"
          title="This section contains any documents produced and supporting paperwork such as client identification."
          :transaction="transaction">

      </DocumentSection>
    </div>
  </div>
</template>

<script>
import DocumentSection from "../../../../../components/common/documents/DocumentSection";

export default {
  name: 'DocumentEstatePlanning',
  components: {DocumentSection},
  props: {
    transaction: {
      type: Object,
      required: true
    },
    previousSectionComplete: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    hasHandedOver() {
      return !!this.transaction.handover_point
    },
    locked() {
      return !this.previousSectionComplete
    }
  },
  methods: {
    documentsFetch() {
      this.$refs.documents.documentsFetch()
    }
  }
}
</script>

<template>
  <b-card body-class="p-0"
          class="hover-down trans-card">
    <div class="d-flex justify-content-between align-items-center p-3">
      <div class="d-flex align-items-center">
        <div class="img-wrapper pr-3">
          <img :src="image"
               alt="cardImage">
        </div>
        <div class="d-flex align-items-start flex-column">
          <h5 class="">{{ clientNameFirstLast(clientProfile.client) }}</h5>
          <h6 class="mb-0 text-mute">Profile</h6>
        </div>
      </div>



        <div
            class="cursor-pointer pr-2"
            @click.stop="goto"><i class="text-25 text-secondary i-Next1"></i>
        </div>


    </div>
    <div class="bg-gray-100 border-top border-gray-200">
      <b-progress-bar :value="clientProfile.percentage" variant="primary"></b-progress-bar>
    </div>

    <div class="d-flex align-items-center justify-content-start pl-3 pr-1 pt-12 pb-12">
      <div :class="status[1]" class="badge text-10 p-1">{{ status[0] }}</div>

      <b-dropdown
          v-if="clientProfile && will && estatePlanning"
          no-caret
                  toggle-class="text-decoration-none"
                  variant="link">
        <template slot="button-content">
          <i class="text-25 text-secondary i-Link"></i>
        </template>
        <b-dropdown-item-button @click.stop="gotoFactFindEstatePlanningWill">
          Go to Fact Find, Estate Planning & Will Instructions
        </b-dropdown-item-button>
      </b-dropdown>

    </div>
    <div
        v-if="clientProfile.percentage > 75 && clientProfile.errors.length"
        class="pl-3 pr-3">
      <b-alert
          show
          variant="alert alert-card alert-danger pl-0">
        <ul class="list-square-small">
          <li v-for="(error, index) in clientProfile.errors" :key="index">{{ error }}</li>
        </ul>
      </b-alert>
    </div>


  </b-card>

</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'ClientProfileCard',
  mixins: [clientHelpers],
  props: {
    clientProfile: {
      type: Object,
      required: true
    },
    transaction: {
      type: Object,
      required: true
    },
    will: {
      type: Object,
      required: false
    },
    estatePlanning: {
      type: Object,
      required: false
    }
  },
  methods: {
    goto() {
      this.$router.push({
        name: 'EstatePlanningFactFind',
        params: {transactionId: this.transaction.id, clientId: this.clientProfile.client.id}
      }).catch(
          error => {
            console.log(error)
          }
      )
    },
    gotoFactFindEstatePlanningWill () {
      this.$router.push({
        name: 'FactFindEstatePlanningWillInstructions',
        params: {transactionId: this.transaction.id, clientId: this.clientProfile.client.id, estatePlanningSurveyId: this.estatePlanning.estatePlanningSurvey.id, willId: this.will.id}
      }).catch(
          error => {
            console.log(error)
          }
      )
    }
  },
  computed: {
    status() {
      // return status text and badge colour
      let string = 'Begin'
      let variant = 'badge-primary'
      if (this.clientProfile.percentage === 100) {
        string = 'Complete'
        variant = 'badge-success'
      } else if (this.clientProfile.percentage > 0) {
        string = 'In Progress'
        variant = 'badge-warning'
      }
      return [string, variant]
    },
    image() {
      // image for card
      if (this.clientProfile.client.profileSex === 'male') {
        return require('@/assets/images/man-profile.svg')
      }
      return require('@/assets/images/woman-profile.svg')
    }
  }
}


</script>

<template>
  <div :class="{ 'trans-section-locked': locked }">
    <div :class="{ completed: completed }"
         class="d-flex align-items-center page-num-container pt-80">
      <div class="page-num">
        <img :src="require('@/assets/images/padlock.svg')"
             alt="padlock"
             class="trans-section-locked-icon"/>
      </div>
      <h4 class="trans-section-title">Estate Planning Questions</h4>
    </div>
    <b-row class="trans-section-intro">
      <b-col lg="7">
        <p class="mb-lg-32">
          The below {{ progress.length > 1 ? "questionnaires" : "questionnaire" }} contains various questions about
          {{ progress.length > 1 ? "each" : "the" }} clients health, estate planning priorities and current
          arrangements.
        </p>
        <div v-if="locked">
          <p v-if="hasHandedOver"
             class="mb-lg-32">
            This section is currently locked as the transaction has been 'Handed Over'. </p>
          <template v-else>
            <p>
            <span v-if="!previousSectionComplete">This section is currently locked as the previous section is
              incomplete.
            </span>
              <span v-if="!hasTraining">You do not have the relevant permissions to take this information, please
              email enquiries@dunham-mccarthy.co.uk for more information.</span>
            </p>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row class="pt-20 trans-section-body">
      <b-col v-for="(survey, index) in progress"
             :key="`estateSurvey${index}`"
             md="4">
        <EstatePlanningSurveyCard :progress="survey"
                                  :transaction="transaction"/>
      </b-col>
    </b-row>
    <Handover
        v-if="!hasHandedOver && sectionComplete && !nextSectionComplete"
        :step="handoverStep"
        :transaction="transaction"
        :client-progress="progress"
        @refreshTransaction="$emit('refreshTransaction', $event)">
      <PostEPHandoverInfo/>
    </Handover>
  </div>
</template>

<script>
import EstatePlanningSurveyCard from "./cards/EstatePlanningSurvey";
import Handover from "@/views/introducer/transaction/detail/estatePlanning/models/handover/Handover";
import PostEPHandoverInfo
  from "@/views/introducer/transaction/detail/estatePlanning/models/handover/PostEPHandoverInfo";

export default {
  name: "EstatePlanning",
  components: {
    PostEPHandoverInfo,
    Handover,
    EstatePlanningSurveyCard,
  },
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    progress: {
      type: Array,
      required: true,
    },
    completed: {
      type: Boolean,
      required: true,
    },
    previousSectionComplete: {
      type: Boolean,
      required: true,
    },
    nextSectionComplete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      handoverStep: "after_estate_planning_surveys",
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    hasTraining() {
      if (this.user) return this.user.is_introducer_training.includes("estate_planning_questions");
      return false;
    },
    hasHandedOver() {
      return !!this.transaction.handover_point;
    },
    locked() {
      return (!this.previousSectionComplete || !this.hasTraining || this.hasHandedOver);
    },
    sectionComplete() {
      return (this.progress.filter((p) => p.percentage === 100).length === this.progress.length);
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.transaction)?_c('div',[_c('Header',{attrs:{"title":"Estate Planning","sub-title":"Wills, Trusts & Lasting Powers of Attorney","banner-image-source":require('@/assets/images/trans-banner.png'),"bar-info1-icon":"i-Administrator","bar-info2-icon":"i-Stopwatch","barInfo1Text":_vm.clientNameList({clients: _vm.transaction.clients}),"bar-info2-text":"25 Minutes"}}),_c('div',{staticClass:"border-bottom-gray-200 pb-40 mt-12"},[_c('b-container',[_c('b-row',[_c('Introduction'),_c('HandedOver',{attrs:{"transaction":_vm.transaction,"currentHandoverStep":_vm.currentHandoverStep,"progress":_vm.clientProfileProgress},on:{"refreshTransaction":function($event){return _vm.$emit('refreshTransaction', $event)}}})],1)],1)],1),_c('StepsProgress',{attrs:{"transaction":_vm.transaction,"client-profile-progress":_vm.clientProfileProgress,"estate-planning-progress":_vm.estatePlanningProgress,"recommendation-progress":_vm.recommendationSectionComplete,"client-profile-complete":_vm.clientProfilesSectionComplete,"estate-planning-complete":_vm.estatePlanningSectionComplete,"recommendation-complete":_vm.recommendationSectionComplete,"products-complete":_vm.productInstructionsSectionComplete,"invoiceSectionComplete":_vm.invoiceSectionComplete,"fullPaymentReceived":_vm.fullPaymentReceived,"depositTaken":_vm.invoiceComplete}}),_c('div',{staticClass:"border-gray-200 border-wide-bottom"},[_c('b-container',[_c('div',{staticClass:"border-wide-left border-gray-200 pl-32 pb-8"},[(
            _vm.transaction.handover_point ===
            'before_client_profile'
          )?_c('Appointments',{ref:"appointments",attrs:{"transaction":_vm.transaction,"show-booking-button":_vm.noAppointments,"help-text":"Transaction handed over, here are the upcoming appointments","appointment-type":"Estate Planning Consultation","host-role":"Estate Planning Introducer"},on:{"refresh":function($event){return _vm.$emit('refreshTransaction', $event)}}}):_vm._e(),_c('ClientProfiles',{attrs:{"completed":_vm.clientProfilesSectionComplete,"nextSectionComplete":_vm.estatePlanningSurveysComplete,"progress":_vm.clientProfileProgress,"wills":_vm.wills,"estatePlanning":_vm.estatePlanningProgress,"transaction":_vm.transaction},on:{"refreshTransaction":function($event){return _vm.refreshTransaction($event)},"showBookingModal":_vm.showBookingModal}}),_c('EstatePlanning',{attrs:{"completed":_vm.estatePlanningSurveysComplete,"nextSectionComplete":_vm.invoiceSectionComplete,"previousSectionComplete":_vm.clientProfilesSectionComplete,"progress":_vm.estatePlanningProgress,"transaction":_vm.transaction},on:{"refreshTransaction":function($event){return _vm.refreshTransaction($event)},"showBookingModal":_vm.showBookingModal}}),(
            _vm.transaction.handover_point ===
            'after_estate_planning_surveys'
          )?_c('Appointments',{ref:"appointments",attrs:{"transaction":_vm.transaction,"show-booking-button":_vm.noAppointments,"help-text":"Transaction handed over, here are the upcoming appointments","appointment-type":"Estate Planning Consultation","host-role":"Estate Planning Introducer"},on:{"refresh":function($event){return _vm.$emit('refreshTransaction', $event)}}}):_vm._e(),_c('Recommendations',{attrs:{"previousSectionComplete":_vm.estatePlanningSectionComplete,"transaction":_vm.transaction},on:{"refreshDocuments":_vm.refreshDocuments,"refreshTransaction":function($event){return _vm.refreshTransaction($event)},"showBookingModal":_vm.showBookingModal}}),(_vm.invoiceSetupSectionComplete)?_c('Invoices',{attrs:{"completed":_vm.invoiceSetupSectionComplete,"nextSectionComplete":_vm.productInstructionsSectionComplete,"previousSectionComplete":_vm.recommendationSectionComplete,"transaction":_vm.transaction,"clientProgress":_vm.clientProfileProgress},on:{"refreshTransaction":function($event){return _vm.refreshTransaction($event)},"showBookingModal":_vm.showBookingModal}}):_vm._e(),(_vm.transaction.handover_point === 'after_deposit')?_c('Appointments',{ref:"appointments",attrs:{"transaction":_vm.transaction,"show-booking-button":_vm.noAppointments,"help-text":"Transaction handed over, here are the upcoming appointments","appointment-type":"Estate Planning Consultation","host-role":"Estate Planning Introducer"},on:{"refresh":function($event){return _vm.$emit('refreshTransaction', $event)}}}):_vm._e(),_c('Products',{attrs:{"completed":_vm.productInstructionsSectionComplete,"previousSectionComplete":_vm.invoiceSectionComplete,"transaction":_vm.transaction,"wills":_vm.wills,"lpas":_vm.lpas},on:{"refreshTransaction":function($event){return _vm.refreshTransaction($event)},"showBookingModal":_vm.showBookingModal}}),(
            _vm.transaction.handover_point ===
            'after_product_instructions'
          )?_c('Appointments',{ref:"appointments",attrs:{"transaction":_vm.transaction,"show-booking-button":_vm.noAppointments,"help-text":"An appointment needs to be scheduled to take the client instructions, a further appointment\n          will then be scheduled to check the documentation produced.","appointment-type":"Estate Planning Consultation","host-role":"Estate Planning Introducer"},on:{"refresh":function($event){return _vm.$emit('refreshTransaction', $event)}}}):_vm._e(),_c('DocumentEstatePlanning',{attrs:{"previousSectionComplete":_vm.fullPaymentReceived,"transaction":_vm.transaction},on:{"refreshTransaction":function($event){return _vm.refreshTransaction($event)}}}),_c('document-request-section',{attrs:{"title":"Below are details of documents requested for this estate planning transaction."},model:{value:(_vm.transaction),callback:function ($$v) {_vm.transaction=$$v},expression:"transaction"}}),_c('Contact',{staticClass:"mt-80",attrs:{"id":"contact","transaction":_vm.transaction},on:{"refreshTransaction":function($event){return _vm.refreshTransaction($event)}}})],1)])],1),_c('div',{staticClass:"container"},[_c('Footer')],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }